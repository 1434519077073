<template>
  <div   :class="`event-popup ${popupType} ${canNavigateClass} ${deletingClass}`">
   <div class="popup-header">
    <div class="popup-icon">
      <capitec-icon class="icon" size="small" :icon="`status/${popupType}-24px-white`"></capitec-icon>
    </div>
    <div class="popup-header-text" >
      {{popupHeader}}
    </div>
    <div class="popup-header-close-button"  @click.stop="close">
      <capitec-icon size="xx-small" class="icon click-icon" icon="system/close-white"/>
    </div>
   </div>
   <div class="popup-body" @click="goToItemViewAndRetrieveIfNecessary">
     {{popupText}}
   </div>
  </div>

</template>

<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";
import { deleteMessage } from '@/webSockets/LdbEventsWebSocket.ts';
import {db_check_if_item_exists,getDefinitionByTable} from '@/db-interface';
import {doPermissionAction} from '@/lfx_rest/lfx_action';
const Mustache = require('mustache');
window.Mustache = Mustache;

export default {
  components: {},
  props: {  
    event:{
      type:Object,
      required:true
    },
    index:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      canNavigate:true,
      deleting:false
    }
  },

  created() {
    
  },

  mounted() {
    this.deleteAfterTimeout(); 
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['now']),
    deletingClass() {
      return this.deleting ? 'deleting' : '';
    },
    canNavigateClass() {
      return this.canNavigate ? 'can-navigate' : 'cant-navigate';
    },
    popupHeader() {
      let header = (this.event.eventType.eventTitle || this.event.eventType.notificationText);
      return this.replaceText(header);
    },
    popupText() {
      return this.replaceText(this.event.eventType.notificationText);
    },
    replaceTags() {
      return this.event.tags || {};
    },
    popupType() {
      return this.event?.eventType?.displayType || 'info';
    },
    time_since() {
      let date = Math.floor(new Date(this.event.timeStamp).getTime() / 1000);
      let fmt = {y:'Y ago',m:'M ago',d:'d ago',h:'h ago',n:'m ago',s:'s ago'};
      var seconds = Math.floor((this.now - date));
      var interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        return interval + `${fmt.y}`;
      }
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        return interval + `${fmt.m}`;
      }
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        return interval + `${fmt.d}`;
      }
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) {
        return interval + `${fmt.h}`;
      }
      interval = Math.floor(seconds / 60);
      if (interval >= 1) {
        return interval + `${fmt.n}`;
      }
      return `now`;
    },
    testEvent() {
      return {
        }
      }
    },
  methods:{
  	...mapActions(['doDeleteEventPopup']),
  	...mapMutations([]),
  	deleteAfterTimeout() {
      let timeout = 10000 + this.index * 1000;
      setTimeout(this.close,timeout);
    },
    doDeleteEvent() {
      deleteMessage(this.event.id);
    },
    replaceText(text) {
      let taggedText = text.replace(/\[/g, "{{").replace(/\]/g,"}}");
      let tags = this.defaultTags(taggedText);
      return Mustache.render(taggedText,tags);
    },
    defaultTags(text) {
      let defaultTags = Mustache.parse(text).filter(a => a[0] === 'name').map(a => a[1]);
      let defaultTagObject = {};
      for (let t = 0 ; t < defaultTags.length ; t++ ) {
        let tag = defaultTags[t];
        defaultTagObject[tag] = `[${tag}]`;
      }
      let replaceTags = {...defaultTagObject,...this.replaceTags};
      return replaceTags;
    },
    close() {
      setTimeout(this.doDeleteEventPopup,100,this.event.id);
      this.deleting = true;
    },
    async goToItemViewAndRetrieveIfNecessary(evt) {      
      const object = this.event.dbObject;
      const definition = getDefinitionByTable(object.Table);
      const collectionPath = Mustache.render(definition.collectionPath, object);
      collectionPath.replaceAll('/null/', '/0/');
      const existingObject = db_check_if_item_exists(this.$store, collectionPath + '/' + object.id, '');
      if (existingObject) {
        if (existingObject.link ) {
          this.$router.push(existingObject.link);
        }
      }        
      else {
        object.collection_path = collectionPath;
        const temporartyObject = definition.class(object, this.$store)
        if (temporartyObject && temporartyObject.link) {
          this.$router.push(temporartyObject.link);
        }
        // if (definition.actions?.item) {
        //   const permissionActionResult = await doPermissionAction(this,definition.actions.item,object,object);
        //   const newObject = db_check_if_item_exists(this.$store, collectionPath + '/' + object.id, '');
        //   if (newObject?.link) {
        //     this.$router.push(newObject.link);
        //   } else {
        //     this.canNavigate = false;
        //   }
        // } else {
        //   this.canNavigate = false;
        //  }
       }
       //  hide loader
       this.showLoader(false);
       this.showEvents(false);
    }
  }
 }
</script>
<style scoped>

.event-popup{
  display:flex;
  flex-direction:column;
  pointer-events: auto;
  user-select: none;
  opacity: 1;
  transition:all 100ms linear;
}

.event-popup.deleting{
  opacity:0;
}

.popup-header{
  display:flex;
  align-items:center;
  white-space: nowrap;
}

.popup-header{
  display:flex;
  background-color:var(--event-color);
  color:white;
  font-weight: bold;
}

.popup-icon{
  flex:1;
  max-width: 25px;
  display: flex;
  align-items:center;
  justify-content: center;
}

.popup-header-text{
  flex:1;
}

.popup-header-close-button{
  flex:1;
  max-width: 20px;
  max-height: 13px;
  display: flex;
  align-items:center;
  justify-content: center;
}

.popup-body{
  padding-left:10px;
}

.event-popup{
  min-width: 450px;
  display:flex;
  overflow:hidden;
  margin:4px;
  border: 3px solid  var(--event-color);  
  background-color:white;   
  overflow: hidden; 
  z-index: 10;
}

.event-popup.info{
  --event-color: var(--theme-status-info-background);
}

.event-popup.error{
  --event-color: var(--theme-status-error-background);
}

.event-popup.success{
  --event-color: var(--theme-status-success-background);
}

.event-popup.warning{
  --event-color: var(--theme-status-warn-background);
}

.event-popup{
  min-height: 50px;
  margin-left:20px;
  margin-right:20px;
  border-radius: 5px;
  border: 1px solid  var(--event-color);  
  box-shadow:var(--theme-product-tile-hover-shadow, 0 3px 3px 0 rgba(0,0,0,0.25), 0 4px 4px 0 rgba(0,0,0,0.15));
}

</style>


