<template>
    <div  class="lfx-event-popups-container">
      <template v-for="(event,key,index) in eventPopups" :key="event.id"> 
        <div class="popup-stack" :style="{'z-index':1000-index}">
          <LfxEventPopup  :event="event" :index="index" />
        </div>
      </template>
    </div>
</template>


<script>

import {mapState,mapActions,mapGetters,mapMutations} from "vuex";

import LfxEventPopup from './LfxEventPopup.vue';


export default {
  components: {LfxEventPopup},
  props: {  
    event:{
      type:Object
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    
  },
  computed:{
    ...mapGetters([]),
  	...mapState(['eventPopups']),

  },
  methods:{
  	...mapActions([]),
  	...mapMutations([])
  }
 }
</script>
<style>

div.lfx-event-popups-container{
  position:fixed;
  top:5px;
  left:400px;
  height:64px;
  z-index: 10000000;
  right:400px;
  pointer-events:none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-stack{
  max-width: 15px;
  overflow: visible;
  display:flex;
  align-items:center;
  justify-content: center;
}

</style>


