<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_banners="showBanners"
    :show_actions="showActions"
    :form_layout="formLayout"
    :show_sidebar="false"
  >
    <template v-if="true" #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>

    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #content>
      <router-view
        v-if="includeUserVidibility"
        :includeSystemUsers="includeSystemUsers"
        :includeBranchesInLists="includeBranchesInLists"
        @on-search-change="onSearchChange"
      ></router-view>
      <router-view
        v-else-if="includeToggleIncludeBranchesInLists"
        :includeBranchesInLists="includeBranchesInLists"
        @on-search-change="onSearchChange"
      ></router-view>
      <router-view v-else></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';
import { evaluate_simple_guards } from '@/db-interface/db_interface_guard';

export default {
  name: 'LfxAccount',
  components: { LFXContent },
  data() {
    return {
      includeSystemUsers: false,
      includeBranchesInLists: false,
      tableSearchCriteria: {},
      beneficiaryTableSearchCriteria: {},
    };
  },
  created() {
    this.record?.activate('general');

    if (this.$route.name === 'account') {
      this.selectFirstTab();
    }
    this.record.signatoriesAndContacts;
    if (this.record.accountLevel === 'client') {
      void this.record.triggerGetDownloadableDocuments()
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'account' && to.params.accountId === from.params.accountId) {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      'permissions',
      'hasAllSystemRequirementsForTradingBeenMet',
      'isTradingEnabled',
      'isRatesFeedConnected',
      'isTodayWorkingDay',
      'isWithinTradingHours',
      'isDevelopment',
      'isTestingSdlc',
      'tradeSettings',
      'iAmAnUnmigratedIntermediary',
    ]),
    ...mapState(['account', 'authUser', 'dbIntermediarySettings','systemSettings']),

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        icon: this.titleBarIcon,
        label: this.titleBarLabel,
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },

    titleBarIcon() {
      return this.$route.meta?.icon?.[this.accountLevel];
    },
    titleBarLabel() {
      return `${this.record?.name}`;
    },
    titleBarButtons() {
      if (this.iAmAnUnmigratedIntermediary) {
        return [];
      }
      let self = this;
      let allButtons = [
        {
          name: 'accountAddClient',
          label: 'Add Client',
          type: 'white',
          guards: this.addClient_buttonGuard && this.authUser.accountIsActive,
          enabledGuards: this.authUser.accountIsActive,
          routeName: 'accountClientsAddClient',
        },
        {
          name: 'accountBankImportClient',
          label: 'Import Client',
          type: 'white',
          guards: this.record?.accountLevel === 'intermediary' && !!this.permissions?.bankImportClient && this.systemSettings?.featureEnable?.mandatesDatabase === true,
          routeName: 'accountBankImportClient',
          routeParams: { accountId: self.record.id },
        },
        {
          name: 'currentApplication',
          label: 'Current Application',
          type: 'white',
          guards: this.currentClientApplication_buttonGuard,
          routeName: 'clientApplication',
          routeParams: { applicationId: self.record.currentClientApplicationId },
        },
        {
          name: 'singleTransact',
          label: this.singleTransactionLabel,
          type: 'white',
          guards: this.onlySingleTransactionAllowed && this.authUser.accountIsActive && this.record?.activeForTrading,
          routeName: this.singleTransactionRouteName,
          // routeParams:
        },
        {
          name: 'transact',
          label: 'New Transaction',
          type: 'white',
          guards:
            this.newTransactaction_buttonGuard &&
            this.areMultipleTransactionsAllowed &&
            this.authUser.accountIsActive &&
            this.record?.activeForTrading,
          enabledGuards: this.authUser.accountIsActive,
          // icon: 'product/transact-white',
          routeName: 'accountDetailsViewNewTransaction',
        },
        {
          name: 'bookOfflineDeal',
          label: 'Book Offline Deal',
          type: 'white',
          guards: this.newOfflineTransactaction_buttonGuard,
          // icon: 'product/transact-white',
          routeName: 'newTransactionOfflineDealCapture',
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    allowedTransactions() {
      let allowedTransactions = [];
      let possibleTransactionsSettings = [
        'allowOutwardDeals',
        'allowTransferDeals',
        'allowInwardDeals',
        'allowOutwardPayments',
        'allowInwardPayments',
      ];
      for (let possibility of possibleTransactionsSettings) {
        if (this.tradeSettings?.[possibility]) {
          allowedTransactions.push(possibility);
        }
      }
      return allowedTransactions;
    },
    areMultipleTransactionsAllowed() {
      return this.allowedTransactions.length > 1 ? true : false;
    },
    onlySingleTransactionAllowed() {
      return this.allowedTransactions.length == 1 ? true : false;
    },
    singleTransactionLabel() {
      // TODO reference this to the buttons in the actionBar somehow - avr 2022-12-05
      const labelMap = {
        allowOutwardDeals: 'Book Outward Deal',
        allowTransferDeals: 'Book Transfer Deal',
        allowInwardDeals: 'Book Inward Deal',
        allowOutwardPayments: 'Make Outward Payment',
        allowInwardPayments: 'Receive Inward Payment',
      };
      return labelMap[this.allowedTransactions[0]];
    },
    singleTransactionRouteName() {
      // TODO reference this to the buttons in the actionBar somehow - avr 2022-12-05
      const routeMap = {
        allowOutwardDeals: 'newTransactionOutwardDealCapture',
        allowTransferDeals: 'newTransactionTransferDealCapture',
        allowInwardDeals: 'newTransactionInwardDealCapture',
        allowOutwardPayments: 'newTransactionOutwardPaymentCapture',
        allowInwardPayments: 'newTransactionInwardPaymentCapture',
      };
      return routeMap[this.allowedTransactions[0]];
    },
    singleTransactionGuards() {
      // TODO reference this to the buttons in the actionBar somehow - avr 2022-12-05
      const guardsMap = {
        allowOutwardDeals:
          !!this.permissions?.bookDeal &&
          this.record?.accountIsVisible &&
          this.record?.activeForTrading &&
          this.hasAllSystemRequirementsForTradingBeenMet &&
          this.authUser.accountIsActive,
        allowTransferDeals:
          !!this.permissions?.bookDeal &&
          this.record?.accountIsVisible &&
          this.record?.activeForTrading &&
          this.record?.hasBankAccountsForTransferDeals &&
          this.hasAllSystemRequirementsForTradingBeenMet &&
          this.authUser.accountIsActive,
        allowInwardDeals:
          !!this.permissions?.bookDeal &&
          this.record?.accountIsVisible &&
          this.record?.activeForTrading &&
          this.hasAllSystemRequirementsForTradingBeenMet &&
          this.authUser.accountIsActive,
        allowOutwardPayments:
          !!this.permissions?.bookTransfer &&
          this.record?.accountIsVisible &&
          this.record?.activeForTrading &&
          this.authUser.accountIsActive,
        allowInwardPayments:
          !!this.permissions?.bookTransfer &&
          this.record?.accountIsVisible &&
          this.record?.activeForTrading &&
          this.authUser.accountIsActive,
      };
      return guardsMap[this.allowedTransactions[0]];
    },

    // ----- Banners -----

    showBanners() {
      return this.banners?.length > 0 || false;
    },
    banners() {
      let allBanners = {
        all: [
          {
            id: `applicationCraDeclined` + this.record?.currentClientApplicationId,
            title: 'Client Application CRA Declined',
            status: 'error',
            closable: false,
            content: 'Customer Risk Assesment (CRA) has been declined for the client or an associated party.',
            guards: ['declined'].includes(this.currentClientApplicationRecord?.craStatus),
          },
          {
            id: `applicationErrorRejectOrCancel` + this.record?.currentClientApplicationId,
            title: 'Client Application ' + this.getStatusTextForBanner(this.currentClientApplicationRecord?.status),
            status: this.currentClientApplicationRecord?.status === 'cancelled' ? 'info' : 'error',
            closable: false,
            content: `Reason: ${this.currentClientApplicationRecord?.consolidatedRejectedReason}`,
            guards: ['error', 'rejected', 'cancelled'].includes(this.currentClientApplicationRecord?.status),
          },
          {
            id: `clientClosed` + this.record?.currentClientApplicationId,
            title: 'Closed',
            status: 'warning',
            closable: false,
            content: 'The client has been marked as closed. Please contact Treasury Maintain.',
            guards: this.record?.isClient && this.record.status === 'closed',
          },
          {
            id: `clientBankInactive` + this.record?.currentClientApplicationId,
            title: 'Inactive in BANCS - ' + getOptionLabel(this.definition, 'bancsStatus', this.record.bancsStatus),
            status: 'warning',
            closable: false,
            content: `The client is inactive at the bank:<br/>&nbsp&nbsp&nbsp&nbsp<b>${getOptionLabel(
              this.definition,
              'bancsStatus',
              this.record.bancsStatus
            )}</b><br/>Please contact Treasury Maintain.`,
            guards: this.record?.isClient && ![null, undefined, 'active'].includes(this.record.bancsStatus),
          },
          {
            id: `kycNonCompliant` + this.record?.id,
            title: 'KYC Non-Compliant',
            status: 'error',
            closable: false,
            content: 'The client has been marked as KYC Non-Compliant. Please contact Treasury Maintain.',
            guards: this.record?.isClient && this.record.kycCompliance === 'nonCompliant',
          },
          {
            id: `mandateDatabaseConflict` + this.record?.id,
            title: 'Mandate Database Conflict',
            status: 'error',
            closable: false,
            content: 'The client has a conflict between CFS and the Mandate Database. This will need to be rectified before any further action can be taken for the client.',
            guards: this.record?.isClient && this.record?.status === 'mandateDatabaseConflict',
          },
          {
            id: `downloadAbleDocuments` + this.record?.id,
            title: 'Downloadable Documents Available',
            status: 'success',
            closable: false,
            content: 'The following documents are available for download: ' + this.record?.savedDownloadAbleDocumentsText,
            guards: this.record?.savedDownloadAbleDocuments && this.record?.savedDownloadAbleDocuments.length !== 0 && this.record?.savedDownloadAbleDocumentsText,
          },
          {
            id: `noFecReviewSignatoryCaptured` + this.record?.currentClientApplicationId,
            title: 'No FEC Review Signatory Assigned',
            status: 'warning',
            closable: false,
            content: `The client is enabled for FEC Booking but no FEC Review Signatory has been assigned. Please contact Treasury Maintain to address this.`,
            guards: this.record.status === 'active' && this.record.mayBookFec && this.record.noFecSignatoryAssigned,
          },
        ],
        accountDetailsView: [
          {
            id: `tradingEnabled` + this.record?.id,
            title: 'Trading Disabled',
            status: this.tradingDisabledBanner.status,
            closable: false,
            content: this.tradingDisabledBanner.content,
            guards:
              this.record.status === 'active' && !this.record.tradingEnabled && this.record.accountLevel !== 'bank',
          },
          {
            id: `contactDetailsOutstanding` + this.record?.currentClientApplicationId,
            title: 'Contact Details Incomplete',
            status: 'warning',
            closable: false,
            content: `The client is <b>Active</b> but their contact details are incomplete. Please use the Update Contact Details button to retrieve them from the Bank.
            You will need the <b>Manage Signatory</b> permission to perform this action.`,
            guards: this.record.status === 'active' && this.record.contactDtailsOutstanding,
          },
          {
            id: `userReviewDue` + this.record?.id,
            title: 'User Review Due',
            status: 'warning',
            closable: false,
            content: `This Intermediary's User Review is Due`,
            guards: this.systemSettings?.userReview?.previousBankReviewDate && this.record.accountLevel === 'intermediary' && this.record.status === 'active' && this.record.userReviewStatus === 'due',
          },
          {
            id: `userReviewNonCompliant` + this.record?.id,
            title: 'User Review Non-Compliant',
            status: 'error',
            closable: false,
            content: `This Intermediary's User Review is Non-Compliant`,
            guards: this.systemSettings?.userReview?.previousBankReviewDate && this.record.accountLevel === 'intermediary' && this.record.status === 'active' && this.record.userReviewStatus === 'nonCompliant',
          },
        ],
        accountBankAccountView: [
          {
            id: `businessBankAccountNumberMissing-${this.bankAccountId}`,
            title: 'Account Number Not Captured',
            status: 'error',
            closable: false,
            content: `The account number has not been captured for this Business Bank account. Please contact Treasury Maintain to update the record.`,
            guards: !!this.bankAccountRecord?.isBusinessBankAccount && !this.bankAccountRecord?.accountNumber,
          },
          {
            id: `businessBankAccountNumberInvalid-${this.bankAccountId}`,
            title: 'Account Number Invalid',
            status: 'error',
            closable: false,
            content: `The account number is invalid for a Capitec account.  Please contact TreasuryMaintain to mark this account as Closed.`,
            guards:
              this.bankAccountRecord?.status === 'active' &&
              this.bankAccountRecord?.accountNumber?.match(/^[0-9]{10}$/) === null,
          },
        ],
      };
      const availableBanners = [...(allBanners[this.buttonGroup] || []), ...allBanners.all];
      return availableBanners.filter((banner) => banner.guards);
    },

    tradingDisabledBanner() {
      const res = { status: 'error', content: `Trading has been <b>Disabled</b>.` };

      switch (this.accountLevel) {
        case 'client':
          if (this.authUser?.accountLevel === 'client') {
            res.content = `Although your profile's status is <b>Active</b>, it has been <b>Disabled</b> for trading.`;
            break;
          }
          res.content = `The client is <b>Active</b>, but trading has been <b>Disabled</b>.${this.record?.tradingDisabledReason ? ` - ${this.record?.tradingDisabledReason}` : ''}`;
          break;

        case 'intermediaryBranch':
          if (this.authUser?.accountId == this.recordId) {
            res.content = `Although your branch's status is <b>Active</b>, it has been <b>Disabled</b> for trading.`;
            break;
          }
          if (['intermediary', 'intermediaryBranch'].includes(this.authUser?.accountLevel)) {
            res.content = `Although the branch's status is <b>Active</b>, it has been <b>Disabled</b> for trading.`;
            break;
          }
          if (this.authUser?.accountLevel === 'bank') {
            res.content = `Although the branch's status is <b>Active</b>, it has been <b>Disabled</b> for trading. The intermediary can re-enable trading.`;
            break;
          }

        case 'intermediary':
          if (this.authUser?.accountLevel === 'intermediary') {
            res.content = `Although your intermediary's status is <b>Active</b>, it has been <b>Disabled</b> for trading.`;
            break;
          }
          if (this.authUser?.accountLevel === 'bank') {
            res.content = `Although the intermediary's status is <b>Active</b>, it has been <b>Disabled</b> for trading.`;
            break;
          }
      }

      return res;
    },

    // ----- Tabs -----

    details_tabGuard() {
      return !!this.permissions?.getAccount;
    },
    branches_tabGuard() {
      return this.record?.isIntermediaryOrBranch && !!this.permissions?.getAccounts && !this.record?.restrictedView;
    },
    clients_tabGuard() {
      return this.record?.isIntermediaryOrBranch && !!this.permissions?.getAccounts && !this.record?.restrictedView;
    },
    transactions_tabGuard() {
      // @ts-ignore
      return (
        this.record?.accountLevel !== 'bank' &&
        !!this.permissions?.getTransactionFiles &&
        this.authUser?.accountLevel !== 'client' &&
        !this.record?.restrictedView
      );
    },
    inwardSwifts_tabGuard() {
      return (
        this.record?.accountLevel !== 'bank' &&
        !!this.permissions?.getInwardSwifts &&
        this.authUser?.accountLevel !== 'client' &&
        !this.record?.restrictedView
      );
    },
    bankAccounts_tabGuard() {
      return this.record?.isClient && !!this.permissions?.getBankAccounts && !this.record?.restrictedView;
    },
    beneficiaries_tabGuard() {
      return (
        (this.record?.isClient || this.record?.isIntermediaryOrBranch) &&
        !!this.permissions?.getBeneficiaries &&
        // this.authUser?.accountLevel !== 'client' && // TODO: permanently remove this? avr 2023-02-08
        !this.record?.restrictedView
      );
    },
    markups_tabGuard() {
      // @ts-ignore
      return this.record?.accountLevel !== 'bank' && !!this.permissions?.getAccountMarkups && !this.record?.restrictedView;
    },
    signatories_tabGuard() {
      // @ts-ignore
      return (
        this.record?.accountLevel !== 'bank' && !!this.permissions?.getAccountSignatories && !this.record?.restrictedView
      );
    },
    users_tabGuard() {
      return (
        this.record?.accountType !== 'individual' &&
        !!this.permissions?.getAccountUsers &&
        // this.authUser?.accountLevel !== 'client' &&
        !this.record?.restrictedView
      );
    },
    settings_tabGuard() {
      return this.isDevelopment && !this.record?.restrictedView; // TODO
    },
    applications_tabGuard() {
      return (
        this.record?.accountLevel === 'client' &&
        this.authUser?.accountLevel !== 'client' &&
        !this.record?.restrictedView
      );
    },
    bulkFees_tabGuard() {
      return (
        this.record?.accountLevel === 'intermediary' &&
        (this.dbIntermediarySettings?.mayProcessBulkFees || this.record?.mayProcessBulkFees) &&
        !this.record?.restrictedView
      );
    },
    uploads_tabGuard() {
      return !this.record?.restrictedView && !!this.permissions.uploadBulkBeneficiaryImportBatch;
    },
    downloads_tabGuard() {
      return !this.record?.restrictedView && this.record?.savedDownloadAbleDocuments && this.record?.savedDownloadAbleDocuments.length !== 0
    },
    logs_tabGuard() {
      return !!this.permissions?.getClientApplications && !this.record?.restrictedView;
    },

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          tabName: 'details',
          routeName: 'accountDetailsView',
          guards: this.details_tabGuard,
          label: 'Details',
        },
        {
          tabName: 'branches',
          routeName: 'accountBranchesView',
          guards: this.branches_tabGuard,
          label: 'Branches',
        },
        { tabName: 'clients', routeName: 'accountClientsView', guards: this.clients_tabGuard, label: 'Clients' },
        {
          tabName: 'transactions',
          routeName: 'accountTransactionsView',
          guards: this.transactions_tabGuard,
          label: 'Transactions',
        },
        {
          tabName: 'deals',
          routeName: 'accountDealsView',
          guards: this.transactions_tabGuard,
          label: 'Deals',
        },
        {
          tabName: 'inwardSwifts',
          routeName: 'accountInwardSwiftsView',
          guards: this.inwardSwifts_tabGuard,
          label: 'Inward Swifts',
        },
        {
          tabName: 'bankAccounts',
          routeName: 'accountBankAccounts',
          guards: this.bankAccounts_tabGuard,
          label: 'Bank Accounts',
        },
        {
          tabName: 'beneficiaries',
          routeName: 'accountBeneficiariesList',
          guards: this.beneficiaries_tabGuard,
          label: 'Beneficiaries',
        },
        {
          tabName: 'markups',
          routeName: 'accountMarkupsList',
          guards: this.markups_tabGuard,
          label: 'Markups',
        },
        {
          tabName: 'signatories',
          // routeName: 'accountSignatoriesList',
          routeName: 'accountSignatories',
          guards: this.signatories_tabGuard,
          label: 'Signatories',
        },
        {
          tabName: 'contacts',
          routeName: 'accountContactsList',
          guards: this.signatories_tabGuard,
          label: 'Contacts',
        },
        {
          tabName: 'users',
          routeName: 'accountUsersView',
          guards: this.users_tabGuard,
          label: 'Users',
        },
        {
          tabName: 'settings',
          routeName: 'accountSettingsView',
          guards: this.settings_tabGuard && this.isDevelopment, // TODO
          label: 'Settings',
        },
        // {
        //   tabName: 'applicationSummary',
        //   routeName: 'applicationSummary',
        //   routeParams: { applicationId: this.record?.currentClientApplicationId },
        //   guards: this.currentClientApplication_buttonGuard,
        //   label: 'Applications',
        // },
        // {
        //   tabName: 'applications',
        //   routeName: 'applicationSummary',
        //   routeParams: { applicationId: this.record?.currentClientApplicationId },
        //   guards: this.currentClientApplication_buttonGuard,
        //   label: 'Applications',
        // },
        {
          tabName: 'applications',
          routeName: 'accountApplicationsList',
          guards: this.applications_tabGuard,
          label: 'Applications',
        },
        {
          tabName: 'bulkFees',
          routeName: 'accountBulkFeesList',
          guards: this.bulkFees_tabGuard,
          label: 'Bulk Fees',
        },
        {
          tabName: 'uploads',
          routeName: 'accountUploadsList',
          guards: this.uploads_tabGuard,
          label: 'Uploads',
        },
        {
          tabName: 'downloadableDocuments',
          routeName: 'accountDownloadableDocuments',
          guards: this.downloads_tabGuard,
          label: 'Downloads',
        },
        {
          tabName: 'logs',
          routeName: 'accountLogsView',
          guards: this.logs_tabGuard && this.isDevelopment,
          label: 'Logs',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    routeTabName() {
      return this.$route.meta?.content?.tabName;
    },

    includeUserVidibility() {
      return ['accountUsersView'].includes(this.$route.name);
    },
    includeAccountVidibility() {
      return ['accountBranchesView', 'accountClientsView'].includes(this.$route.name);
    },
    includeToggleIncludeBranchesInLists() {
      return [
        'accountBranchesView',
        'accountClientsView',
        'accountTransactionsView',
        'accountDealsView',
        'accountUsersView',
        'accountUploadsList',
      ].includes(this.$route.name);
    },

    // ----- Stepper -----

    showStepper() {
      return false;
    },

    // ----- Content Label -----

    showLabel() {
      return true;
    },
    contentLabel() {
      return this.$route.meta?.content?.label
        ? `${this.accountLabel} ${this.$route.meta.content.label}`
        : "Navigation didn't complete, please click tab above to continue"; // TODO
    },

    // ------------------------------
    // Permissions and guards
    // ------------------------------

    canManageSignatories() {
      return true // TODO REMOVE THIS - IT IS A DIRTY HACK SO THAT BANK CAN CAPTURE MISSING SIGNATORIES FOR FEC REVIEW
      // FIX ME: AS ABOVE - 2024-08-12
      // return this.authUser?.accountLevel !== 'bank' || this.record?.accountIsBankDirectClient;
    },
    // ----- Title Bar -----

    addClient_buttonGuard() {
      return (
        this.record?.accountIsVisible &&
        this.record?.accountIsActive &&
        this.record?.isIntermediaryOrBranch &&
        !!this.permissions?.createClientForAccount &&
        !this.record?.restrictedView
      );
    },
    newTransactaction_buttonGuard() {
      return (
        this.record?.isActive &&
        this.record?.isClient &&
        // @ts-ignore
        this.record?.tradingEnabled &&
        (!!this.permissions?.bookDeal || !!this.permissions?.bookTransfer) &&
        !this.record?.restrictedView
      );
    },
    newOfflineTransactaction_buttonGuard() {
      return (
        this.record?.isActive &&
        this.record?.isClient &&
        // @ts-ignore
        this.record?.tradingEnabled &&
        !!this.permissions?.captureOfflineDeal &&
        !this.record?.restrictedView
      );
    },

    // Details
    updateTocMandate_buttonGuard(){
      return (
        this.record?.status === 'mandateDatabaseConflict' &&
        this.record?.accountIsVisible &&
        !!this.permissions?.updateTocMandate &&
        !this.record?.restrictedView)
    },
    unhideAccount_buttonGuard() {
      return (
        this.record?.notMyAccount &&
        !this.record?.accountIsVisible &&
        !!this.permissions?.unhideAccount &&
        !this.record?.restrictedView
      ); //this.permissions[unhideAction];
    },

    currentClientApplication_buttonGuard() {
      // FIXME this button displays even when application is complete and client is active - e.g. CIF 13960067
      return (
        this.record?.isActionable &&
        !!this.permissions?.getApplication &&
        this.record?.accountLevel === 'client' &&
        !!this.record?.currentClientApplicationId &&
        !this.record?.restrictedView
      );
    },

    clientBankInfo_buttonGuard() {
      return (
        this.record?.isActive &&
        this.record?.isClient &&
        !!this.permissions?.getBankInformation &&
        !this.record?.restrictedView
      );
    },

    changeClientIntermediary_buttonGuard() {
      return (
        this.record?.notMyAccount &&
        this.record?.isClient &&
        // @ts-ignore
        this.record?.status !== 'notWithThisIntermediary' &&
        !!this.permissions?.changeClientIntermediary &&
        !this.record?.restrictedView
      );
    },
    intermediaryCommissionAccountStatement_buttonGuard() {
      return !this.record?.notMyAccount &&
      this.record?.accountIsActive &&
      this.record?.accountLevel === 'intermediary'  &&
      !!this.permissions?.intermediaryCommissionAccountStatement &&
      !!this.record?.commissionAccount
    },

    closeClient_buttonGuard() {
      // @ts-ignore
      return (
        this.record?.isActionable &&
        this.record?.isClient &&
        this.record?.status === 'active' && // || this.record.kycCompliance === 'nonCompliant' // Still display this button if KYC Non-Compliant but not closed
        !!this.permissions?.closeClient
      );
    },
    reopenClient_buttonText() {
      if (this.record?.status === 'closed' || this.record?.status === 'bankNotActive') {
        return 'Re-Open CIF';
      } else {
        return 'Confirm KYC';
      }
    },
    reopenClientClient_buttonGuard() {
      return (
        this.record?.isActionable &&
        this.record?.isClient &&
        (this.record?.status === 'closed' ||
          this.record?.status === 'bankNotActive' ||
          this.record.kycCompliance === 'nonCompliant') &&
        !!this.permissions?.reopenClient
      );
    },
    releaseMigratedClient_buttonGuard() {
      return (
        this.record?.isActionable &&
        this.record?.isClient &&
        ['submitted', 'rejected'].includes(this.record?.status) &&
        this.record?.currentApplicationStatus === 'retrievedFromBank' &&
        !!this.permissions?.releaseMigratedClient
      );
    },

    hideAccount_buttonGuard() {
      // @ts-ignore
      let accountLevel = this.record?.accountLevel;
      let hideAction = `hide${accountLevel.charAt(0).toUpperCase()}${accountLevel.slice(1)}`;
      let canHideApplication = this.currentClientApplicationRecord?.canHideApplication;
      return (
        this.record?.isActionable && !!this.permissions?.[hideAction] && !this.record?.restrictedView && !!canHideApplication && this.record?.status !== 'mandateDatabaseConflict'
      );
    },

    // Bank Accounts

    accountBankAccountImport_buttonGuard() {
      let importAction;
      if (this.systemSettings?.featureEnable?.mandatesDatabase === true && !this.userAccountRecord?.bankDirectClients) {
        importAction = 'bankImportBankAccount'
      } else {
        importAction = 'importBankAccount'
      }
      return this.record?.isActive && !!this.permissions?.[importAction] && !this.record?.restrictedView;
    },

    // Beneficiaries

    addBeneficiary_buttonGuard() {
      return this.record?.accountIsVisible && !!this.permissions?.createBeneficiary && !this.record?.restrictedView;
    },
    bulkCreateBeneficiary_buttonGuard() {
      return (
        this.record?.accountIsVisible &&
        !!this.permissions?.uploadBulkBeneficiaryImportBatch &&
        !this.record?.restrictedView &&
        this.record.status !== 'notWithThisIntermediary' &&
        this.record.status !== 'mandateDatabaseConflict'
      );
    },

    // Markups

    updateFecAdditionalMarkups_buttonGuard() {
      return (
        this.record?.isActionable &&
        (this.record?.accountLevel === 'intermediary' ||
          (this.record?.accountLevel === 'client' && !!this.record?.mayBookFec)) &&
        !!this.permissions?.editAccountFecMarkups &&
        !this.record?.restrictedView
      );
    },
    accountMarkupsBulkEdit_buttonGuard() {
      let accountMarkups = this.record?.markup || {};
      let visibleMarkups = {};
      for (let m in accountMarkups) {
        let mrk = accountMarkups[m];
        if (mrk.status === 'visible') {
          visibleMarkups[mrk.id] = mrk;
        }
      }
      let numberOfAccountVisibleMarkups = Object.keys(visibleMarkups).length;
      return (
        this.record?.isActionable &&
        numberOfAccountVisibleMarkups > 0 &&
        !!this.permissions?.editAccountMarkups &&
        !this.record?.restrictedView
      );
    },
    accountMarkupsAdd_buttonGuard() {
      // @ts-ignore
      let systemCurrencyPairs = this.$store.getters.config.currencyPair;
      let numberOfSystemCurrencyPairs = 0;
      if (systemCurrencyPairs) {
        numberOfSystemCurrencyPairs = Object.keys(systemCurrencyPairs).length;
      }
      // @ts-ignore
      let accountMarkups = this.record?.markup || {};
      let visibleMarkups = {};
      for (let m in accountMarkups) {
        let mrk = accountMarkups[m];
        if (mrk.status === 'visible') {
          // @ts-ignore
          visibleMarkups[mrk.id] = mrk;
        }
      }
      let numberOfAccountVisibleMarkups = Object.keys(visibleMarkups).length;
      return (
        this.record?.isActionable &&
        numberOfSystemCurrencyPairs > numberOfAccountVisibleMarkups &&
        !!this.permissions?.createAccountMarkup &&
        !this.record?.restrictedView
      );
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length > 0 ? true : false;
    },
    actionBarButtons() {
      if (this.iAmAnUnmigratedIntermediary) {
        return [];
      }
      let self = this;
      let allButtons = {
        accountDetailsView: [
          //
          // Positive workflow actions

          {
            name: 'unhideAccount',
            label: `Unhide ${this.accountLabel}`,
            type: 'primary',
            modal: {
              header: `Unhide ${this.accountLabel}: ${this.record?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Unhide',
              content: this.unhideAccountModalMessage,
            },
            action: 'unhideAccount',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards: this.unhideAccount_buttonGuard,
          },
          {
            name: 'enableIntermediary',
            label: `Enable Intermediary`,
            type: 'primary',
            guards:
              this.record?.accountLevel === 'intermediary' &&
              (this.record?.status !== 'active' || this.record?.tradingEnabled !== true) &&
              !!this.permissions.enableIntermediary,
            clickEvent() {
              self.$router.push({ name: 'accountDetailsViewEnableIntermediary' });
            },
          },
          {
            name: 'enableBranch',
            label: this.record?.status !== 'active' ? 'Enable Branch' : 'Enable Branch Trading',
            type: 'primary',
            modal: {
              header: `${this.record?.status !== 'active' ? 'Enable Branch:' : 'Enable Branch Trading:'} ${
                this.record?.name
              }`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: this.enableBranchModalMessage,
            },
            action: 'enableBranch',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards:
              this.record?.accountLevel === 'intermediaryBranch' &&
              (this.record?.status !== 'active' || this.record?.tradingEnabled !== true),
          },
          {
            name: 'enableClientTrading',
            label: `Enable Client Trading`,
            type: 'primary',
            modal: {
              header: `Enable Client Trading: ${this.record?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Enable',
              content: this.enableClientTradingModalMessage,
            },
            action: 'enableClientTrading',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards:
              this.record?.accountLevel === 'client' &&
              this.record?.status === 'active' &&
              this.record?.tradingEnabled !== true,
          },
          {
            name: 'reopenClient',
            label: this.reopenClient_buttonText,
            type: 'primary',
            modal: {
              header: `${this.reopenClient_buttonText}: ${this.record?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Re-Open',
              content: this.reopenAccountModalMessage,
            },
            action: 'reopenClient',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards: this.reopenClientClient_buttonGuard,
          },
          {
            name: 'releaseMigratedClient',
            label: 'Release Migrated Client',
            type: 'primary',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards: this.releaseMigratedClient_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'releaseMigratedClient' });
            },
          },

          // Maintenance actions
          {
            name: 'enableIndividualSignatory',
            label: `Update Contact Details`,
            type: '',
            guards:
              this.clientBankInfo_buttonGuard &&
              this.accountLevel === 'client' &&
              this.record?.accountType === 'individual' &&
              this.record?.individualHasSignatory === false,
            clickEvent() {
              self.$router.push({ name: 'enableIndividualSignatory' });
            },
          },
          {
            name: 'updateIndividualSignatory',
            label: `Update Contact Details`,
            type: '',
            guards:
              this.clientBankInfo_buttonGuard &&
              this.accountLevel === 'client' &&
              this.record?.accountType === 'individual' &&
              !!this.record?.individualHasSignatory,
            clickEvent() {
              self.$router.push({
                name: 'updateIndividualSignatory',
                params: { signatoryId: self.record?.individualSignatory?.id },
              });
            },
          },
          {
            name: 'enableEntityMainContact',
            label: `Update Contact Details`,
            type: '',
            guards:
              this.clientBankInfo_buttonGuard &&
              this.accountLevel === 'client' &&
              this.record?.accountType === 'entity' &&
              this.record?.entityHasMainContact === false,
            clickEvent() {
              self.$router.push({ name: 'enableIndividualSignatory' });
            },
          },
          {
            name: 'updateEntityMainConctact',
            label: `Update Contact Details`,
            type: '',
            guards:
              this.clientBankInfo_buttonGuard &&
              this.accountLevel === 'client' &&
              this.record?.accountType === 'entity' &&
              !!this.record?.entityHasMainContact,
            clickEvent() {
              self.$router.push({
                name: 'updateIndividualSignatory',
                params: { signatoryId: self.record?.entityMainContact?.id },
              });
            },
          },

          {
            name: 'clientBankInfo', //TODO
            label: 'Bank Information',
            type: '',
            guards: this.clientBankInfo_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountDetailsViewBankInfo' });
            },
          },
          {
            name: 'editAccountDetails',
            label: 'Edit Details',
            type: '',
            guards: this.record?.editAccountDetails_buttonGuard &&
              this.record?.status !== 'mandateDatabaseConflict',
            clickEvent() {
              self.$router.push({ name: 'accountDetailsEdit' });
            },
          },
          {
            name: 'updateOpenDealLimit',
            label: 'Edit Open Deal Limit',
            type: '',
            guards: this.record?.accountOpdenDealLimitEdit_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountOpenDealLimitEdit' });
            },
          },
          {
            name: 'moveBranch',
            label: 'Move Branch',
            type: '',
            guards: this.record?.moveBranch_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountDetailsMoveBranch' });
            },
          },
          {
            name: 'moveClient',
            label: 'Move Client',
            type: '',
            guards: this.record?.moveClient_buttonGuard &&
              this.record?.status !== 'mandateDatabaseConflict',
            clickEvent() {
              self.$router.push({ name: 'accountDetailsMoveClient' });
            },
          },
          {
            name: 'checkTocMandate',
            label: `Check TOC Mandate`,
            type: '',
            guards: this.record?.accountLevel === 'client' && !!this.permissions.getTocMandate && this.systemSettings?.featureEnable?.mandatesDatabase === true,
            clickEvent() {
              self.$router.push({ name: 'checkTocMandate' });
            },
          },

          {
            name: 'updateTocMandate',
            label: `Update Mandate Database`,
            type: 'primary',
            modal: {
              header: `Update Mandate Database to ${this.account?.[this.record.intermediaryId]?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Update',
              content: this.updateTocMandateModalMessage,
            },
            action: 'updateTocMandate',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards: this.updateTocMandate_buttonGuard,
          },
          {
            name: 'changeClientIntermediary',
            label: 'Change Client Intermediary',
            type: '',
            guards: this.changeClientIntermediary_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountDetailsViewChangeClientIntermediary' });
            },
          },
          {
            name: 'intermediaryCommissionAccountStatement',
            label: 'Commission Account Statement',
            type: '',
            guards:
              this.intermediaryCommissionAccountStatement_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'intermediaryCommissionAccountStatement' });
            },
          },

          // Negative workflow actions

          {
            name: 'disableIntermediary',
            label: this.record?.tradingEnabled ? 'Disable Intermediary' : 'Close Intermediary',
            type: '',
            guards: this.record?.accountLevel === 'intermediary' && this.record?.status !== 'closed' && !!this.permissions?.disableIntermediary,
            clickEvent() {
              self.$router.push({ name: 'accountDetailsViewDisableIntermediary' });
            },
          },
          {
            name: 'disableBranchTrading',
            label: `Disable Branch Trading`,
            type: '',
            modal: {
              header: `Disable Trading for ${this.record?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: this.disableBranchTradingModalMessage,
            },
            action: 'disableBranchTrading',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards: this.record?.accountLevel === 'intermediaryBranch' && this.record?.tradingEnabled === true,
          },
          {
            name: 'disableBranch',
            label: `Disable Branch`,
            type: '',
            modal: {
              header: `Disable: ${this.record?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Disable',
              content: this.disableBranchModalMessage,
            },
            action: 'disableBranch',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards:
              this.record?.accountLevel === 'intermediaryBranch' &&
              (this.record?.status === 'active' || this.record?.tradingEnabled === true),
          },
          {
            name: 'disableClientTrading',
            label: `Disable Client Trading`,
            type: '',
            guards: this.record?.accountLevel === 'client' && this.record?.tradingEnabled === true && !!this.permissions.disableClientTrading && this.record?.status !== 'mandateDatabaseConflict',
            clickEvent() {
              self.$router.push({ name: 'disableClientTrading' });
            },
          },

          {
            name: 'closeClient',
            label: 'Close/KYC',
            type: '',
            guards: this.closeClient_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountDetailsViewCloseClient' });
            },
          },

          {
            name: 'hideAccount',
            label: `Hide ${this.accountLabel}`,
            type: '',
            modal: {
              header: `Hide ${this.accountLabel}: ${this.record?.name}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Hide',
              content: this.hideAccountModalMessage,
            },
            action: 'hideAccount',
            params: { accountId: this.record?.id },
            actionParams: { accountId: this.record?.id },
            guards: this.hideAccount_buttonGuard,
          },
        ],
        accountDetailsEdit: [
          {
            name: 'editAccountDetailsCancel',
            label: 'Cancel',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.go(-1);
            },
          },
        ],

        // ----- Branches tab -----

        accountBranchesView: [
          {
            name: 'bulkEnableBranch',
            label: 'Bulk Enable Branch',
            type: '',
            guards: self.record.accountBulkEnableBranch_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'bulkEnableBranch' });
            },
          },
          {
            name: 'accountAddBranch',
            label: 'Add Branch',
            type: 'primary',
            guards: self.record.accountAddBranch_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountBranchAdd' });
            },
          },
        ],

        // ----- Clients tab -----

        accountClientsView: [
          {
            name: 'accountAddClient',
            label: 'Add Client',
            type: 'primary',
            guards: false && !!this.permissions?.checkNewClientID,
            clickEvent() {
              self.$router.push({ name: 'accountClientsAddClient' });
            },
          },
          {
            name: 'accountBankImportClient',
            label: 'Import Client',
            type: 'primary',
            guards: this.record?.accountLevel === 'intermediary' && !!this.permissions?.bankImportClient && this.systemSettings?.featureEnable?.mandatesDatabase === true,
            routeName: 'accountBankImportClient',
            routeParams: { accountId: self.record.id },
          },
        ],

        // ----- Transactions tab -----

        accountTransactionsView: [
          {
            name: 'bookOutwardDeal',
            label: 'Book Outward Deal',
            type: '',
            guards:
              !!this.permissions?.bookDeal &&
              this.record?.accountIsActive &&
              this.record?.accountIsVisible &&
              this.tradeSettings.allowOutwardDeals,
            enabledGuards:
              this.record?.activeForTrading &&
              this.hasAllSystemRequirementsForTradingBeenMet &&
              this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'newTransactionOutwardDealCapture' });
            },
          },
          {
            name: 'bookTransferDeal',
            label: 'Book Transfer Deal',
            type: '',
            guards:
              !!this.permissions?.bookDeal &&
              this.record?.accountIsActive &&
              this.record?.accountIsVisible &&
              this.tradeSettings.allowTransferDeals,
            enabledGuards:
              this.record?.activeForTrading &&
              this.record?.hasBankAccountsForTransferDeals &&
              this.hasAllSystemRequirementsForTradingBeenMet &&
              this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'newTransactionTransferDealCapture' });
            },
          },
          {
            name: 'bookInwardDeal',
            label: 'Book Inward Deal',
            type: '',
            guards:
              !!this.permissions?.bookDeal &&
              this.record?.accountIsActive &&
              this.record?.accountIsVisible &&
              this.tradeSettings.allowInwardDeals,
            enabledGuards:
              this.record?.activeForTrading &&
              this.hasAllSystemRequirementsForTradingBeenMet &&
              this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'newTransactionInwardDealCapture' });
            },
          },

          {
            name: 'bookOutwardPayment',
            label: 'Make Outward Payment',
            type: '',
            guards:
              !!this.permissions?.bookTransfer &&
              this.record?.accountIsActive &&
              this.record?.accountIsVisible &&
              this.tradeSettings.allowOutwardPayments,
            enabledGuards: this.record?.activeForTrading && this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'newTransactionOutwardPaymentCapture' });
            },
          },
          {
            name: 'bookInwardPayment',
            label: 'Receive Inward Payment',
            type: '',
            guards:
              !!this.permissions?.bookTransfer &&
              this.record?.accountIsActive &&
              this.record?.accountIsVisible &&
              !!this.tradeSettings.allowInwardPayments,
            enabledGuards: this.record?.activeForTrading && this.authUser.accountIsActive,
            clickEvent() {
              self.$router.push({ name: 'newTransactionInwardPaymentCapture' });
            },
          },
        ],

        // ----- Deals tab -----

        accountDealsView: [
          {
            name: 'downloadDeals',
            label: 'Download',
            type: '',
            guards: true,
            action: 'downloadDeals',
            params: {},
            onSuccess: { doStoreUpdate: false },
            actionParams: { additionalSelector: this.tableSelectors },
          },
        ],

        // ----- Inward SWIFTS tab -----

        accountInwardSwiftsView: [
          {
            name: 'createInwardSwift',
            label: 'Create Inward SWIFT',
            type: '',
            guards:
              !!this.permissions?.createSwiftForClient &&
              this.record?.accountIsVisible &&
              this.record?.accountIsActive &&
              this.record?.isClient,
            clickEvent() {
              self.$router.push({ name: 'newAccountInwardSwiftsView' });
            },
          },
        ],

        // ----- Bank Accounts tab -----

        accountBankAccountsList: [
          {
            name: 'accountBankAccountImport',
            label: 'Import Bank Account',
            type: '',
            guards: this.accountBankAccountImport_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountsListImport' });
            },
          },
        ],

        accountBankAccountView: [
          {
            name: 'accountBankAccountImport',
            label: 'Import Bank Account',
            type: '',
            guards: this.accountBankAccountImport_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountViewImport' });
            },
          },
          {
            name: 'accountBankAccountReopen',
            label: 'Re-open Account',
            type: 'primary',
            guards: !!this.bankAccountRecord && !!this.bankAccountRecord.accountBankAccountReopen_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountViewReopen' });
            },
          },
          {
            name: 'consolidatedAccountBankAccountBalances',
            label: 'Consolidated Account Balances',
            type: '',
            guards:
              this.numberOfBusinessBankAccounts > 1 &&
              this.record?.isActive &&
              !!this.bankAccountRecord &&
              !!this.bankAccountRecord?.accountBankAccountBalance_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'consolidatedAccountBankAccountBalances' });
            },
          },
          {
            name: 'accountBankAccountBalance',
            label: 'Account Balance',
            type: '',
            guards:
              !!this.bankAccountRecord &&
              this.record?.accountIsActive &&
              !!this.bankAccountRecord.accountBankAccountBalance_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountViewBalance' });
            },
          },
          {
            name: 'accountBankAccountStatement',
            label: 'Account Statement',
            type: '',
            guards:
              !!this.bankAccountRecord &&
              this.record?.accountIsActive &&
              !!this.bankAccountRecord.accountBankAccountStatement_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountViewStatement' });
            },
          },
          {
            name: 'stampedBankAccountStatement',
            label: 'Stamped Statement',
            type: '',
            guards:
              !!this.bankAccountRecord &&
              this.record?.accountIsActive &&
              !!this.bankAccountRecord.accountBankAccountStatement_buttonGuard &&
              !!this.dbIntermediarySettings.intermediaryCanSeeStampedStatements,
            clickEvent() {
              self.$router.push({ name: 'accountStampedBankAccountViewStatements' });
            },
          },
          {
            name: 'accountBankAccountClose',
            label: 'Close Account',
            type: '',
            guards: !!this.bankAccountRecord && !!this.bankAccountRecord.accountBankAccountClose_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountViewClose' });
            },
          },
          {
            name: 'ensureBankAccountMandate',
            label: `Ensure Account Mandate`,
            type: 'primary',
            modal: {
              header: `Ensure Bank Account Mandate`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Ensure',
              content: this.ensureBankAccountMandateModalMessage,
            },
            action: 'ensureBankAccountMandate',
            params: {
              accountId: this.record?.id,
              bankAccountId: this.bankAccountRecord?.id,
            },            
            guards: 
              !!this.bankAccountRecord &&
              this.record?.accountIsActive &&
              !!this.bankAccountRecord.ensureBankAccountMandate_buttonGuard,
          },
          {
            name: 'editBankAccount',
            label: 'Edit Bank Account',
            type: '',
            guards:
              !!this.bankAccountRecord &&
              !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) &&
              !!this.permissions?.updateClientBankAccount,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountViewEdit' });
            },
          },
          {
            name: 'downloadConfirmationLetter',
            label: 'Account Confirmation',
            type: '',
            guards:
              !!this.bankAccountRecord &&
              this.bankAccountRecord?.status === 'active' &&
              !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) &&
              !!this.dbIntermediarySettings.intermediaryCanSeeAccountConfirmations,
            action: 'downloadAccountConfirmation',
            params: {
              accountId: this.record?.id,
              bankAccountId: this.bankAccountRecord?.id,
            },
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'logs',
            label: 'Logs',
            type: '',
            guards: !!this.bankAccountRecord,
            clickEvent() {
              self.$router.push({ name: 'accountBankAccountViewLogsList' });
            },
          },
        ],

        // ----- Beneficiaries tab -----

        accountBeneficiariesList: [
          {
            name: 'addBeneficiary',
            label: 'Add Beneficiary',
            type: '',
            guards: !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) && this.addBeneficiary_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'addBeneficiaryDetails' });
            },
          },
          {
            name: 'downloadBeneficiary',
            label: 'Download',
            type: '',
            guards: true,
            action: 'downloadBankAccounts',
            params: { accountId: self.recordId },
            onSuccess: { doStoreUpdate: false },
            actionParams: { additionalSelector: this.tableSelectors },
          },
        ],

        // ----- Markups tab -----

        accountMarkupsList: [
          {
            name: 'updateFecAdditionalMarkups',
            label: 'Update FEC Additional Markups',
            type: '',
            guards: this.updateFecAdditionalMarkups_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'updateFecAdditionalMarkups' });
            },
          },
          {
            name: 'bulkAccountMarkupsEdit',
            label: 'Bulk Edit',
            type: '',
            guards: this.accountMarkupsBulkEdit_buttonGuard && !!this.permissions?.createIntermediary, //Finish LfxBulkAccountMarkupEdit before giving to others
            clickEvent() {
              self.$router.push({ name: 'bulkAccountMarkupsEdit' });
            },
          },
          {
            name: 'accountMarkupsAdd',
            label: 'Add Markup',
            type: '',
            guards: !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) && this.accountMarkupsAdd_buttonGuard && this.clientIsNotIntermediary,
            clickEvent() {
              self.$router.push({ name: 'accountMarkupsListAdd' });
            },
          },
          {
            name: 'logs',
            label: 'Logs',
            type: '',
            clickEvent() {
              self.$router.push({ name: 'accountMarkupLogs' });
            },
          },
        ],

        // ----- Signatories tab -----

        accountSignatoriesList: [
          {
            name: 'enableIndividualSignatory',
            label: `Enable Individual Signatory`,
            type: '',
            guards:
              this.accountLevel === 'client' &&
              this.record?.accountType === 'individual' &&
              this.record?.individualHasSignatory === false,
            clickEvent() {
              self.$router.push({ name: 'enableIndividualSignatory' });
            },
          },
          {
            name: 'addSignatory',
            label: 'Add Signatory',
            type: '',
            guards:
              this.record?.accountLevel === 'client' &&
              !!this.permissions?.createSignatory &&
              !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) &&
              this.record?.accountIsVisible &&
              this.canManageSignatories,
            clickEvent() {
              self.$router.push({ name: 'accountSignatoriesListAdd' });
            },
          },
          {
            name: 'configureSignatories',
            label: 'Configure Signatories',
            type: '',
            guards:
              !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) &&
              this.record?.accountIsVisible &&
              this.record?.accountLevel === 'client' &&
              !!this.permissions?.editAccount &&
              this.canManageSignatories,
            clickEvent() {
              self.$router.push({ name: 'accountSignatoriesConfigure' });
            },
          },
        ],
        accountContactsList: [
          {
            name: 'enableEntityMainContact',
            label: `Enable Main Contact`,
            type: '',
            guards:
              this.accountLevel === 'client' &&
              this.record?.accountType === 'entity' &&
              this.record?.entityHasMainContact === false,
            clickEvent() {
              self.$router.push({ name: 'enableIndividualSignatory' });
            },
          },
          {
            name: 'addContact',
            label: 'Add Contact',
            type: '',
            guards:
              !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) &&
              !!this.permissions?.createSignatory &&
              this.record?.accountIsVisible &&
              this.canManageSignatories,
            clickEvent() {
              self.$router.push({ name: 'accountContactsListAdd' });
            },
          },
          {
            name: 'contactsReceiveCommunication',
            label: 'Who Will Receive Communication',
            type: '',
            guards:
              !['notWithThisIntermediary','mandateDatabaseConflict'].includes(this.record?.status) &&
              this.record?.accountIsVisible &&
              this.record?.accountLevel === 'client',
            clickEvent() {
              self.$router.push({ name: 'contactsReceiveCommunication' });
            },
          },
        ],

        // ----- Users tab -----

        accountUsersView: [
          {
            name: 'accountUserAccessReport',
            label: 'Access Report',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({ name: 'accountUsersAccessReport' });
            },
          },
          {
            name: 'bulkEnableUser',
            label: 'Bulk Enable Users',
            type: '',
            guards: self.record.accountBulkEnableUser_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'bulkEnableUser' });
            },
          },
          {
            name: 'accountAddUser',
            label: 'Add User',
            type: 'primary',
            guards:
              this.record?.accountIsVisible && this.record?.accountType !== 'individual' && !!this.permissions?.createUser, // TODO
            clickEvent() {
              self.$router.push({ name: 'accountUsersAddUser' });
            },
          },
          {
            name: 'showHideSystem',
            label: this.includeSystemUsers === false ? 'Show System Users' : 'Hide System Users',
            type: '',
            guards: this.authUser?.accountLevel === 'bank',
            action: 'click',
            params: {},
            httpParams: {},
            clickEvent: this.toggleSystem,
          },
          {
            name: 'accountUserSettings',
            label: 'Settings',
            type: '',
            guards: this.record?.accountLevel === 'client' && !!this.permissions?.updateAccountGeneralSettings, // TODO account must be client, user must have correct permissions
            clickEvent() {
              self.$router.push({ name: 'accountUsersViewSettings' });
            },
          },
        ],

        // Application Summary tab
        applicationSummary: [
          {
            name: 'addClientPortfolioDocument',
            label: 'Add Document',
            type: '',
            guards: this.currentClientApplicationRecord?.addDocumentPayment_buttonGuard || false,
            clickEvent() {
              self.$router.push({ name: 'clientPortfolioAddDocument' });
            },
          },
          {
            name: 'getClientPortfolioSummary',
            label: 'Portfolio Summary',
            type: '',
            guards: this.currentClientApplicationRecord?.clientPortfolioSummary_buttonGuard || false,
            clickEvent() {
              self.$router.push({ name: 'clientPortfolioSummary' });
            },
          },
          {
            name: 'viewPortfolio',
            label: 'View Portfolio',
            type: 'primary',
            guards: this.currentClientApplicationRecord?.viewClientPortfolio_buttonGuard || false,
            async clickEvent() {
              const viewPortfolioResult = await doPermissionAction(
                { onSuccess: { doStoreUpdate: false } },
                'getClientPortfolioLink',
                { accountId: this.recordId, applicationId: this.record?.currentClientApplicationId }
              );
              if (viewPortfolioResult.status === 'success') {
                window.open(viewPortfolioResult.response.portfolioUrl);
              } else {
                self.showToast({
                  type: 'error',
                  header: 'Error Building Link',
                  message: viewPortfolioResult.response.message,
                  show: true,
                });
              }
            },
          },

          {
            name: 'clientApplicationHistory',
            label: 'Application History',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({
                name: 'clientApplicationHistory',
                params: { accountId: self.record.id, applicationId: self.record.currentClientApplicationId },
              });
            },
          },

          {
            name: 'currentClientApplicationLogs',
            label: 'Application Logs',
            type: '',
            guards: this.currentClientApplication_buttonGuard,
            clickEvent() {
              self.$router.push({
                name: 'clientApplicationLogs',
                params: { accountId: self.record.id, applicationId: self.record.currentClientApplicationId },
              });
            },
          },
          {
            name: 'currentClientApplication',
            label: 'Application Details',
            type: 'primary',
            guards: this.currentClientApplication_buttonGuard,
            clickEvent() {
              self.$router.push({
                name: 'clientApplication',
                params: { applicationId: self.record.currentClientApplicationId },
              });
            },
          },
        ],
        bulkFees: [
          {
            name: 'uploadBulkFeeBatch',
            label: 'Upload Bulk Fees',
            type: 'primary',
            guards: this.bulkFees_tabGuard && !!this.permissions?.uploadBulkFees,
            clickEvent() {
              self.$router.push({
                name: 'uploadBulkFeeBatch',
              });
            },
          },
        ],
        uploads: [
          {
            name: 'uploadBulkBeneficiaryImportBatch',
            label: 'Import Beneficiaries',
            type: 'primary',
            guards: this.uploads_tabGuard && this.bulkCreateBeneficiary_buttonGuard,
            clickEvent() {
              self.$router.push({
                name: 'uploadBulkBeneficiaryImportBatch',
              });
            },
          },
        ],
      };
      const generalButtons = [
        {
          name: 'includeExcludeBranches',
          label: this.includeBranchesInLists === false ? 'Include Branches' : 'Exclude Branches',
          type: '',
          guards:
            this.includeToggleIncludeBranchesInLists &&
            (this.record?.accountLevel === 'intermediary' || this.record?.accountLevel === 'intermediaryBranch'),
          clickEvent: this.toggleIncludeBranchesInLists,
        },
      ];
      const buttonGroupButtons = allButtons[this.buttonGroup] ? allButtons[this.buttonGroup] : [];
      const allReturnButtons = [];
      for (const button of buttonGroupButtons) {
        allReturnButtons.push(button);
      }
      for (const button of generalButtons) {
        allReturnButtons.push(button);
      }
      const returnButtons = [];
      for (const button of allReturnButtons) {
        if (evaluate_simple_guards(button.guards, button.params)) {
          returnButtons.push(button)
        }
      }
      return returnButtons;
    },
    buttonGroup() {
      return this.$route.meta?.content?.buttonGroup;
    },

    tableSelectors() {
      if (this.routeTabName === 'beneficiaries') {
        const routeSearchCriteria = { where: { beneficiaryId: { gt: 0 } }, orderBy: { beneficiaryId: 'asc' } };
        return { ...routeSearchCriteria, ...this.beneficiaryTableSearchCriteria };
      }
      return { ...this.tableSearchCriteria }; //TODO - Get this from the table
    },
    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.content?.layout === 'list' ? false : true;
    },

    // ----- Record -----

    recordName() {
      return 'account';
    },
    recordId() {
      return +this.$route.params?.accountId;
    },
    record() {
      return this.$store.state?.[this.recordName]?.[this.recordId] || {};
    },

    userAccountRecord() {
      return this.account?.[this.authUser?.accountId]
    },
    definition() {
      return getDefinition(this.accountLevel);
    },

    accountLevel() {
      return this.record?.accountLevel;
    },
    accountLabel() {
      if (this.record?.accountLevel === 'bank') {
        return 'Bank';
      } else if (this.record?.accountLevel === 'intermediary') {
        return 'Intermediary';
      } else if (this.record?.accountLevel === 'intermediaryBranch') {
        return 'Branch';
      } else if (this.record?.accountLevel === 'client') {
        return 'Client';
      } else {
        return 'Account';
      }
    },
    accountName() {
      return `${this.accountLabel} ${this.record?.name}`;
    },
    clientIsNotIntermediary() {
      if (this.record?.accountLevel !== 'client') {
        return true
      }
      const clientCif = this.record.cifNumber
      const intermediaryCifNumber =  this.account?.[this.record.intermediaryId]?.cifNumber;
      return !clientCif || !intermediaryCifNumber || clientCif!== intermediaryCifNumber
    },
    currentClientApplicationRecord() {
      if (!this.record?.currentClientApplicationId) {
        return undefined;
      }
      return this.record?.application?.[this.record?.currentClientApplicationId];
    },
    bankAccountId() {
      return +this.$route.params?.bankAccountId;
    },
    bankAccountRecord() {
      return (this.bankAccountId ? this.record?.bankAccounts?.[this.bankAccountId] : undefined) || undefined;
    },
    numberOfBusinessBankAccounts() {
      let count = 0;
      for (const bankAccountId in this.record?.bankAccounts || {}) {
        if (this.record.bankAccounts[bankAccountId].isBusinessBankAccount) {
          count++;
        }
      }
      return count;
    },
    updateTocMandateModalMessage() {
      return `      
        <p>Do you wish to update the TOC Mandate Database to ${this.account?.[this.record.intermediaryId]?.name}?</p>
      `;
    },
    unhideAccountModalMessage() {
      return `
      <ol>
        <li>The ${this.accountLabel} will be visible in normal views again.</li>
        <li>This will allow interaction with the ${this.accountLabel} again.</li>
        ${this.record?.accountLevel === 'client' ? '<li>Unhiding a Client does not change their status.</li>' : ''}
      </ol>
      `;
    },
    enableIntermediaryModalMessage() {
      return `
      <ol>
        <li>The Intermediary will be set to active and trading enabled.</li>
      </ol>
      `;
    },
    disableIntermediaryModalMessage() {
      return `
      <ol>
        <li>The Intermediary will be set to closed and trading disabled.</li>
      </ol>
      `;
    },
    disableIntermediaryTradingModalMessage() {
      return `
      <ol>
        <li>The Intermediary's trading will be disabled.</li>
      </ol>
      `;
    },
    enableBranchModalMessage() {
      return `
      <ol>
        <li>The Branch will be set to active and trading enabled.</li>
      </ol>
      `;
    },
    disableBranchModalMessage() {
      return `
      <ol>
        <li>The Branch will be set to closed and trading disabled.</li>
      </ol>
      `;
    },
    disableBranchTradingModalMessage() {
      return `
      <ol>
        <li>The Branch's trading will be disabled.</li>
      </ol>
      `;
    },
    enableClientTradingModalMessage() {
      return `
      <ol>
        <li>The Client's trading will be enabled.</li>
      </ol>
      `;
    },

    hideAccountModalMessage() {
      return `
      <ol>
        <li>The ${this.accountLabel} will be hidden from normal views.</li>
        <li>Searching for the ${this.accountLabel} will still display the ${this.accountLabel}.</li>
      </ol>
      `;
    },
    reopenAccountModalMessage() {
      return `
      <ol>
        <li>This Client's account will be re-opened and KYC will be set to Compliant</li>
      </ol>
      `;
    },
    ensureBankAccountMandateModalMessage() {
      return `
      <p>
        This will check the Bank Account against the Mandate Database and add it if required.
      </p>
      `;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    onSearchChange(tableSearchCriteria) {
      if (this.routeTabName === 'beneficiaries') {
        this.beneficiaryTableSearchCriteria = tableSearchCriteria;
      } else this.tableSearchCriteria = tableSearchCriteria;
    },
    // ----- Titlebar -----

    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName });
    },

    // ----- Tabs -----

    selectFirstTab() {
      if (this.recordId === 1) {
        const usersTabIndex = this.tabs.findIndex((tab) => tab.tabName === 'users');
        if (usersTabIndex >= 0) {
          this.$router.replace({ name: this.tabs[usersTabIndex].routeName });
        }
      } else {
        this.$router.replace({ name: this.tabs[0].routeName });
      }
    },
    toggleSystem() {
      if (this.includeSystemUsers === true) {
        this.includeSystemUsers = false;
      } else {
        this.includeSystemUsers = true;
      }
    },
    toggleIncludeBranchesInLists() {
      if (this.includeBranchesInLists === true) {
        this.includeBranchesInLists = false;
      } else {
        this.includeBranchesInLists = true;
      }
    },
    getStatusTextForBanner(status) {
      switch (status) {
        case 'error':
          return 'Error';
        case 'rejected':
          return 'Rejected';
        case 'cancelled':
          return 'Cancelled';
        default:
          return status;
      }
    },
  },
};
</script>
