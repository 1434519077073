<template>
  <LFXContent
    :titleBar="titleBar"
    :show_tabs="showTabs"
    :tabs="tabs"
    :show_stepper="showStepper"
    :show_label="showLabel"
    :label="contentLabel"
    :show_actions="showActions"
    :show_banners="showBanners"
    :form_layout="formLayout"
    :show_sidebar="showSidebar"
  >
    <template #actions>
      <LDBButton
        v-for="button in actionBarButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>

    <template #banners>
      <LfxBanner
        v-for="banner in banners"
        :key="banner.id"
        :title="banner.title"
        :status="banner.status"
        :closable="banner.closable"
        ><div v-html="banner.content"></div
      ></LfxBanner>
    </template>

    <template #content>
      <router-view
        v-if="includeMarkupSelectors"
        :includeCancelledAndRejected="includeCancelledAndRejected"
      ></router-view>
      <router-view v-else-if="includeRefreshCounter" :refreshCounter="refreshCounter"></router-view>
      <router-view v-else></router-view>
    </template>
  </LFXContent>

  <router-view name="modal"></router-view>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LFXContent from '@/components/layout/LfxContent.vue';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { getOptionLabel } from '@/sharedUtils/LdbDefinitionUtils';
import { getInwardSwift } from '@/definitions/actions/LfxTransactionActions';

export default {
  name: 'LfxTransaction',
  components: { LFXContent },
  props: {},
  data() {
    return {
      includeCancelledAndRejected: false,
      refreshCounter: 0,
      supersededDeal_retrievalStatus: 'unretrieved', //'unretrieved'|'retrieving'|'retrieved'|'failed'
      activePartialDrawdownDeal_retrievalStatus: 'unretrieved', //'unretrieved'|'retrieving' |'retrieved'|'failed'
      inwardSwift_retrievalStatus: 'unretrieved', //'unretrieved'|'retrieving' |'retrieved'|'failed'
    };
  },
  created() {
    this.accountRecord?.loadDataForDealing();
    this.accountRecord?.loadDataForPayment();
    this.accountRecord?.loadDataForSignatories();

    if (this.transactionId) {
      this.transactionRecord?.getDeals();
      this.transactionRecord?.getTransactionPayments();
      this.transactionRecord?.getTransactionDealPayments();
    }

    if (this.$route.name === 'transaction') {
      this.selectFirstTab();
    }
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'transaction' && to.params.transactionId === from.params.transactionId) {
      next(false);
      this.selectFirstTab();
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      'permissions',
      'isDevelopment',
      'isTestingSdlc',
      'hasAllSystemRequirementsForTradingBeenMet',
      'config',
      'currencyPairs',
      'iAmAnUnmigratedIntermediary',
      'isWithinTradingHours',
      'isTodayWorkingDay',
      'isTradingEnabled',
      'isBeforeSameDayCutOffTimes',
    ]),
    ...mapState(['authUser', 'user', 'transaction', 'deal', 'swift', 'account', 'systemTodayAsString']),

    includeMarkupSelectors() {
      return ['transactionMarkups'].includes(this.actionGroup);
    },
    includeRefreshCounter() {
      return ['transactionLogs'].includes(this.actionGroup);
    },

    // ----- Title Bar -----

    titleBar() {
      const titleBar = {
        icon: this.titleBarIcon,
        label: this.titleBarLabel,
        buttons: this.titleBarButtons,
      };
      return titleBar;
    },

    titleBarIcon() {
      return this.$route.meta?.icon;
    },
    titleBarLabel() {
      // return `Client: ${this.accountRecord?.name} / Transaction File: ${this.transactionReference}`;
      return `${this.accountRecord?.name} / ${this.transactionTypeLabel}: ${this.transactionReference}`;
    },
    transactionTypeLabel() {
      let options = {
        outwardDeal: 'Outward Deal',
        transferDeal: 'Transfer Deal',
        inwardDeal: 'Inward Deal',
        outwardPayment: 'Outward Payment',
        inwardPayment: 'Inward Payment',
      };
      return options[this.transactionRecord?.transactionType];
    },
    titleBarButtons() {
      let self = this;
      let allButtons = [
        {
          name: 'goToClient',
          label: 'Go To Client',
          type: 'white',
          guards: this.authUser?.accountLevel !== 'client',
          routeName: 'account',
          routeParams: { accountId: this.transactionRecord?.accountId },
        },
        {
          name: 'newTransaction',
          label: 'New Transaction',
          type: 'white',
          guards: this.newTransactaction_buttonGuard,
          routeName: 'transactionSummaryViewNewTransaction',
          // routeParams: { accountId: this.transactionRecord?.accountId },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },

    newTransactaction_buttonGuard() {
      return (
        !this.iAmAnUnmigratedIntermediary &&
        // this.accountRecord.isActive && // TODO: remove this after testing - avr 2023-02-15
        this.accountRecord?.accountIsVisible && // replaced above with this
        this.accountRecord?.isClient &&
        this.accountRecord?.tradingEnabled &&
        (this.permissions?.bookDeal || this.permissions?.clientBookDeal || this.permissions?.bookTransfer) &&
        !this.accountRecord?.restrictedView
      );
    },

    // ----- Tabs -----

    showTabs() {
      return true;
    },
    tabs() {
      let tabs = [
        {
          tabName: 'summary',
          routeName: 'transactionSummaryView',
          guards: true, // TODO
          label: 'Summary',
        },
        {
          tabName: 'inwardSwift',
          routeName: 'transactionInwardSwiftDetailsView',
          routeParams: { swiftId: this.inwardSwiftId },
          guards: this.isInwardSwiftAssignedToPayment, // TODO
          label: 'Inward SWIFT',
        },
        {
          tabName: 'deals',
          routeName: 'transactionDealDetailsView',
          routeParams: { dealId: this.firstDealId },
          guards: this.hasAnyDeals, // TODO
          label: this.hasMultipleDeals ? 'Deals' : 'Deal',
        },
        {
          tabName: 'payments',
          routeName: 'transactionPaymentDetailsView',
          routeParams: { paymentId: this.firstPaymentId },
          guards: this.hasAnyPayments,
          label: this.hasMultiplePayments ? 'Payments' : 'Payment',
        },
        {
          tabName: 'markups',
          routeName: 'transactionMarkups',
          guards: this.hasAnyDeals && this.authUser?.accountLevel !== 'client', // TODO
          label: 'Markups',
        },
        {
          tabName: 'dealPayments',
          routeName: 'dealPayments',
          guards: this.showDealPaymentsTab,
          label: 'Many to Many',
        },
        {
          tabName: 'logs',
          routeName: 'transactionLogs',
          guards: true, // TODO
          label: 'Logs',
        },
        {
          tabName: 'transactionDealsList', // TODO DELETE
          routeName: 'transactionDealsList',
          guards: this.hasMultipleDeals && this.isDevelopment,
          label: '*** TEMP DEAL LIST***',
        },
        {
          tabName: 'partialDrawdowns',
          routeName: 'partialDrawdowns',
          guards: this.showPartialDrawdownsTab,
          label: 'Partial Drawdowns',
        },
      ];
      return tabs.filter((tab) => tab.guards);
    },

    // ----- Stepper -----

    showStepper() {
      return false;
    },

    // ----- Content Label -----

    showLabel() {
      return this.contentLabel ? true : false;
    },
    contentLabel() {
      return this.$route.meta?.content?.label || "Navigation didn't complete, please click tab above to continue"; // TODO
    },

    // ----- Action Bar -----

    showActions() {
      return this.actionBarButtons.length !== 0 ? true : false;
    },
    actionBarButtons() {
      if (this.iAmAnUnmigratedIntermediary) {
        return [];
      }
      let self = this;
      let allButtons = {
        transactionSummaryView: [
          {
            name: 'mergeTransactions',
            label: 'Merge Transaction Files',
            type: '',
            guards:
              this.permissions.mergeTransactionFiles &&
              this.transactionRecord.checkIfManyToManyFunctionCanTakePlace &&
              !this.transactionRecord?.isValueDateInThePast,
            enabledGuards:
              !this.transactionRecord?.hasSubmitted_activePayments && this.transactionRecord.noPaymentsHaveBeenSubmitted,
            clickEvent() {
              self.$router.push({ name: 'transactionSummaryViewMerge' });
            },
          },
          {
            name: 'checkStatus',
            label: 'Check and Update Status',
            type: '',
            action: 'checkAndUpdateTfStatus',
            params: { transactionId: self.transactionId },
            guards: !this.transactionRecord.transactionLocked,
          },
        ],
        transactionInwardSwiftDetailsView: [
          {
            name: 'goToSwift',
            label: 'Go To SWIFT',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({ name: 'swift' });
            },
          },
        ],
        transactionDealsList: [],
        transactionDealDetailsView: [
          {
            name: 'goToPartialDrawdown',
            label: 'Go To Partial Drawdown',
            type: '',
            guards:
              this.dealRecord.forwardModificationType === 'partialDrawdown' &&
              (this.dealRecord.releaseFecModificationButtonGuard || this.dealRecord.claimDealButtonGuard) && // TODO - allow button if deal is superseded and for TOC users
              !!this.activePartialDrawdownDeal,
            clickEvent() {
              self.$router.push({
                name: 'transactionDealDetailsView',
                params: {
                  accountId: self.transactionRecord?.accountId,
                  transactionId: self.activePartialDrawdownDeal?.transactionFileId,
                  dealId: self.activePartialDrawdownDeal?.id,
                },
              });
            },
          },
          {
            name: 'goToParentDeal',
            label: 'Go To Parent Deal',
            type: '',
            guards: !!this.dealRecord?.isPartialDrawdown && !!this.deal?.[this.dealRecord.supersedesDealId],
            clickEvent() {
              self.$router.push({
                name: 'transactionDealDetailsView',
                params: {
                  accountId: self.transactionRecord?.accountId,
                  transactionId: self.deal?.[self.dealRecord.supersedesDealId]?.transactionFileId,
                  dealId: self.dealRecord.supersedesDealId,
                },
              });
            },
          },
          // Bank Only
          {
            name: 'claimDeal',
            label: 'Claim Deal',
            type: 'primary',
            modal: {
              header: `Claim: ${this.dealRecord.reference}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Claim',
              content: this.claimDealModalMessage,
            },
            action: 'claimDeal',
            params: { transactionId: self.transactionId, dealId: self.dealId },
            guards: this.dealRecord.claimDealButtonGuard,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },

          {
            name: 'releaseDeal',
            label: 'Release Deal',
            type: 'primary',
            guards: this.dealRecord.releaseDealButtonGuard,
            clickEvent() {
              self.$router.push({ name: 'releaseDeal' });
            },
          },
          {
            name: 'updateDealSettlementAccount',
            label: 'Update Settlement Account',
            type: 'primary',
            guards: this.dealRecord.updateDealSettlementAccountButtonGuard,
            clickEvent() {
              self.$router.push({ name: 'updateDealSettlementAccount' });
            },
          },
          {
            name: 'resendDeal',
            label: 'Resend Deal',
            type: 'primary',
            modal: {
              header: `Resend Deal: ${this.dealRecord.reference}`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Resend',
              content: this.resendDealModalMessage,
            },
            action: 'resendDeal',
            params: { transactionId: self.transactionId, dealId: self.dealId },
            guards: this.dealRecord.resendDealButtonGuard,
            // clickEvent: 'onLDBButtonClick', // TODO remove this - avr
          },
          {
            name: 'modifyValueDate',
            label: 'Modify Value Date',
            type: '',
            guards: this.dealRecord.modifyValueDateGuard,
            clickEvent() {
              self.$router.push({ name: 'modifyValueDate' });
            },
          },
          {
            name: 'drawDownFec',
            label: 'Draw Down FEC',
            type: '',
            guards: this.dealRecord.drawDownFecGuard,
            clickEvent() {
              self.$router.push({ name: 'fecDrawDown' });
            },
          },

          {
            name: 'reviewFec',
            label:
              this.dealRecord.forwardModificationType === 'partialDrawdown'
                ? 'Review Partial Drawdown'
                : 'Review FEC Modification',
            type: 'primary',
            guards:
              this.dealRecord.releaseFecModificationButtonGuard &&
              (this.dealRecord?.forwardModificationType === 'partialDrawdown' ? !!this.activePartialDrawdownDeal : true),
            clickEvent() {
              self.$router.push({
                name:
                  self.dealRecord.forwardModificationType === 'partialDrawdown'
                    ? 'reviewFecPartialDrawdown'
                    : 'reviewFec',
                params: { transactionId: self.transactionId, dealId: self.dealId },
              });
            },
          },
          {
            name: 'rejectDeal',
            label: 'Reject Deal',
            type: '',
            guards:
              this.dealRecord?.rejectDealButtonGuard &&
              !this.dealRecord?.fecModificationIsReleaseable &&
              !(this.dealRecord?.isPartialDrawdown && this.dealRecord.status === 'requested') &&
              !(
                this.dealRecord?.supersedesDealId &&
                this.deal?.[this.dealRecord.supersedesDealId]?.status === 'fecModificationRequested'
              ),
            clickEvent() {
              self.$router.push({ name: 'rejectDeal' });
            },
          },

          // Intermediary
          {
            name: 'updateExternalReference',
            label: 'Update Deal External Reference',
            type: '',
            guards:
              ['prepared', 'requested', 'processed', 'booked', 'released', 'fecModificationRequested'].includes(
                this.dealRecord.status
              ) && !!this.permissions.updateDealExternalReference,
            clickEvent() {
              self.$router.push({ name: 'updateDealExternalReference' });
            },
          },

          {
            name: 'addDeal',
            label: 'Add Deal',
            type: '',
            guards:
              this.permissions.bookDeal &&
              this.transactionRecord?.transactionType === 'outwardDeal' &&
              !['completed', 'rejected', 'cancelled', 'merged'].includes(this.transactionRecord?.status), // TODO
            enabledGuards:
              this.accountRecord.accountIsVisible &&
              this.hasAllSystemRequirementsForTradingBeenMet &&
              this.transactionRecord.checkIfManyToManyFunctionCanTakePlace &&
              this.valueDateAndTimeAllowAddDeal &&
              this.currencyPairAllowsTrading &&
              !this.transactionRecord?.hasSubmitted_activePayments,
            clickEvent() {
              self.$router.push({
                name: 'addDealToTransactionFileCapture',
                params: { accountId: self.accountId, transactionId: self.transactionId },
              });
            },
          },
          {
            name: 'splitDeal',
            label: 'Split Deal Out',
            type: '',
            guards:
              this.permissions.splitDealToNewTf &&
              this.dealRecord?.status === 'released' &&
              this.transactionRecord?.hasMultipleReleasedDeals &&
              this.transactionRecord.checkIfDealCanBeSplit, // Added 2023-11-15 - Prevents bad deals from being removed from complex TF
            // this.transactionRecord.checkIfManyToManyFunctionCanTakePlace, // REMOVED 2023-11-15 - Prevents bad deals from being removed from complex TF
            // !this.transactionRecord?.isValueDateInThePast, // REMOVED 2023-11-15 - Prevents bad deals from being removed from complex TF
            enabledGuards: !this.transactionRecord?.hasSubmitted_activePayments,
            clickEvent() {
              self.$router.push({ name: 'transactionDealDetailsViewSplit' });
            },
          },

          {
            name: 'modifyFec',
            label: 'Modify FEC',
            type: '',
            guards:
              this.dealRecord.deliveryType === 'FWD' &&
              this.dealRecord.status === 'released' &&
              this.permissions.fecRequestDrawdown &&
              !this.transactionRecord?.isValueDateInThePast,
            enabledGuards: this.isWithinTradingHours && this.isTodayWorkingDay && this.isTradingEnabled,
            clickEvent() {
              self.$router.push({
                name: 'modifyFec',
                params: { transactionId: self.transactionId, dealId: self.dealId },
              });
            },
          },
          {
            name: 'downloadDealConfirmation',
            label: 'Download Confirmation',
            type: '',
            guards: !!this.dealRecord.confirmationS3Key,
            action: 'downloadDealConfirmation',
            params: { transactionId: self.transactionId, dealId: self.dealId },
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'dealLogs',
            label: 'Deal History',
            type: '',
            guards: true,
            clickEvent() {
              self.$router.push({
                name: 'dealLogs',
                params: { transactionId: self.transactionId, dealId: self.dealId },
              });
            },
          },
          {
            name: 'dealEmailLogs',
            label: 'Emails',
            type: '',
            guards: this.isTestingSdlc,
            clickEvent() {
              self.$router.push({
                name: 'dealEmailLogs',
                params: { transactionId: self.transactionId, dealId: self.dealId },
              });
            },
          },
        ],
        transactionPaymentDetailsView: [
          //
          // ----- Payment
          {
            name: 'captureAndSubmitPayment',
            label: this.captureAndSubmitPayment_buttonLabel,
            type: 'primary',
            guards: this.paymentRecord?.capturePayment_buttonGuard || this.paymentRecord?.submitPayment_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentDetailsViewCapture' });
            },
          },

          {
            name: 'addPaymentForManyToMany',
            label: 'Add Payment',
            type: '',
            action: 'click',
            guards:
              this.permissions.addTransactionFilePayment &&
              this.transactionRecord.checkIfManyToManyFunctionCanTakePlace &&
              !this.transactionRecord?.isValueDateInThePast,
            enabledGuards: !this.transactionRecord?.hasSubmitted_activePayments,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentDetailsViewAddPayment' });
            },
          },
          {
            name: 'editAmountForManyToMany',
            label: 'Edit Amount',
            type: '',
            action: 'click',
            guards:
              this.paymentRecord?.editAmountForManyToMany_buttonGuard &&
              this.transactionRecord?.areAllActivePaymentsUnsubmitted &&
              this.transactionRecord?.hasMultiple_activePayments &&
              this.transactionRecord.checkIfManyToManyFunctionCanTakePlace &&
              !this.transactionRecord?.isValueDateInThePast,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentDetailsViewEditAmount' });
            },
          },
          {
            name: 'cancelPaymentForManyToMany',
            label: 'Delete Payment',
            type: '',
            action: 'click',
            guards:
              this.paymentRecord?.editAmountForManyToMany_buttonGuard &&
              this.transactionRecord?.areAllActivePaymentsUnsubmitted &&
              this.transactionRecord?.hasMultiple_activePayments &&
              this.transactionRecord.checkIfManyToManyFunctionCanTakePlace &&
              !this.transactionRecord?.isValueDateInThePast,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentDetailsViewCancelPayment' });
            },
          },

          {
            name: 'editPaymentValue',
            label: this.transactionRecord.transactionType === 'outwardPayment' ? 'Modify Value and Date' : 'Modify Date',
            type: '',
            guards:
              this.paymentRecord.editPaymentValue_buttonGuard &&
              ['inwardPayment', 'outwardPayment'].includes(this.transactionRecord.transactionType),
            clickEvent() {
              self.$router.push({ name: 'editTransactionFilePaymentAmount' });
            },
          },
          {
            name: 'cancelPayment',
            label: 'Cancel Payment',
            type: '',
            action: 'cancelTransferPayment',
            params: { transactionId: self.transactionId, paymentId: self.paymentId },
            modal: {
              header: `Cancel Payment`,
              cancelLabel: 'Cancel',
              confirmLabel: 'Cancel Payment',
              content: this.cancelTransferPaymentModalMessage,
            },
            guards:
              this.paymentRecord.cancelTransferPayment_buttonGuard &&
              ['inwardPayment', 'outwardPayment'].includes(this.transactionRecord.transactionType),
          },

          {
            name: 'updatePaymentStatus',
            label: 'Update Status',
            type: '',
            guards: this.paymentRecord.updatePaymentStatus_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentBankUpdateStatus' });
            },
          },

          // ----- Portfolio

          {
            name: 'downloadArchivedPortfolio',
            label: 'Download Portfolio',
            type: '',
            guards:
              this.paymentRecord.currentPortfolioId && this.paymentRecord.portfolioIsArchived && this.isTestingSdlc,
            action: 'downloadArchivedPortfolio',
            params: { transactionId: self.transactionId, paymentId: self.paymentId },
            onSuccess: { doStoreUpdate: false },
          },
          {
            name: 'viewPortfolio',
            label: 'View Portfolio',
            type: 'primary',
            guards:
              this.paymentRecord.viewPortfolio_buttonGuard &&
              (!this.paymentRecord.portfolioIsArchived || !this.isTestingSdlc),
            async clickEvent() {
              const viewPortfolioResult = await doPermissionAction(
                { onSuccess: { doStoreUpdate: false } },
                'getPortfolioLink',
                {
                  transactionId: self.transactionId,
                  paymentId: self.paymentId,
                }
              );
              if (viewPortfolioResult.status === 'success') {
                window.open(viewPortfolioResult.response.portfolioUrl);
              } else {
                self.showToast({
                  type: 'error',
                  header: 'Error Building Link',
                  message: viewPortfolioResult.response.message,
                  show: true,
                });
              }
            },
          },
          {
            name: 'getPortfolioSummary',
            label: 'Portfolio Summary',
            type: '',
            guards: this.paymentRecord.portfolioSummary_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentPortfolioSummary' });
            },
          },
          {
            name: 'addDocumentToPaymentPortfolio',
            label: 'Add Portfolio Document',
            type: '',
            guards: this.paymentRecord.addDocumentPayment_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentAddDocument' });
            },
          },
          {
            name: 'recallPaymentPortfolio',
            label: 'Recall Portfolio',
            type: '',
            guards: this.paymentRecord.recallPayment_buttonGuard,
            clickEvent() {
              self.$router.push({ name: 'transactionPaymentRecallPortfolio' });
            },
          },
          {
            name: 'portfolioLogs',
            label: 'Portfolio Logs',
            type: '',
            guards: this.paymentRecord.viewPortfolioLogs_buttonGuard,
            clickEvent() {
              self.$router.push({
                name: 'paymentMessageLogs',
                params: { transactionId: self.transactionId, paymentId: self.paymentId },
              });
            },
          },
          {
            name: 'portfolioHistory',
            label: 'Portfolio History',
            type: '',
            guards: this.paymentRecord.viewPortfolioLogs_buttonGuard,
            clickEvent() {
              self.$router.push({
                name: 'paymentPortfolioHistory',
                params: { transactionId: self.transactionId, paymentId: self.paymentId },
              });
            },
          },
          {
            name: 'paymentEmailLogs',
            label: 'Emails',
            type: '',
            guards: this.isTestingSdlc,
            clickEvent() {
              self.$router.push({
                name: 'paymentEmailLogs',
                params: { transactionId: self.transactionId, dealId: self.paymentId },
              });
            },
          },
        ],
        transactionMarkups: [
          {
            name: 'showHideCancelledAndRejected',
            label: this.includeCancelledAndRejected ? 'Hide Cancelled and Rejected' : 'Show Cancelled and Rejected',
            type: '',
            guards: true,
            action: 'click',
            params: {},
            httpParams: {},
            clickEvent: self.toggleIncludeCancelledAndRejected,
          },
        ],
        dealPayments: [],
        transactionLogs: [
          {
            name: 'refreshLogs',
            label: 'Refresh',
            type: '',
            guards: true,
            action: 'click',
            params: {},
            httpParams: {},
            clickEvent: self.incrementRefreshCounter,
          },
        ],
      };
      return allButtons[this.actionGroup]?.filter((button) => button.guards) || [];
    },

    captureAndSubmitPayment_buttonLabel() {
      if (this.paymentRecord?.capturePayment_buttonGuard && this.paymentRecord?.submitPayment_buttonGuard) {
        return 'Edit and Submit Payment';
      } else if (this.paymentRecord?.submitPayment_buttonGuard) {
        return 'Submit Payment';
      } else if (this.paymentRecord?.capturePayment_buttonGuard) {
        return 'Capture Payment';
      } else {
        return 'Payment';
      }
    },

    valueDateAndTimeAllowAddDeal() {
      let res = true;
      const currencyPair = this.config.currencyPair?.[this.dealRecord.currencyPair]; // Note: this won't work if we allow multiple currency pairs in a transaction
      if (
        this.transactionRecord?.isValueDateInThePast ||
        (this.transactionRecord?.isValueDateToday &&
          (!currencyPair?.allowSameDay || !this.isBeforeSameDayCutOffTimes.outwardDeal))
      ) {
        res = false;
      }
      return res;
    },

    currencyPairAllowsTrading() {
      return this.currencyPairs?.[this.dealRecord?.currencyPair]?.allowTrading ? true : false;
    },

    actionGroup() {
      return this.$route.meta?.content?.actionGroup;
    },

    // ----- Banners -----

    showBanners() {
      return this.banners?.length > 0 || false;
    },
    banners() {
      let allBanners = {
        all: [
          {
            id: `valueDateInPast-${this.transactionId}`,
            title: 'Value Date In the Past',
            status: this.valueDateBanner.status,
            closable: false,
            content: this.valueDateBanner.content,
            guards:
              this.transactionRecord?.isValueDateInThePast &&
              this.transactionRecord?.status === 'awaitingPayment' &&
              this.transactionRecord?.transactionLocked === false,
          },
          {
            id: `valueDateConflict-${this.transactionId}`,
            title: 'Value Date Conflicts',
            status: 'error',
            closable: false,
            content: `The deals in this Transaction File do not share the same value date. This will need to be rectified before any further actions can be taken`,
            guards: this.transactionRecord?.consolidatedStatus === 'dealConflict',
          },
          {
            id: `amountConflict-${this.transactionId}`,
            title: 'Amounts Conflicts',
            status: 'error',
            closable: false,
            content: `The total amount of the deals in this Transaction File do not match the total amount of the payments. This will need to be rectified before any further actions can be taken`,
            guards: this.transactionRecord?.consolidatedStatus === 'paymentConflict',
          },
        ],
        transactionSummaryView: [
          {
            id: `bopAmountConflict-${this.transactionId}`,
            title: 'BOP Category Amounts Conflicts',
            status: 'warning',
            closable: false,
            content: `There is a conflict with the BOP Category Foreign Amounts for one of the payments in this Transaction File`,
            guards: this.transactionRecord?.consolidatedStatus === 'bopCatAmountConflict',
          },
        ],
        transactionDealDetailsView: [
          {
            id: `dealRejected-${this.dealId}`,
            title: 'Deal Rejected',
            status: 'error',
            closable: false,
            content: this.dealRecord?.rejectionReason,
            guards: this.dealRecord?.status === 'rejected',
          },
          {
            id: `dealNotClaimed-${this.dealId}`,
            title: 'Deal Not Yet Claimed',
            status: 'warning',
            closable: false,
            content: `This deal has not yet been claimed and cannot be manually Released.`,
            guards:
              this.dealRecord?.dealIsReleaseable &&
              !this.dealRecord.bankDealerUserId &&
              !this.dealRecord?.isPartialDrawdown,
          },
          {
            id: `dealNotClaimed-${this.dealId}`,
            title:
              'FEC Modification Not Yet Claimed - ' +
              getOptionLabel(
                this.dealRecord?.definition,
                'forwardModificationType',
                this.dealRecord.forwardModificationType
              ),
            status: 'warning',
            closable: false,
            content: `This modifcation has not yet been claimed and cannot be manually Released.`,
            guards:
              this.dealRecord.fecModificationIsReleaseable &&
              !this.dealRecord.bankDealerUserId &&
              !this.dealRecord?.isPartialDrawdown,
          },
          {
            id: `dealNotClaimed-${this.dealId}`,
            title: 'Deal Claimed by Other',
            status: 'info',
            closable: false,
            content: `This deal has been claimed by ${
              this.user?.[this.dealRecord.bankDealerUserId]?.displayName || this.dealRecord.bankDealerUserId
            }.`,
            guards:
              (this.dealRecord?.dealIsReleaseable || this.dealRecord.fecModificationIsReleaseable) &&
              this.dealRecord.bankDealerUserId &&
              this.dealRecord.bankDealerUserId !== this.authUser.id,
          },
          {
            id: `dealClaimed-${this.dealId}`,
            title: 'Deal Claimed by You',
            status: this.claimedDealStatus,
            closable: false,
            content: `You have claimed this deal. <br/> \nCIF: <b>${
              this.accountRecord?.cifNumber || 'Not Captured'
            }</b><br/>BP: <b>${this.accountRecord?.bpNumber || 'Not Captured'}</b>${this.supersedesDealNumberText}
            ${
              this.dealRecord.fecModificationIsReleaseable
                ? `<br/>Modification Type: <b>${getOptionLabel(
                    this.dealRecord?.definition,
                    'forwardModificationType',
                    this.dealRecord.forwardModificationType
                  )}</b>`
                : ''
            }
            ${
              this.dealRecord.fecModificationIsReleaseable &&
              this.dealRecord?.forwardModificationType === 'partialDrawdown'
                ? `<br/>Drawdown Amount: <b>${this.activePartialDrawdownAmount}</b>`
                : ''
            }
            ${
              this.dealRecord.fecModificationIsReleaseable &&
              this.dealRecord?.forwardModificationType === 'partialDrawdown'
                ? `<br/>Drawdown Delivery Type: <b>${this.activePartialDrawdownDeliveryType}</b>`
                : ''
            }
            ${
              this.dealRecord.fecModificationIsReleaseable &&
              this.dealRecord?.forwardModificationType === 'partialDrawdown'
                ? `<br/>Drawdown Value Date: <b>${this.activePartialDrawdownValueDate}</b>`
                : ''
            }`,
            guards:
              (this.dealRecord?.dealIsReleaseable || this.dealRecord.fecModificationIsReleaseable) &&
              this.dealRecord.bankDealerUserId &&
              this.dealRecord.bankDealerUserId === this.authUser.id,
          },
          {
            id: `dealUnClaimedPartial-${this.dealId}`,
            title: 'Deal is Partial Drawdown',
            status: 'warning',
            closable: false,
            content: `This deal is a partial drawdown of an FEC it must be released or rejected from the parent deal.`,
            guards:
              (this.dealRecord?.dealIsReleaseable || this.dealRecord?.fecModificationIsReleaseable) &&
              !this.dealRecord?.bankDealerUserId &&
              this.dealRecord?.isPartialDrawdown,
          },
        ],
        transactionPaymentDetailsView: [
          {
            id: `paymentSendBack-${this.paymentId}`,
            title: this.paymentRecord?.status === 'informationQuery' ? 'Paynent Information Query' : 'Payment Sent Back',
            status: this.paymentRecord?.status === 'informationQuery' ? 'warning' : 'error',
            closable: false,
            content: this.paymentRecord?.rejectionReason,
            guards:
              this.paymentRecord?.rejectionReason &&
              ['readyForSubmission', 'informationQuery', 'cancelled'].includes(this.paymentRecord?.status),
          },

          {
            id: `bopAmountConflictPayment-${this.paymentId}`,
            title: 'BOP Category Amounts Conflicts',
            status: 'error',
            closable: false,
            content: `There is a conflict with the BOP Category Foreign Amounts. This will need to be rectified before any further actions can be taken`,
            guards: this.paymentRecord?.consolidatedStatus === 'bopCatAmountConflict',
          },
          {
            id: `paymentIsBalancePayment-${this.paymentId}`,
            title: 'Balance Payment',
            status: 'info',
            closable: false,
            content: `This is the balance payment for the Transaction File. It can not be modified directly but will update when any other payment is updated.`,
            guards:
              this.transactionRecord.activePayments > 1 &&
              this.paymentIsBalancePayment &&
              !['complete', 'cancelled', 'deleted'].includes(this.paymentRecord.status),
          },
          {
            id: `paymentIsReadyForSubmitButSignatoriesOutstanding-${this.paymentId}`,
            title: 'Signatories Still to Be Captured',
            status: 'info',
            closable: false,
            content: `This payment has been marked as Ready For Submission but the signatories have not yet been captured. They will need to be captured before the payment can be submitted.`,
            guards:
              ['readyForSubmission'].includes(this.paymentRecord?.status) &&
              this.paymentRecord?.outstandingFields.includes('signatories') &&
              this.authUser?.accountLevel !== 'client',
          },
          {
            id: `paymentIsReadyForSubmitButContacInformationIsOutstanding-${this.paymentId}`,
            title: 'Client Contact Information is Outstanding',
            status: 'warning',
            closable: false,
            content: `The payment is ready for submission but the client's contact details are outstanding. Please use the Update Contact Details button on the client's profile to retrieve them from the Bank.
            You will need the <b>Manage Signatory</b> permission to perform this action.`,
            guards:
              false &&
              ['readyForSubmission'].includes(this.paymentRecord?.status) &&
              this.accountRecord.contactDtailsOutstanding,
          },
          {
            id: `remitterAccountCouldBeRounded-${this.inwardSwiftId}`,
            title: 'SWIFT Remitter Account could be Rounded',
            status: 'warning',
            closable: false,
            content: `The remitter's bank account in this SWIFT is very long and may have been rounded during import. Please check to confirm that it is correct and contact support@landobyte.com if it is not.`,
            guards:
              !!this.inwardSwiftRecord?.warnAboutPotentialRemitterAccountRounding &&
              ['new', 'readyForSubmission'].includes(this.paymentRecord?.status),
          },
        ],
      };
      const availableBanners = [...(allBanners[this.bannerGroup] || []), ...allBanners.all];
      return availableBanners.filter((banner) => banner.guards);
    },

    valueDateBanner() {
      const banner = {
        status: 'error',
        content: `The value date is in the past. Please contact your dedicated <strong>Payments Team</strong>.`,
      };

      if (
        ['outwardPayment', 'inwardPayment'].includes(this.transactionRecord?.transactionType) &&
        this.transactionRecord?.numberOf_unsubmitted_activePayments == 1
      ) {
        banner.status = 'warning';
        if (this.permissions?.editTransferPayment) {
          banner.content = `The value date is in the past. You can amend the value date on the Payment tab.`;
        } else {
          banner.content = `The value date is in the past. A user with the correct permission can amend the value date on the Payment tab.`;
        }
      }

      // if (this.transactionRecord?.numberOf_unsubmitted_activePayments == 0) {
      //   banner.status = 'warning';
      //   if (this.transactionRecord?.hasMultiple_activePayments) {
      //     banner.content = `As all open payments have been submitted, you should be able to continue without updating the value date. Please contact your <strong>Payments Team</strong> if you are unsure.`;
      //   } else {
      //     banner.content = `As the payment has been submitted, you should be able to continue without updating the value date. Please contact your <strong>Payments Team</strong> if you are unsure.`;
      //   }
      // }

      return banner;
    },

    claimedDealStatus() {
      if (this.dealRecord?.forwardModificationType === 'partialDrawdown') {
        switch (this.activePartialDrawdownDeal_retrievalStatus) {
          case 'unretrieved':
            return 'info';
          case 'retrieving':
            return 'info';
          case 'retrieved':
            if (this.activePartialDrawdownDeal) {
              return 'success';
            } else {
              return 'error';
            }
          case 'failed':
            return 'error';
          default:
            return 'info';
        }
      }
      return 'success';
    },

    supersedesDeal() {
      if (this.dealRecord.supersedesDealId) {
        if (this.deal[this.dealRecord.supersedesDealId]) {
          this.supersededDeal_retrievalStatus = 'retrieved';
          return this.deal[this.dealRecord.supersedesDealId];
        } else if ((this.supersededDeal_retrievalStatus = 'unretrieved')) {
          this.getSupersededDeal();
        }
        // if (this.supersededDeal_retrievalStatus === 'unretrieved') {
        //   const response = doPermissionAction({}, 'getDeal', {
        //     transactionId: this.transactionId,
        //     dealId: this.dealRecord.supersedesDealId,
        //   });
        //   if (response.status === 'success') {
        //     this.supersededDeal_retrievalStatus = 'retrieved';
        //   } else {
        //     this.supersededDeal_retrievalStatus = 'failed';
        //   }
        // }
      }
      return undefined;
    },
    supersedesDealNumberText() {
      if (this.supersedesDeal) {
        return `<br/>Supersedes Deal Number: <b>${this.supersedesDeal.dealNumber}</b>`;
      }
      return '';
    },

    activePartialDrawdownDeal() {
      if (this.dealRecord?.activePartialDrawdownDeal) {
        this.activePartialDrawdownDeal_retrievalStatus = 'retrieved';
      } else if (this.activePartialDrawdownDeal_retrievalStatus === 'unretrieved') {
        this.getActivePartialDrawdownDeal();
      }
      return this.dealRecord?.activePartialDrawdownDeal;
    },
    activePartialDrawdownAmount() {
      switch (this.activePartialDrawdownDeal_retrievalStatus) {
        case 'unretrieved':
          return '';
        case 'retrieving':
          return 'Loading...';
        case 'retrieved':
          if (this.activePartialDrawdownDeal) {
            return this.dealRecord?.activePartialDrawdownAmount;
          } else {
            return 'Failed to retrieve partial drawdown deal';
          }
        case 'failed':
          return 'Failed to retrieve partial drawdown deal';
      }
    },
    activePartialDrawdownDeliveryType() {
      switch (this.activePartialDrawdownDeal_retrievalStatus) {
        case 'unretrieved':
          return '';
        case 'retrieving':
          return 'Loading...';
        case 'retrieved':
          if (this.activePartialDrawdownDeal) {
            return getOptionLabel(this.definition, 'deliveryType', this.activePartialDrawdownDeal?.deliveryType);
          } else {
            return 'Failed to retrieve partial drawdown deal';
          }
        case 'failed':
          return 'Failed to retrieve partial drawdown deal';
      }
    },
    activePartialDrawdownValueDate() {
      switch (this.activePartialDrawdownDeal_retrievalStatus) {
        case 'unretrieved':
          return '';
        case 'retrieving':
          return 'Loading...';
        case 'retrieved':
          if (this.activePartialDrawdownDeal) {
            return this.activePartialDrawdownDeal?.valueDate?.substring(0, 10);
          } else {
            return 'Failed to retrieve partial drawdown deal';
          }
        case 'failed':
          return 'Failed to retrieve partial drawdown deal';
      }
    },

    bannerGroup() {
      return this.$route.meta?.content?.actionGroup; // TODO
    },

    // ----- Layout -----

    formLayout() {
      return this.$route.meta?.content?.layout === 'list' ? false : true;
    },

    // ----- Sidebar -----

    showSidebar() {
      return this.$route.params?.name === 'transactionDealView' && this.hasMultipleDeals ? true : false;
    },

    // ----- Transaction Record -----

    transactionId() {
      return +this.$route.params?.transactionId;
    },
    transactionRecord() {
      return this.transaction?.[this.transactionId];
    },

    transactionReference() {
      return this.transactionRecord?.reference;
    },

    hasAnyDeals() {
      return this.transactionRecord?.hasAnyDeals;
    },
    hasMultipleDeals() {
      return this.transactionRecord?.hasMultipleDeals;
    },
    firstDealId() {
      return +this.transactionRecord?.firstDealId;
    },

    hasAnyPayments() {
      return this.transactionRecord?.hasAnyPayments;
    },
    hasMultiplePayments() {
      return this.transactionRecord?.hasMultiplePayments;
    },
    firstPaymentId() {
      return +this.transactionRecord?.firstPaymentId;
    },
    hasDealPayments() {
      return (
        typeof this.transactionRecord.dealPayment === 'object' &&
        Object.keys(this.transactionRecord.dealPayment).length > 0
      );
    },
    showDealPaymentsTab() {
      const showTab = this.hasMultipleDeals && this.hasMultipleDeals && this.hasDealPayments;
      return showTab;
    },

    // ----- Deal Record -----

    dealId() {
      return +this.$route.params?.dealId;
    },
    dealRecord() {
      return this.transactionRecord?.deals?.[this.dealId] || {};
    },
    showPartialDrawdownsTab() {
      return false;
      // const showTab = this.dealRecord.deliveryType === 'FWD' && this.dealRecord.partialDrawdownDeals && Object.keys(this.dealRecord.partialDrawdownDeals).length !== 0;
      // return showTab
    },

    // ----- Payment Record -----

    paymentId() {
      return +this.$route.params?.paymentId;
    },
    paymentRecord() {
      return this.transactionRecord?.payments?.[this.paymentId] || {};
    },
    paymentIsBalancePayment() {
      return this.paymentId && this.paymentId === this.transactionRecord.balancePaymentId;
    },

    // ----- Inward SWIFT Record -----

    firstPaymentId() {
      return +this.transactionRecord?.firstPaymentId;
    },
    firstPaymentRecord() {
      return this.transactionRecord?.payments?.[this.firstPaymentId] || {};
    },
    inwardSwiftId() {
      return +this.firstPaymentRecord?.inwardSwiftId;
    },
    inwardSwiftRecord() {
      if (this.swift?.[this.inwardSwiftId]) {
        this.inwardSwift_retrievalStatus = 'retrieved';
      } else if (this.inwardSwift_retrievalStatus === 'unretrieved') {
        this.getInwardSwift();
      }
      return this.swift?.[this.inwardSwiftId];
    },
    inwardSwiftPaymentId() {
      return this.inwardSwiftId ? this.inwardSwiftRecord?.paymentId : undefined;
    },
    isInwardSwiftAssignedToPayment() {
      return (
        (this.inwardSwiftId &&
          this.inwardSwiftRecord?.status === 'assigned' &&
          this.inwardSwiftPaymentId === this.firstPaymentId) ||
        false
      );
    },

    // ----- Account Record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId] || {};
    },

    // --------------- Modal Message ---------
    claimDealModalMessage() {
      return `
      <ol>
        <li>Claims the deal and allows the user to manually release it.</li>
        ${
          this.dealRecord.bankDealerUserId
            ? '<li>Deal currently claimed by ' +
              (this.user?.[this.dealRecord.bankDealerUserId]?.displayName || this.dealRecord.bankDealerUserId) +
              '</li>'
            : ''
        }
      </ol>`;
    },
    resendDealModalMessage() {
      return `
      <ol>
        <li>The deal will be resent to Treasury.</li>
      </ol>`;
    },
    cancelTransferPaymentModalMessage() {
      return `
      <ol>
        <li>The payment will be cancelled.</li>
      </ol>`;
    },
  },
  methods: {
    ...mapActions(['showToast']),
    ...mapMutations([]),
    //
    toggleIncludeCancelledAndRejected() {
      this.includeCancelledAndRejected = !this.includeCancelledAndRejected;
    },
    incrementRefreshCounter() {
      this.refreshCounter++;
    },
    // ----- Titlebar -----

    onTitleBarButtonClick(button) {
      this.$router.push({ name: button.routeName, params: button.routeParams });
    },

    // ----- Tabs -----

    selectFirstTab() {
      // this.$router.replace({
      //   name: 'transactionPaymentRedirectToPaymentCapture',
      //   params: { paymentId: this.transactionRecord.firstPaymentId },
      // });
      this.$router.replace({ name: this.tabs[0].routeName });
    },

    // ----- Load deals -----

    async getActivePartialDrawdownDeal() {
      this.activePartialDrawdownDeal_retrievalStatus = 'retrieving';
      const response = await doPermissionAction(
        {},
        'getDeals',
        {},
        { additionalSelector: { where: { supersedesDealId: this.dealId } } }
        // { additionalSelector: { where: { supersedesDealId: 40000000 } } } // to test case of zero return
        // { additionalSelector: { where: { banana: 4000000 } } } // to test failure case
      );
      if (response.status === 'success') {
        this.activePartialDrawdownDeal_retrievalStatus = 'retrieved';
      } else {
        this.activePartialDrawdownDeal_retrievalStatus = 'failed';
      }
    },
    async getSupersededDeal() {
      this.supersededDeal_retrievalStatus = 'retrieving';
      const response = await doPermissionAction(
        {},
        'getDeals',
        {},
        { additionalSelector: { where: { id: this.dealRecord.supersedesDealId } } }
        // { additionalSelector: { where: { supersedesDealId: 40000000 } } } // to test case of zero return
        // { additionalSelector: { where: { banana: 4000000 } } } // to test failure case
      );
      if (response.status === 'success') {
        this.supersededDeal_retrievalStatus = 'retrieved';
      } else {
        this.supersededDeal_retrievalStatus = 'failed';
      }
    },

    // ----- Load SWIFT -----

    async getInwardSwift() {
      this.inwardSwift_retrievalStatus = 'retrieving';
      // console.log('1');
      const response = await doPermissionAction(
        {},
        'getInwardSwifts',
        {},
        { additionalSelector: { where: { transactionFileId: this.transactionId } } }
      );

      if (response.status === 'success') {
        this.inwardSwift_retrievalStatus = 'retrieved';
      } else {
        this.inwardSwift_retrievalStatus = 'failed';
      }
      // console.log('2');
    },
  },
};
</script>
