<template>
  <LDBModal result="info" :header="header" size="large">
    <template #body>
      <h3>{{accountRecord?.name}}</h3>
      <div v-if="this.mandateStatus === 'none'">
        <p>TOC Mandate not available</p>
      </div>
      <div v-else-if="mandateStatus === 'requested'">
        <p>Retrieving TOC Mandate...</p>
      </div>
      <div v-else-if="this.mandateStatus === 'failed'">
        <p>TOC Mandate Error</p>
        {{ this.mandateError.displayMessage }}
      </div>
      <div v-else-if="this.mandateStatus === 'success'">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="item"
          :fieldname="field.name"
          :definition="undefined"
          :record="mandateData"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in buttons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import LDBLoader from '@/landobyte_vue/LDBLoader.vue';

export default {
  name: 'LfxClientCheckTocMandate',
  components: { LDBLoader },
  props: {},
  emits: {},
  async created() {
    this.mandateStatus = 'requested';
    this.mandateData.currentInterId = this.currentInterId;
    this.mandateData.currentInterCif = this.currentInterRecord.cifNumber;
    this.mandateData.currentInterName = this.currentInterRecord.name;
    const mandateResult = await doPermissionAction(this, 'getTocMandate', {
      accountId: this.accountId,
    });
    if (mandateResult.status === 'success') {
      // TODO handle no intermediary
      // TODO handle bank own intermediary
      this.mandateStatus = 'success';
      this.mandateData.mandateInterId = mandateResult.response.intermediaryIds?.[0];
      this.mandateData.mandateInterCif = mandateResult.response.intermediaryCifNumber;
      this.mandateData.mandateInterName = this.account?.[this.mandateData.mandateInterId]?.name || "Not Found";
      this.mandateData.mandateFound = mandateResult.response.mandateFound
      this.mandateData.mandateStatus = this.cifsAreInOrder ? 'In Order' : "Conflict";
    } else {
      this.mandateStatus = 'failed';
      this.mandateError = mandateResult?.response?.response?.data?.error || mandateResult?.response?.response?.data;
      if (this.mandateError.message) {
        this.mandateError.displayMessage = this.mandateError.message
        if (mandateResult?.response?.response?.data?.error.codeReason) {
          this.mandateError.displayMessage = mandateResult?.response?.response?.data?.error.codeReason + " - " + this.mandateError.displayMessage
        }
      } else {
        this.mandateError.displayMessage = 'An Unknown Error has Occured';
      }
    }
  },
  mounted() {},
  data() {
    return {
      onSuccess: {
        doStoreUpdate: false,
      },
      mandateStatus: 'none',
      mandateError: '',
      mandateData: {
        currentInterId:undefined,
        currentInterCif:undefined,
        currentInterName:undefined,
        mandateInterId:undefined,
        mandateInterCif:undefined,
        mandateInterName:undefined,
        mandateStatus:undefined,
        mandateFound:undefined
      },
    };
  },
  computed: {
    ...mapGetters(['config']),
    ...mapState(['account','authUser','user']),

    // ----- Account record -----

    accountId() {
      return +this.$route.params?.accountId;
    },
    accountRecord() {
      return this.account?.[this.accountId];
    },
    currentInterId() {
      return this.accountRecord?.intermediaryId
    },
    currentInterRecord() {
      return this.account?.[this.currentInterId];
    },
    // ----- Markup record ----

    header() {
      return `Check TOC Mandate - ` + this.accountRecord.name;
    },
    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta?.modal?.cancelRoute : 'todo';
    },
    buttons() {
      let self = this;
      let allButtons = [
        {
          name: 'closeMandateModal',
          label: 'Close',
          type: 'primary',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
      ];
      return allButtons.filter((button) => button.guards);
    },
    viewFields() {
      const allFields = [
        {
          name: 'currentInterName',
          label: 'CFS Intermediary Name',
          datatype: 'text',
        },
        {
          name: 'currentInterCif',
          label: 'CFS Intermediary CIF Number',
          datatype: 'text',
        },
        {
          name: 'mandateInterName',
          label: 'Mandate Intermediary Name',
          datatype: 'text',
        },
        {
          name: 'mandateInterCif',
          label: 'Mandate Intermediary CIF Number',
          datatype: 'text',
        },
        { name: 'mandateStatus', label: 'Mandate Status', datatype: 'text' },
      ];
      return allFields;
    },
    cifsAreInOrder() {
      if (this.currentInterRecord.bankDirectClients) {
        return !this.mandateData?.mandateFound
      } else {
        return this.mandateData?.mandateInterCif === this.currentInterRecord.cifNumber
      }
    }
  },
  methods: {
    onApiSuccess() {},
  },
};
</script>
