import VueStore from 'vue-class-store';

import { LdbDbObject, LdbDefinition } from '@/definitions/LdbInterfaces';
import { LfxApplicationInt, LfxApplicationDef } from '@/definitions/LfxApplicationDef';
import { LfxModel, LfxDefinition } from './LfxModel';

// @ts-expect-error
import { http_patch, http_get, http_put } from '@/lfx_rest';
import { LfxApplicationAccount } from './LfxApplicationAccountMdl';
import { LfxApplicationCif } from './LfxApplicationCifMdl';
import { doPermissionAction } from '@/lfx_rest/lfx_action';
import { db_assign_object_to_state } from '@/db-interface/db_state';
import { filterDocumentTypes } from '@/definitions/LfxApplicationDocumentTypeDef';

// @ts-ignore
@VueStore
// @ts-ignore
class LfxApplicationMdl extends LfxDefinition {
  // @ts-expect-error
  constructor(def, dbclass, context, name, config) {
    super(def, dbclass, context, name, config);
  }
}

// @ts-ignore
@VueStore
// @ts-ignore
export class LfxApplication extends LfxModel {
  // @ts-expect-error
  constructor(data, state) {
    super({ definition_name: 'application', ...data }, state);
  }
  get link() {
    // @ts-expect-error
    return `/client/${this.accountId}/application/${this.id}`;
  }
  get clientName() {
    //@ts-expect-error
    return this.store?.state?.account?.[this.accountId]?.name || this.reference;
  }
  get craApprovalHasExpired() {
      // @ts-expect-error
      for (const cifApplicationId in this.application_cif || {}) {
      // @ts-expect-error
      const cifApp = this.application_cif[cifApplicationId];
      if (cifApp.craApprovalHasExpired) {
        return true
      }
    }
    return false

  }
  get consolidatedStatus() {
    // console.log({// @ts-expect-error
    //   status:this.status,
    //   craEnabled:this.craEnabled,// @ts-expect-error
    //   craStatus:this.craStatus,
    //   craApprovalHasExpired:this.craApprovalHasExpired,
    //   documentUpload:this.thisApplicationDocumentUploadType,// @ts-expect-error
    //   applicationPortfolioStatus:this.applicationPortfolioStatus,// @ts-expect-error
    //   integrationStatus:this.integrationStatus,// @ts-expect-error
    //   mainCif_is_submittable:this.application_cif?.[this.fullCifId]?.is_submittable
    // })
    const documentUpload = this.thisApplicationDocumentUploadType
    // @ts-expect-error
    switch (this.status) {
      case 'new':
        // @ts-expect-error
        if (this.applicationPortfolioStatus === 'new'&& (!this.craEnabled && this.craStatus !== 'notStarted' ) ) {
          return 'new';
        } else {// @ts-expect-error
          if (this.application_cif?.[this.fullCifId]?.is_submittable) {
            return 'readyForSubmission';
          } else {
            return 'new'
          }
        }
      case 'inProgress':
        // @ts-expect-error
        switch (this.craStatus) {
          case 'inProgress': return 'craInProgress'
          case 'declined': return 'craDeclined'
          case 'error': return 'craError'
          case 'reOpened': return 'craReOpened'
          case 'eddRequested': 
            return 'craEddRequested'
          case 'eddSubmitted':
            return 'craEddSubmitted'
          case 'approved':
            if (this.craApprovalHasExpired) {
              return 'craExpired'
            }
          case 'notStarted': 
          case null:
          case undefined:
          break 
        }
        if (documentUpload === 'signiFlow') {
          // @ts-expect-error
          switch (this.applicationPortfolioStatus) {
            case 'new':
              return 'new';
            case 'readyForSubmission':
              return 'readyForSubmission';
            case 'submitted':
              return 'portfolioSubmitted';
            case 'readyForSigning':
              return 'readyForSigning';
            case 'signed':
              // @ts-expect-error
              switch (this.integrationStatus) {
                case 'none':
                case null:
                case undefined:
                  return 'signed';
                case 'sent':
                  return 'applicationSubmitted';
                case 'active':
                  return 'completed';
                case 'inactive':
                  return 'applicationSubmitted';
                case 'pendingReview':
                  return 'pendingReview';
                case 'retrievedFromBank':
                  return 'retrievedFromBank';
                case 'error':
                  return 'error';
                case 'rejected':
                  return 'rejected';
              }
              return 'signed';
            case 'signedNoMetaData':
              return 'signedNoMetaData'
            case 'metaDataNoSignature':
              return 'metaDataNoSignature'
            case 'recallRequested':
              return 'recallRequested';
            case 'cancelled':
              return 'readyForSubmission';
            case 'deleted':
              return 'readyForSubmission';
            case 'notRequired':
              return 'inProgress';
          }
        } else {
          // @ts-expect-error
          switch (this.integrationStatus) {
            case 'none':
            case null:
            case undefined:
              return 'readyForSubmission';
            case 'sent':
              return 'applicationSubmitted';
            case 'active':
              return 'completed';
            case 'inactive':
              return 'applicationSubmitted';
            case 'pendingReview':
              return 'pendingReview';
            case 'retrievedFromBank':
              return 'retrievedFromBank';
            case 'error':
              return 'error';
            case 'rejected':
              return 'rejected';
          }
        }
        return 'inProgress';
      case 'error':
        return 'error'; //
      case 'completed':
        return 'completed'; //
      case 'partiallyCompleted':
        return 'partiallyCompleted'; //
      case 'cancelled':
        return 'cancelled'; //
      case 'rejected':
        return 'rejected'; //
    }
    // @ts-expect-error
    return this.status;
  }
  get consolidatedRejectedReason() {
    // @ts-expect-error
    if (['new','readyForSubmission','cancelled'].includes(this.applicationPortfolioStatus) && this.rejectedReason) {
      // @ts-expect-error
      return this.rejectedReason;
    }
    // @ts-expect-error
    const mainCifApplication = this.application_cif?.[this.fullCifId];
    if (
      mainCifApplication &&
      ['declined', 'error'].includes(mainCifApplication.craStatus) &&
      mainCifApplication.craDeclinedReason
    ) {
      return mainCifApplication.craDeclinedReason;
    }
    if (
      mainCifApplication &&
      ['error', 'cancelled', 'rejected'].includes(mainCifApplication.status) &&
      mainCifApplication.rejectedReason
    ) {
      return mainCifApplication.rejectedReason;
    }
    // @ts-expect-error
    for (const accountApplicationId in this.application_account || {}) {
      // @ts-expect-error
      const accountApp = this.application_account[accountApplicationId];
      if (accountApp && ['error', 'cancelled', 'rejected'].includes(accountApp.status) && accountApp.rejectedReason) {
        return accountApp.rejectedReason;
      }
    }
    // @ts-expect-error
    for (const cifApplicationId in this.application_cif || {}) {
      // @ts-expect-error
      const cifApp = this.application_cif[cifApplicationId];
      if (
        cifApp.id !== this.fullCifId &&
        cifApp &&
        ['error', 'cancelled', 'rejected'].includes(cifApp.status) &&
        cifApp.rejectedReason
      ) {
        return cifApp.rejectedReason;
      }
    }
    // @ts-expect-error
    return this.rejectedReason;
  }

  get displayRequestedAtAsTag() {
    // @ts-expect-error
    if (this.store.state.account[this.store.state.authUser.accountId].accountLevel !== 'bank') {
      return false;
    }
    // @ts-expect-error
    if (!['inProgress', 'partiallyCompleted'].includes(this.status)) {
      return false;
    }
    return this.applicationOverdue;
  }
  get requestedAtTagType() {
    return 'warning';
  }
  get applicationOverdue() {
    const slaTime = 2 * 60 * 60 * 1000; // 2 hours
    // @ts-expect-error
    return this.store.state.systemNowAsDate.valueOf() > new Date(this.requestedAt).valueOf() + slaTime ? true : false;
  }

  // ----- Full CIF -----

  get fullCifId() {
    let res = {};
    // @ts-expect-error
    for (let a in this.application_cif) {
      // @ts-expect-error
      let app = this.application_cif[a];
      if (app.cifType === 'full') {
        // @ts-expect-error
        res[a] = app.id;
      }
    }
    let key = Object.keys(res)[0];
    return key ? +key : undefined;
  }

  // ----- Associated CIFs -----

  get associatedCifIds() {
    let res = {};
    // @ts-expect-error
    for (let a in this.application_cif) {
      // @ts-expect-error
      let app = this.application_cif[a];
      if (app.cifType === 'associated') {
        // @ts-expect-error
        res[a] = app.id;
      }
    }
    return res;
  }
  get associatedCifs() {
    let res = {};
    // @ts-expect-error
    for (let a in this.application_cif) {
      // @ts-expect-error
      let app = this.application_cif[a];
      if (app.cifType === 'associated') {
        // @ts-expect-error
        res[a] = app;
      }
    }
    return res;
  }
  get hasAssociatedCifs() {
    return Object.keys(this.associatedCifIds).length > 0;
  }

  get activeAssociatedCifs() {
    let res = {};
    for (let c in this.associatedCifs) {
      // @ts-expect-error
      let cif = this.associatedCifs[c];
      if (cif.status !== 'cancelled') {
        // @ts-expect-error
        res[c] = cif;
      }
    }
    return res;
  }
  get activeAssociatedCifIds() {
    return Object.keys(this.activeAssociatedCifs);
  }
  get numberOfActiveAssociatedCifs() {
    return Object.keys(this.activeAssociatedCifs).length;
  }
  get hasActiveAssociatedCifs() {
    return this.numberOfActiveAssociatedCifs > 0;
  }
  get firstActiveAssociatedCifId() {
    return +Object.keys(this.activeAssociatedCifs)[0];
  }
  get lastActiveAssociatedCifId() {
    // @ts-expect-error
    return +Object.keys(this.activeAssociatedCifs).at(-1);
  }

  // ----- Bank Accounts -----

  get bankAccountApplicationIds() {
    let res = {};
    // @ts-expect-error
    for (let a in this.application_account) {
      // @ts-expect-error
      let app = this.application_account[a];
      // @ts-expect-error
      res[a] = app.id;
    }
    return res;
  }

  // ----- Form stuff ? -----

  get isDataComplete() {
    //@ts-expect-error
    return this.application_account !== undefined && this.application_cif !== undefined;
  }

  get is_submittable() {
    const documentUpload = this.thisApplicationDocumentUploadType
    if (documentUpload === 'signiFlow') {
      //@ts-expect-error
      if (!Array.isArray(this.documents)) { // || this.documents.length === 0 //There are valid scenarios with no additional required documents
        return false;
      }
      //@ts-expect-error
      if (!Array.isArray(this.signatoryOrder) || this.signatoryOrder.length === 0) {
        return false;
      }
    }

    //@ts-expect-error
    if (this.application_account) {
      //@ts-expect-error
      for (const application of Object.keys(this.application_account)) {
        //@ts-expect-error
        if (!this.application_account[application].is_submittable) {
          return false;
        }
      }
    } else {
      return false;
    }
    //@ts-expect-error
    if (this.application_cif) {
      // TODO check if entity appl. has at least one activeCIF
      let cifIdsToCheck = [this.fullCifId, ...this.activeAssociatedCifIds];
      // for (const application of Object.keys(this.application_cif)) {
      for (const application of cifIdsToCheck) {
        //@ts-expect-error
        if (!this.application_cif[application].is_submittable) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  }
  get applicationIsEditable() {
    return (
      // @ts-expect-error
      ['notStarted','reOpened','error',null,undefined].includes(this.craStatus!) &&
      //@ts-expect-error
      (['new', 'readyForSubmission'].includes(this.applicationPortfolioStatus!) ||
        //@ts-expect-error
        (this.applicationPortfolioStatus! === 'notRequired' &&
          // @ts-expect-error
          ['none', 'error', 'rejected',null,undefined].includes(this.integrationStatus))) &&
      //@ts-expect-error
      !['cancelled', 'completed'].includes(this.status)
    );
  }
  get applicationIsCraSubmitable() {
    return (
      this.craEnabled &&
      //@ts-expect-error
      ['notStarted','error','reOpened',null,undefined].includes(this.craStatus) && 
      //@ts-expect-error
      ['readyForSubmission', 'notRequired','new'].includes(this.applicationPortfolioStatus!) &&
      //@ts-expect-error
      (['none', 'error', 'rejected', null, undefined].includes(this.integrationStatus) ||
        //@ts-expect-error
        (this.integrationStatus === 'active' && this.status === 'partiallyCompleted')) &&
      //@ts-expect-error
      !['cancelled', 'completed'].includes(this.status)
    );
  }
  get applicationIsSubmitable() {
    const documentUpload = this.thisApplicationDocumentUploadType
    var allowedStatuses: string[] = [];
    switch (documentUpload) {
      case 'signiFlow':
        allowedStatuses = ['signed'];
        break;
      case 'local':
        allowedStatuses = ['readyForSubmission', 'notRequired'];
        break;
    }
    return (
      //@ts-expect-error
      (!this.craEnabled || this.craStatus === 'approved' && !this.craApprovalHasExpired) &&
      //@ts-expect-error
      allowedStatuses.includes(this.applicationPortfolioStatus!) &&
      //@ts-expect-error
      (['none', 'error', 'rejected', null, undefined].includes(this.integrationStatus) ||
        //@ts-expect-error
        (this.integrationStatus === 'active' && this.status === 'partiallyCompleted') || 
        //@ts-expect-error
        (this.integrationStatus === 'pendingReview' && this.status === 'inProgress')
        ) &&
      //@ts-expect-error
      !['cancelled', 'completed'].includes(this.status)
    );
  }
  // @ts-expect-error
  async addAssociatedCif(data) {
    let record = data;
    // @ts-expect-error
    record.collection_path = `/client/${this.accountId}/application`;

    let options = {
      // @ts-expect-error
      parentId: this.id,
      action: 'assCif',
      // @ts-expect-error
      return_collection_path: `/account/${this.accountId}/application/${this.id}/application_cif`,
    };

    // @ts-expect-error
    this.store.dispatch('showLoader', true);
    // @ts-expect-error
    let response = await this.store.dispatch('db_create_document', { record, options });
    // @ts-expect-error
    this.store.dispatch('showLoader', false);
    return response;
  }
  get craEnabled() {
    //@ts-expect-error
    return !!this.store.state.systemSettings?.featureEnable?.craOnboarding
  }
  get thisApplicationDocumentUploadType() {
    // @ts-expect-error
    if (this.applicationPortfolioStatus === 'notRequired') {
      return 'local'
    }
    // @ts-expect-error
    return this.store.state.account[this.accountId]?.applicationDocumentUploadType || 'local'
  }

  get localDocuments() {
    // @ts-expect-error
    return this.applicationDocument || {};
  }
  get availableDocumentTypes() {
    // @ts-expect-error
    const client = this.store.state.account[this.accountId];
    // @ts-expect-error
    const applicationCif = this.application_cif?.[this.fullCifId];
    // @ts-expect-error
    const includeKyc = !(this.store.state.dbIntermediarySettings?.mayCompleteOwnKyc || this.store.state.account[this.store.state.authUser.accountId].mayCompleteOwnKyc);
    // @ts-expect-error
    const includeEdd = this.craStatus === 'eddRequested';
    const documentUploadType = this.thisApplicationDocumentUploadType;

    const allowOnboardingWithIncompleteData =
      //@ts-expect-error
      this.store.state.dbIntermediarySettings?.allowOnboardingWithIncompleteData ||
      //@ts-expect-error
      !!this.store.state.account[this.store.state.authUser.accountId].allowOnboardingWithIncompleteData;
    const applicationDocumentTypes = [];
    // @ts-expect-error
    for (const documentTypeId in this.store.getters.config.applicationDocumentType) {
      // @ts-expect-error
      applicationDocumentTypes.push(this.store.getters.config.applicationDocumentType[documentTypeId]);
    }

    return filterDocumentTypes(
      client,
      this as LfxApplicationInt,
      applicationCif?.cifInfo,
      includeKyc,
      includeEdd,
      documentUploadType,
      allowOnboardingWithIncompleteData,
      applicationDocumentTypes,
    );
  }
  get outstandingDocumentTypes() {
    const outstandingDocumentTypes = [];
    for (const documentType of this.availableDocumentTypes) {
      //@ts-expect-error
      if (documentType.isMandatoryForDueDilligence(this.application_cif?.[this.fullCifId]?.cifInfo?.clientDueDilligence)) {
        let documentUploaded = false;
        for (const uploadedDocument of this.uploadedDocuments) {
          if (uploadedDocument.documentTypeId === documentType.id) {
            documentUploaded = true;
            break;
          }
        }
        if (!documentUploaded) {
          outstandingDocumentTypes.push(documentType);
        }
      }
    }
    return outstandingDocumentTypes;
  }
  get outstandingDocumentTypeText() {
    const textArray = [];
    for (const documentType of this.outstandingDocumentTypes) {
      textArray.push(documentType.documentName);
    }
    return textArray.join(', ');
  }
  get uploadedDocuments() {
    const uploadedDocuments = [];
    for (const documentId in this.localDocuments) {
      if (this.localDocuments[documentId].status === 'uploaded') {
        uploadedDocuments.push(this.localDocuments[documentId]);
      }
    }
    return uploadedDocuments;
  }

  async on_after_activate() {
    if (!this.isDataComplete) {
      const response = await doPermissionAction(
        this,
        'getApplication',
        {
          // @ts-expect-error
          accountId: this.accountId,
          // @ts-expect-error
          applicationId: this.id,
        },
        {}
      );
    } else {
    }
  }

  // ------------------------------
  // Permissions and guards
  // ------------------------------

  // ---- General -----

  get permissionPlaceholder() {
    return true;
  }

  // ---- Guards ----

  get editApplication_buttonGuard() {
    return (
      // @ts-expect-error
      ['new', 'rejected', 'error'].includes(this.status) &&
      // @ts-expect-error
      ['notStarted','reOpened',null,undefined,'error'].includes(this.craStatus!) &&
      // @ts-expect-error
      ['new', 'readyForSubmission', 'error', 'notRequired'].includes(this.applicationPortfolioStatus) &&
      !!this.hasPermission.updateApplicationCifIndividual &&
      !!this.hasPermission.updateApplicationCifEntity &&
      !!this.hasPermission.updateApplicationAccount
    );
  }
  get submitApplicationPortfolio_buttonGuard() {
    // console.log({
    //   "1-craEnabled":this.craEnabled,// @ts-expect-error
    //   "2-craStatus":this.craStatus,
    //   "3-craApprovalHasExpired":this.craApprovalHasExpired,// @ts-expect-error
    //   "4-status":this.status,// @ts-expect-error
    //   "5-integrationStatus":this.integrationStatus,// @ts-expect-error
    //   "6-applicationPortfolioStatus":this.applicationPortfolioStatus,
    //   "7-hasPermission":this.hasPermission.submitApplicationPortfolio
    // })
    return (
      // @ts-expect-error
      (!this.craEnabled || this.craStatus === 'approved' && !this.craApprovalHasExpired) &&
      // @ts-expect-error
      (['new', 'rejected', 'error'].includes(this.status) || (this.status === 'inProgress' && ['none',null,undefined].includes(this.integrationStatus))) &&
      // @ts-expect-error
      ['new','readyForSubmission', 'error', 'notRequired'].includes(this.applicationPortfolioStatus) &&
      !!this.hasPermission.submitApplicationPortfolio
    );
  }

  get recallClientPortfolio_buttonGuard() {
    //@ts-expect-error
    const applicationPortfolioStatus = this.applicationPortfolioStatus
    //@ts-expect-error
    const integrationStatus = this.integrationStatus
    return (
        applicationPortfolioStatus === 'readyForSigning' ||
        applicationPortfolioStatus === "signed" && integrationStatus === "rejected"
      ) 
      && !!this.hasPermission.cancelClientPortfolio;
  }
  get clientPortfolioSummary_buttonGuard() {
    return (
      //@ts-expect-error
      ['readyForSigning', 'signed'].includes(this.applicationPortfolioStatus) && !!this.hasPermission.cancelClientPortfolio
    );
  }
  get viewClientPortfolio_buttonGuard() {
    return (
      //@ts-expect-error
      ['readyForSigning', 'signed'].includes(this.applicationPortfolioStatus) &&
      !!this.hasPermission.getClientPortfolioLink
    );
  }
  get updateClientPortfolioStatus_buttonGuard() {
    return (
      //@ts-expect-error
      ['readyForSigning', 'signed'].includes(this.applicationPortfolioStatus) && !!this.hasPermission.rejectClientPortfolio
    );
  }
  get addDocumentPayment_buttonGuard() {
    return (
      //@ts-expect-error
      ['readyForSigning'].includes(this.applicationPortfolioStatus) && !!this.hasPermission.addClientPortfolioDocument
    );
  }

  get canHideApplication() { 
    //@ts-expect-error
    return (!['completed','inProgress'].includes(this.status) && //@ts-expect-error
            this.visibility !== 'hidden' && //@ts-expect-error
            !['submitted','readyForSigning','recallRequested'].includes(this.applicationPortfolioStatus!))
      ;
  }

}

// @ts-expect-error
export function Definition(context, name: string) {
  return new LfxApplicationMdl(LfxApplicationDef, LfxApplication, context, name, {
    fields: {
      rejectedReason: { views: { rejectOrCancelApplication: true } },
    },
  });
}
