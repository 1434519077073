<template>
  <LDBModal result="info" :header="header">
    <template #body>
      <div v-if="mounted">
        <LDBField
          v-for="(field, fieldname) in viewFields"
          :key="fieldname"
          :field="field"
          view="edit"
          :fieldname="field.name"
          :definition="definition"
          :record="newRecord"
          @change="on_change"
        />
      </div>
    </template>

    <template #footer>
      <LDBButton
        v-for="button in formButtons"
        :key="button.name"
        :label="button.label"
        :action="button.action"
        :actionParams="button.actionParams"
        :params="button.params"
        :type="button.type"
        :tooltip="button.tooltip"
        :modal="button.modal"
        :viewGuards="button.guards"
        :enabledGuards="button.enabledGuards"
        :onSuccess="button.onSuccess"
        @click="button.clickEvent"
        @on-api-success="onApiSuccess"
      >
        <template v-if="button.modal !== undefined" #modal>
          <div v-html="button.modal.content"></div>
        </template>
      </LDBButton>
    </template>
  </LDBModal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getDefinition } from '@/db-interface';

import LDBField from '@/landobyte_vue/LDBField.vue';

export default {
  name: 'LfxImportBankAccount',
  components: { LDBField },
  props: {},
  data() {
    return {
      mounted: false,
      newRecord: {},
    };
  },
  created() {
    this.newRecord = new this.definition.class(
      {
        Table: 'LfxBankAccount',
        ...this.definition.default_values,
        collection_path: `/account/${this.accountId}/bank_account`,
      },
      this.$store
    );
    this.newRecord.isBusinessBankAccount = true;
    console.log('newRecord in created', this.newRecord);
  },
  mounted() {
    this.populateFieldDefaults();
  },
  computed: {
    ...mapGetters(['permissions', 'config']),
    ...mapState(['account','systemSettings']),
    header() {
      return 'Import an Existing Bank Account';
    },

    // ----- Buttons -----

    formButtons() {
      let self = this;
      let importAction;
      if (this.systemSettings?.featureEnable?.mandatesDatabase === true) {
        importAction = 'bankImportBankAccount'
      } else {
        importAction = 'importBankAccount'
      }

      let allButtons = [
        {
          name: 'cancelImportBankAccount',
          label: 'Cancel',
          type: '',
          guards: true,
          clickEvent() {
            self.$router.go(-1);
          },
        },
        {
          name: 'importBankAccount',
          label: 'Import Bank Account',
          type: 'primary',
          action: importAction,
          params: { accountId: this.accountId },
          actionParams: this.newRecord,
          guards: true,
          // clickEvent: 'okay', // this crashes
          clickEvent(response) {
            if (response.status === 'success') {
              self.$router.go(-1);
              // TODO make this go to the new bank account instead
            }
          },
        },
      ];
      return allButtons?.filter((button) => button.guards);
    },

    cancelRoute() {
      return this.$route.meta?.modal?.cancelRoute ? this.$route.meta.modal.cancelRoute : 'todo'; //TODO: go to user's home as fallback
    },

    // ----- Definition -----

    definition() {
      return getDefinition('bankAccount');
    },

    // ----- Record -----

    accountId() {
      return +this.$route.params.accountId;
    },
    accountRecord() {
      console.log('accountId', this.accountId);
      return this.account[this.accountId];
    },

    // ----- Fields -----

    fields() {
      return this.definition.fields;
    },

    view() {
      return 'importBankAccount';
    },
    viewFields() {
      let res = [];
      for (let k in this.fields) {
        let field = this.fields[k];
        if (field.views[this.view] === true) {
          field.fieldname = k;
          res.push(field);
        }
      }
      return res;
    },
  },
  methods: {
    ...mapActions(['showLoader']),
    ...mapMutations([]),

    // ----- Fields -----

    populateFieldDefaults() {
      for (let f = 0; f < this.viewFields.length; f++) {
        let field = this.viewFields[f];
        if (field.default) {
          this.newRecord[field.name] = field.default;
        }
      }
      this.mounted = true;
    },
    on_change({ field, value }) {
      this.newRecord[field] = value;
      const newRec = this.newRecord;
    },

    // ----- API calls -----
  },
};
</script>
