//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxAccountDef.ts
//  Author      : Bevan Timm
//  Description : This module defines an Account record for the LandoFX system
//  Created     : 15 July 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition, LdbDbObject, LdbViewFunction } from '../definitions/LdbInterfaces';
import { extendedTextWhiteList, standardTextWhiteList } from '../sharedUtils/LdbValidations';
import { LfxAccountMarkupInt } from './LfxAccountMarkupDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxAccountAccountLevel = 'bank' | 'intermediary' | 'intermediaryBranch' | 'client';
export type LfxAccountAccountType = 'entity' | 'individual';
export type LfxAccountStatus = 'new' | 'submitted' | 'rejected' | 'closed' | 'active' | 'notWithThisIntermediary' | 'bankNotActive' | 'mandateDatabaseConflict';
export type LfxAccountCurrentApplicationStatus =
  | 'na'
  | 'awaitingDetailsCapture'
  | 'readyForPortfolioSubmission'
  | 'portfolioSubmitted'
  | 'portfolioInProgress'
  | 'portfolioSentBack'
  | 'readyForApplicationSubmission'
  | 'applicationSubmitted'
  | 'applicationInProgress'
  | 'applicationSentBack'
  | 'complete'
  | 'cancelled'
  | 'retrievedFromBank'
  | 'craInProgress'
  | 'craDeclined';
export type LfxAccountIdenticationType =
  | 'rsaRegistrationNumber'
  | 'internationalRegistrationNumber'
  | 'rsaId'
  | 'passport'
  | 'rsaNonCompanyRegistrationNumber';
export type LfxAccountVisibility = 'visible' | 'hidden';
export type LfxAccountSignatoryOption = 'client' | 'intermediary' | 'submittingUser' | 'intermediaryAndClient';
export type LfxAccountBancsStatus =
  | 'active'
  | 'blacklisted'
  | 'businessRescue'
  | 'closed'
  | 'debtReview'
  | 'deceased'
  | 'distressedRestructure'
  | 'edcPlacement1'
  | 'ficaNonCompliant'
  | 'fraud'
  | 'inactive'
  | 'judgment'
  | 'legal'
  | 'liquidation'
  | 'litigation'
  | 'litigationPaymentArrangement'
  | 'ncaS129'
  | 'ncrRestructured'
  | 'nonPerforming'
  | 'paymentArrangementDefault'
  | 'privateSale'
  | 'saleInExecution'
  | 'sequestrated'
  | 'underAdministration'
  | 'underPaymentArrangement'
  | 'unemployed'
  | 'whereaboutsUnknown'
  | 'writeOff';
  export type LfxAccountKycCompliance = 
  | 'compliant'
  | 'nonCompliant'

//----------------------------------------------------------------------------------------------------------------------

export interface LfxAccountInt {
  id?: number;
  uuid?: string;
  accountId?: number;
  intermediaryId?: number;
  intermediarySettingsId?: number;
  accountLevel?: LfxAccountAccountLevel;

  accountType?: LfxAccountAccountType;

  reference?: string;

  name?: string;
  bankName?: string;
  identificationType?: LfxAccountIdenticationType;
  identification?: string;
  cifNumber?: string;
  bpNumber?: string;

  contactName?: string;
  contactNumber?: string;
  emailAddress?: string;
  address?: string;
  suburb?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  externalReference?: string;

  status?: LfxAccountStatus;
  bancsStatus?: LfxAccountBancsStatus;
  kycCompliance?: LfxAccountKycCompliance;
  currentApplicationId?: number;
  currentApplicationStatus?: LfxAccountCurrentApplicationStatus;

  tradingEnabled?: boolean;
  lastTradeTimstamp?: Date;
  tradingDisabledReason?: string;

  bopFormSignatoryOption?: LfxAccountSignatoryOption;
  signatoryOrder?: number[];

  openDealLimit?: number;
  mayBookFec?: boolean;
  fecDocumentsInOrder?: boolean;
  fecDisabledNote?: string;

  note?: string;

  visibility?: LfxAccountVisibility;

  restrictedView?: boolean;

  lastEditedAt?: Date;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;

  Markups?: LfxAccountMarkupInt[];
}

//----------------------------------------------------------------------------------------------------------------------

export const accountIsClient: LdbViewFunction = (viewFunctionObject) => {
  return (viewFunctionObject.record! as LfxAccountInt).accountLevel === 'client';
};
export const bankEditingIntermediaryOrBranch: LdbViewFunction = (viewFunctionObject) => {
  return viewFunctionObject.authUser.accountLevel === 'bank' && accountIsIntermediaryOrBranch(viewFunctionObject);
};
export const accountIsIntermediary: LdbViewFunction = (viewFunctionObject) => {
  return ['intermediary'].includes((viewFunctionObject.record! as LfxAccountInt).accountLevel!);
};
export const accountIsIntermediaryOrBranch: LdbViewFunction = (viewFunctionObject) => {
  return ['intermediary', 'intermediaryBranch'].includes((viewFunctionObject.record! as LfxAccountInt).accountLevel!);
};
export const accountIsNotBank: LdbViewFunction = (viewFunctionObject) => {
  return ['intermediary', 'intermediaryBranch', 'client'].includes(
    (viewFunctionObject.record! as LfxAccountInt).accountLevel!
  );
};
export const accountIsNotMyAccount: LdbViewFunction = (viewFunctionObject) => {
  //@ts-expect-error
  return viewFunctionObject.authUser.accountId !== viewFunctionObject.record?.id;
};
export const isBankUser: LdbViewFunction = (viewFunctionObject) => {
  return ['bank'].includes(viewFunctionObject.authUser.accountLevel);
};
export const intermediaryOrBranchUser: LdbViewFunction = (viewFunctionObject) => {
  return ['intermediary', 'intermediaryBranch'].includes(viewFunctionObject.authUser.accountLevel);
};
export const releaseClientUserEditingClient: LdbViewFunction = (viewFunctionObject) => {
  return (
    viewFunctionObject.authUser.accountLevel === 'bank' &&
    //@ts-expect-error
    viewFunctionObject.record!.status! === 'active' &&
    viewFunctionObject.authUser.permissions.includes('releaseClient')
  );
};
export const isBankUserViewingIntermediaryList: LdbViewFunction = (viewFunctionObject) => {
  return (
    ['bank'].includes(viewFunctionObject.authUser.accountLevel) &&
    viewFunctionObject?.routeMetaData?.content?.recordsAccountLevel === 'intermediary'
  );
};
export const interOrclientIsEnabledForFec: LdbViewFunction = (viewFunctionObject) => {
  const accountRecord = viewFunctionObject.record as LfxAccountInt;
  return (
    accountRecord.accountLevel === 'intermediary' ||
    (accountRecord.accountLevel === 'client' && !!accountRecord.mayBookFec)
  );
};

export const fecIsDisabled: LdbViewFunction = (viewFunctionObject) => {
  const accountRecord = viewFunctionObject.record as LfxAccountInt;
  return accountRecord.accountLevel === 'client' && !accountRecord.mayBookFec;
};
export const fecIsDisabledAndIAmABankUser: LdbViewFunction = (viewFunctionObject) => {
  return viewFunctionObject.authUser.accountLevel === 'bank' && fecIsDisabled(viewFunctionObject);
};
//----------------------------------------------------------------------------------------------------------------------

export const LfxAccountDef: LdbDefinition = {
  apiUrl() {
    return `/account`;
  },
  title: 'Account',
  table: 'LfxAccount',
  collectionPath: '/account',
  view: { create: false, edit: true, item: true, list: true, delete: false, csv: false },
  actions: {
    list: 'getAccounts',
    item: 'getAccount',
  },
  indexes: [{ unique: 'uuid' }],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'General', color: 'success' },
    details: { priority: 2, label: 'Details', color: 'info' },
    settings: { priority: 3, label: 'Settings', color: 'info' },
    reports: {priority: 4, label: 'Reports', color: 'info' },
    directSettings: { priority: 5, label: 'Direct Client Settings - Bank Only', color: 'info' },
    changes: { priority: 6, label: 'Changes', color: 'disabled' },
  },
  fields: {
    // details:{
    //   datatype:'group',
    //   label:'Details',
    //   property:{read:true,write:false},
    //   views: { create: false, edit: false, item: 1, list: true, delete: false, csv: false },
    //   order: {default:undefined,list:10},
    // },
    id: {
      label: 'Name',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      order: { default: undefined, list: 10 },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      search: true,

      unique: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Owner',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Parent',
          guards: {
            id(record: LdbDbObject) {
              //@ts-expect-error
              return { notEq: record.accountId };
            },
          },
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: true,
        delete: false,
        csv: true,
        customViews: { moveAccount: true },
      },
      order: { default: undefined, list: 20 },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',

          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
          sequelizeBelongsTo: 'Intermediary',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: intermediaryFieldViewGuard,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: true,
      },
      group: 'system',
    },
    intermediarySettingsId: {
      label: 'Intermediary Settings',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediary_settings',
          linkTable: 'LfxIntermediarySettings',
          linkField: 'id',
          displayField: 'organisation',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },

    accountLevel: {
      label: 'Account Level',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'bank', name: 'Bank' },
            { id: 'intermediary', name: 'Intermediary' },
            { id: 'intermediaryBranch', name: 'Intermediary Branch' },
            { id: 'client', name: 'Client' },
          ],
        },
      },
      default: 'client',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },
    accountType: {
      label: 'Account Type',
      search: true,
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'entity', name: 'Entity' },
            { id: 'individual', name: 'Individual' },
          ],
        },
      },
      default: 'entity',
      mandatory: true,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'system',
    },
    reference: {
      label: 'Reference',
      search: true,
      datatype: 'text',
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true, search: true },
      group: 'system',
    },
    name: {
      label: 'Name',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'Name should be between 2 and 200 characters', args: [2, 200] },
      },
      guards: { accountType: { in: ['entity', 'individual'] } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true, search: true },
      group: 'system',
    },
    bankName: {
      // This should not be editable. It will be retrieved from the bank
      label: 'Bank Name',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    identificationType: {
      label: 'Identification Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            {
              id: 'rsaRegistrationNumber',
              name: 'RSA Company Registration Number',
              guards: { accountType: { eq: 'entity' } },
            },
            {
              id: 'internationalRegistrationNumber',
              name: 'International Company Registration Number',
              guards: { accountType: { eq: 'entity' } },
            },
            { id: 'rsaId', name: 'RSA ID Number', guards: { accountType: { eq: 'individual' } } },
            { id: 'passport', name: 'Passport', guards: { accountType: { eq: 'individual' } } },
            {
              id: 'rsaNonCompanyRegistrationNumber',
              name: 'Other RSA Registration Number',
              guards: { accountType: { eq: 'entity' } },
            },
          ],
        },
      },
      default: null,
      mandatory: true,
      guards: { accountType: { in: ['entity', 'individual'] } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: true },
      group: 'details',
    },

    identification: {
      label: 'Identification',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      validators: {
        // isValidRSAID: {
        //   msg: 'Must be a valid RSA ID number.',
        //   args: function (value: string, record: LfxAccountInt) {
        //     if (record.identificationType === undefined) {
        //       return true;
        //     } else {
        //       return isValidId(record.identificationType, value);
        //     }
        //   },
        // }, // FIXME
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Identification should be between 3 and 30 characters', args: [3, 30] },
      },
      guards: { accountType: { in: ['entity', 'individual'] } },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: true, search: true },
      group: 'details',
    },
    cifNumber: {
      label: 'CIF Number',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        isNumeric: { msg: 'Please use only numeric charaters' },
        len: { msg: 'CIF Number should be 8 characters', args: [8, 8] },
      },
      views: {
        create: true,
        edit: releaseClientUserEditingClient,
        item: true,
        list: true,
        delete: false,
        csv: true,
        search: true,
      },
      group: 'details',
    },

    bpNumber: {
      label: 'BP Number',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: {
        create: true,
        edit: releaseClientUserEditingClient,
        item: true,
        list: true,
        delete: false,
        csv: true,
        search: true,
      },
      group: 'details',
    },

    commissionAccount: {
      label: 'Commission Account',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: true, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },

    contactName: {
      // This should not be editable. It should be updated from individual signatury or main contact
      label: 'Contact Name',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'details',
    },
    contactNumber: {
      // This should not be editable. It should be updated from individual signatury or main contact
      label: 'Contact Number',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'details',
    },
    emailAddress: {
      // This should not be editable. It should be updated from individual signatury or main contact
      label: 'Email Address',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'details',
    },
    address: {
      // This should not be editable. It will be retrieved from the bank
      label: 'Address',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Address should be between 5 and 40 characters', args: [5, 40] },
      },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'contact',
    },
    suburb: {
      // This should not be editable. It will be retrieved from the bank
      label: 'Suburb',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Suburb should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'contact',
    },
    city: {
      // This should not be editable. It will be retrieved from the bank
      label: 'City',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'City should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'contact',
    },
    province: {
      // This should not be editable. It will be retrieved from the bank
      label: 'Province',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Province should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'contact',
    },
    postalCode: {
      // This should not be editable. It will be retrieved from the bank
      label: 'Postal Code',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Postal Code should be between 2 and 9 characters', args: [2, 9] },
      },
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'contact',
    },
    country: {
      // This should not be editable. It will be retrieved from the bank
      label: 'Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'contact',
    },

    organisation: {
      label: 'Organisation',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      default: null,
      mandatory: false,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: isBankUser,
        delete: false,
        csv: false,
      },
      group: 'settings',
    },
    mayCompleteOwnKyc: {
      label: 'May Complete Own KYC',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: bankEditingIntermediaryOrBranch, item: true, list: false, delete: false, csv: false },
      group: 'settings',
    },
    mayProcessBulkFees: {
      label: 'May Process Bulk Fees',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: bankEditingIntermediaryOrBranch, item: true, list: false, delete: false, csv: false },
      group: 'settings',
    },
    allowClientsToSubmitPortfolios: {
      label: 'Allow Clients to Submit Portfilios',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'directSettings',
    },
    bankDirectClients: {
      label: 'Bank Own Clients',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'directSettings',
    },
    allowOnboardingWithIncompleteData: {
      label: 'Allow Onboarding with Incomplete Data',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'directSettings',
    },
    enforceSimplifiedTrading: {
      label: 'Enforce Simplified Trading',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None', tagType: 'info' },
            { id: 'fixesMarkup', name: 'Fixed Markup', tagType: 'warning' },
            { id: 'fixedRate', name: 'Fixed Rate', tagType: 'warning' },
          ],
        },
      },
      default: 'none',
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: true,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: false,
        delete: false,
        search: false,
        csv: false,
      },
      group: 'directSettings',
    },
    clientReportEnable:{
      label: 'Enable Client Report',
      datatype: 'boolean',
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: isBankUserViewingIntermediaryList,
        delete: false,
        search: false,
        csv: false,
      },
      group: 'reports',
    },
    clientReportS3Key: {
      label: 'Client Report',
      datatype: 'text',
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'reports',
    },
    clientReportTimestamp: {
      label: 'Latest Client Report',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'reports',
    },
    accountBalanceReportEnable:{
      label: 'Enable Account Balance Report',
      datatype: 'boolean',
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: isBankUserViewingIntermediaryList,
        delete: false,
        search: false,
        csv: false,
      },
      group: 'reports',
    },
    accountBalanceReportS3Key: {
      label: 'Account Balance Report',
      datatype: 'text',
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'reports',
    },
    accountBalanceReportTimestamp: {
      label: 'Latest Account Balance Report',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'reports',
    },
    dailyDealReportEnable:{
      label: 'Enable Daily Deal Report',
      datatype: 'boolean',
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: isBankUserViewingIntermediaryList,
        delete: false,
        search: false,
        csv: false,
      },
      group: 'reports',
    },
    dailyDealReportS3Key: {
      label: 'Daily Deal Report',
      datatype: 'text',
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'reports',
    },
    dailyDealReportTimestamp: {
      label: 'Latest Daily Deal Report',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'reports',
    },
    lastUserReviewDate: {
      label: 'Last User Review Timestamp',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },
    lastUserReviewBy: {
      label: 'Last User Review By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    externalReference: {
      label: 'External Reference',
      datatype: 'text',
      search: true,
      default: null,
      allowNullValues: true,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alpha charaters', args: [standardTextWhiteList] },
        len: { msg: 'External Reference should be between 0 and 100 characters', args: [0, 100] },
      },
      views: {
        create: true,
        edit: intermediaryOrBranchUser,
        item: true,
        list: intermediaryOrBranchUser,
        delete: false,
        csv: true,
      },
      group: 'system',
    },
    status: {
      label: 'Status',
      search: true,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'submitted', name: 'Submitted', tagType: 'warning' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'closed', name: 'Closed', tagType: 'cancelled' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'notWithThisIntermediary', name: 'Not With This Intermediary', tagType: 'cancelled' },
            { id: 'bankNotActive', name: 'Not Active at Bank', tagType: 'warning'},
            { id: 'mandateDatabaseConflict', name: 'Mandate Database Conflict', tagType: 'error'}
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },
    bancsStatus: {
      label: 'Bancs CIF Status',
      search: false,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'blacklisted', name: 'Blacklisted', tagType: 'warning' },
            { id: 'businessRescue', name: 'Business Rescue', tagType: 'warning' },
            { id: 'closed', name: 'Closed', tagType: 'cancelled' },
            { id: 'debtReview', name: 'Debt Review', tagType: 'warning' },
            { id: 'deceased', name: 'Deceased', tagType: 'warning' },
            { id: 'distressedRestructure', name: 'Distressed restructure', tagType: 'warning' },
            { id: 'edcPlacement1', name: 'EDC Placement 1', tagType: 'warning' },
            { id: 'ficaNonCompliant', name: 'FICA Non Compliant', tagType: 'warning' },
            { id: 'fraud', name: 'Fraud', tagType: 'warning' },
            { id: 'inactive', name: 'Inactive', tagType: 'warning' },
            { id: 'judgment', name: 'Judgment', tagType: 'warning' },
            { id: 'legal', name: 'Legal', tagType: 'warning' },
            { id: 'liquidation', name: 'Liquidation', tagType: 'warning' },
            { id: 'litigation', name: 'Litigation', tagType: 'warning' },
            { id: 'litigationPaymentArrangement', name: 'Litigation payment arrangement', tagType: 'warning' },
            { id: 'ncaS129', name: 'NCA S129', tagType: 'warning' },
            { id: 'ncrRestructured', name: 'NCR: Restructured', tagType: 'warning' },
            { id: 'nonPerforming', name: 'Non Performing', tagType: 'warning' },
            { id: 'paymentArrangementDefault', name: 'Payment arrangement default', tagType: 'warning' },
            { id: 'privateSale', name: 'Private Sale', tagType: 'warning' },
            { id: 'saleInExecution', name: 'Sale in execution', tagType: 'warning' },
            { id: 'sequestrated', name: 'Sequestrated', tagType: 'warning' },
            { id: 'underAdministration', name: 'Under Administration', tagType: 'warning' },
            { id: 'underPaymentArrangement', name: 'Under Payment arrangement', tagType: 'warning' },
            { id: 'unemployed', name: 'Unemployed', tagType: 'warning' },
            { id: 'whereaboutsUnknown', name: 'Whereabouts Unknown', tagType: 'warning' },
            { id: 'writeOff', name: 'Write Off', tagType: 'warning' },          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },
    kycCompliance: {
      label: 'KYC Compliance',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'compliant', name: 'Compliant', tagType: 'success' },
            { id: 'nonCompliant', name: 'Non-Compliant', tagType: 'warning' },
          ]}
        },
      default: false,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },

    consolidatedStatus: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'submitted', name: 'Submitted', tagType: 'warning' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'closed', name: 'Closed', tagType: 'cancelled' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'notWithThisIntermediary', name: 'Not With This Intermediary', tagType: 'cancelled' },
            { id: 'bankNotActive', name: 'Not Active at Bank', tagType: 'warning'},
            { id: 'kcyNonCompliant', name: 'KYC Non-Compliant', tagType: 'warning'},
            { id: 'mandateDatabaseConflict', name: 'Mandate Database Conflict', tagType: 'error'},

            // Application Statuses
            { id: 'na', name: 'Application not Started' },
            { id: 'awaitingDetailsCapture', name: 'Awaiting Details Capture', tagType: 'info' },
            { id: 'readyForPortfolioSubmission', name: 'Ready for Portfolio Submission', tagType: 'info' },
            { id: 'portfolioSubmitted', name: 'Portfolio Submitted', tagType: 'info' },
            { id: 'portfolioInProgress', name: 'Portfolio in Progress', tagType: 'info' },
            { id: 'portfolioSentBack', name: 'Portfolio Sent Back', tagType: 'warning' },
            { id: 'readyForApplicationSubmission', name: 'Ready for Application Submission', tagType: 'info' },
            { id: 'applicationSubmitted', name: 'Application Submitted', tagType: 'info' },
            { id: 'applicationInProgress', name: 'Application in Progress', tagType: 'info' },
            { id: 'applicationSentBack', name: 'Application Sent Back', tagType: 'warning' },
            { id: 'complete', name: 'Complete', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: 'cancelled' },
            { id: 'retrievedFromBank', name: 'Retrieved From Bank', tagType: 'info' },
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'status' },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    migrationStatus: {
      label: 'Migration Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'notMigrated', name: 'Not Migrated', tagType: 'info' },
            { id: 'migrated', name: 'Migrated', tagType: 'success' },
            { id: 'createdLocally', name: 'Created Locally', tagType: 'success' },
          ],
        },
      },
      default: 'createdLocally',
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: true,
      allowNullValues: true,
      views: {
        create: true,
        edit: bankEditingIntermediaryOrBranch,
        item: isBankUser,
        list: isBankUserViewingIntermediaryList,
        delete: false,
        search: false,
        csv: false,
      },
      group: 'system',
    },
    userReviewStatus:{
      label:'User Review Status',
      datatype:{option:{optionType:'text',
          options:[
            {id:'compliant',name:"Compliant", tagType: 'success'},
            {id:'nonCompliant',name:"Non Compliant", tagType: 'error'},
            {id:'due',name:'Review Due', tagType: 'warning'},
          ]
        }
      },
      property: { source: 'frontend', read: true, write: true, sort: 'none' },
      default:'active',
      mandatory:true,
      views:{create:false,edit:false,item:accountIsIntermediary,list:isBankUserViewingIntermediaryList,delete:false,csv:false},
      group: 'system',
    },
    currentApplicationId: {
      label: 'Current Application',
      datatype: {
        foreignKey: {
          linkTable: 'LfxApplication',
          linkField: 'id',
          collection: 'fk_applications',
          displayField: 'reference',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    currentApplicationStatus: {
      label: 'Application Status',
      search: true,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'na', name: 'Not Applicable' },
            { id: 'awaitingDetailsCapture', name: 'Awaiting Details Capture', tagType: 'info' },
            { id: 'readyForPortfolioSubmission', name: 'Ready for Portfolio Submission', tagType: 'info' },
            { id: 'portfolioSubmitted', name: 'Portfolio Submitted', tagType: 'info' },
            { id: 'portfolioInProgress', name: 'Portfolio in Progress', tagType: 'info' },
            { id: 'portfolioSentBack', name: 'Portfolio Sent Back', tagType: 'warning' },
            { id: 'readyForApplicationSubmission', name: 'Ready for Application Submission', tagType: 'info' },
            { id: 'applicationSubmitted', name: 'Application Submitted', tagType: 'info' },
            { id: 'applicationInProgress', name: 'Application in Progress', tagType: 'info' },
            { id: 'applicationSentBack', name: 'Application Sent Back', tagType: 'warning' },
            { id: 'complete', name: 'Complete', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: 'cancelled' },
            { id: 'retrievedFromBank', name: 'Retrieved From Bank', tagType: 'info' },
            { id: 'craInProgress', name: 'CRA In Progress', tagType: 'info'},
            { id: 'craDeclined', name: 'CRA Declined', tagType: 'error'}
          ],
        },
      },
      default: 'na',
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'system',
    },

    tradingEnabled: {
      label: 'Trading Enabled',
      datatype: 'boolean',
      displayType: {
        displayAsTag: true,
        tagTypeFunctionName: 'tradingEnabledTagType',
      },

      default: false,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'settings',
    },
    lastTradeTimstamp: {
      label: 'Last Trade',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'settings',
    },
    tradingDisabledReason: {
      label: 'Trading Disabled Reason',
      datatype: 'memo',
      search: true,
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: extendedTextWhiteList },
        len: { msg: 'Note should be between 2 and 250 characters', args: [2, 250] },
      },
      views: {
        create: false,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: true,
      },
      group: 'settings',
    },
    bopFormSignatoryOption: {
      label: 'BOP Form Signatory Option',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'client', name: 'Client', tagType: 'info' },
            { id: 'intermediary', name: 'Intermediary', tagType: 'info' },
            { id: 'submittingUser', name: 'Submitting User', tagType: 'info' },
            { id: 'intermediaryAndClient', name: 'Intermediary And Client', tagType: 'info' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: accountIsNotBank, item: accountIsNotBank, list: false, delete: false, csv: true },
      group: 'settings',
    },
    signatoryOrder: {
      label: 'Signatory Order',
      datatype: {
        json: {},
      },
      default: [],
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'settings',
    },

    openDealLimit: {
      label: 'Open Deal Limit',
      datatype: { currency: { symbol: 'ZAR' } },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      help: 'Sets the total ZAR value of open deals that the client may have. Allows the intermediary to control the risk they take on behalf of the client.',
      views: { item: accountIsClient, csv: true },
      write: true,
      group: 'settings',
    },
    availableOpenDealLimit: {
      label: 'Available Open Deal Amount',
      datatype: { currency: { symbol: 'ZAR' } },
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      views: { item: accountIsClient },
      group: 'settings',
    },

    mayBookFec: {
      label: 'Client may book FEC Deals',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: {
        create: false,
        edit: releaseClientUserEditingClient,
        item: accountIsClient,
        list: false,
        delete: false,
        csv: true,
      },
      group: 'settings',
    },
    fecDocumentsInOrder: {
      label: 'FEC Documentation in Order',
      datatype: 'boolean',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: {
        create: false,
        edit: releaseClientUserEditingClient,
        item: accountIsClient,
        list: false,
        delete: false,
        csv: true,
      },
      group: 'settings',
    },
    fecDisabledNote: {
      label: 'FEC Disabled Note',
      datatype: 'memo',
      search: true,
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: extendedTextWhiteList },
        len: { msg: 'Note should be between 2 and 250 characters', args: [2, 250] },
      },
      views: {
        create: false,
        edit: fecIsDisabledAndIAmABankUser,
        item: fecIsDisabled,
        list: false,
        delete: false,
        csv: true,
      },
      group: 'settings',
    },
    fecMarkupsText: {
      label: 'FEC Additional Markups',
      datatype: 'memo',
      default: false,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: interOrclientIsEnabledForFec, list: false, delete: false, csv: false },
      property: { source: 'frontend', read: true, write: false, sort: 'none' },
      group: 'settings',
    },

    note: {
      label: 'Note',
      datatype: 'memo',
      search: true,
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: extendedTextWhiteList },
        len: { msg: 'Note should be between 2 and 250 characters', args: [2, 250] },
      },
      views: {
        create: false,
        edit: accountIsNotMyAccount,
        item: accountIsNotMyAccount,
        list: false,
        delete: false,
        csv: false,
      },
      group: 'settings',
    },
    visibility: {
      label: 'Visibility',
      search: false,
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'visible', name: 'Visible' },
            { id: 'hidden', name: 'Hidden' },
          ],
        },
      },
      default: 'visible',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'settings',
    },

    restrictedView: {
      label: 'Restricted View',
      datatype: 'text',
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: false,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'settings',
    },

    lastEditedAt: {
      label: 'Last Edited At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: true },
      group: 'changes',
    },

    // Temporary Feature Switches
    intermediaryHasOwnSignUpForm: {
      label: 'Intermediary has own Sign Up Form',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: bankEditingIntermediaryOrBranch, edit: bankEditingIntermediaryOrBranch, item: isBankUser, list: isBankUser, delete: false, csv: false },
      group: 'settings',
    },
    intermediaryCanSeeStampedStatements: {
      label: 'Intermediary can see Stamped Statements.',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: bankEditingIntermediaryOrBranch, edit: bankEditingIntermediaryOrBranch, item: isBankUser, list: isBankUser, delete: false, csv: false },
      group: 'settings',
    },
    intermediaryCanSeeAccountConfirmations: {
      label: 'Intermediary can see Account Confirmations',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: bankEditingIntermediaryOrBranch, edit: bankEditingIntermediaryOrBranch, item: isBankUser, list: isBankUser, delete: false, csv: false },
      group: 'settings',
    },
    intermediaryMayTakeMarkupsOnFecMod: {
      label: 'Intermediary can take a markup on FEC Modifications',
      datatype: 'boolean',
      default: false,
      property: { source: 'backend', read: true, write: true, sort: 'none' },
      mandatory: false,
      allowNullValues: true,
      views: { create: bankEditingIntermediaryOrBranch, edit: bankEditingIntermediaryOrBranch, item: isBankUser, list: isBankUser, delete: false, csv: false },
      group: 'settings',
    },
    // End

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: true },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
          linkInSql: false,
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    Applications: {
      label: 'Client Applications',
      datatype: {
        json: {
          type: 'collection',
          path(record: LdbDbObject) {
            //@ts-ignore
            return `/account/${record.id}/application`;
          },
        },
      },
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
    },

    numberOf_active_intermediaryUsers: {
      label: 'Number of Active Users',
      datatype: 'text',
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      views: { item: false },
      group: 'settings',
    },
    numberOf_active_intermediaryUsers_withActiveManageUser: {
      label: 'Number of Active User Managers',
      datatype: 'text',
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      views: { item: false },
      group: 'settings',
    },
    numberOf_active_systemUser: {
      label: 'Number of Active System Users',
      datatype: 'text',
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      views: { item: false },
      group: 'settings',
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------

// Tracking
// activationTimstamp:Date, // ?
// activatingUser:number, // ?

// rejectionTimstamp:Date, // ?
// rejectingUser:number, // ?
// rejectionReason:string, // ?

// Integration
// esb_kyc_transaction_id
// esb_transaction_id
// esb_kyc_status
// esb_cif_status
// inter_esb_system_user

// General Options
// default_bop_category_inward
// default_bop_category_outward

// Intermediary Options
// inter_may_freeze_rate
// inter_must_review_bop
// inter_may_process_bulk_fees

// Client Options
// trader_type - importer/exporter/both
// client_allowed_value_dates
// account_allow_formward
// email_client_directly
