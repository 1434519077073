//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxApplicationCifEntityDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a Entity Information record for LandoByte TS systems
//  Created     : 30 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import type { LdbDefinition, LdbViewFunction } from './LdbInterfaces';
import type { LfxApplicationDueDilligence, LfxApplicationRequiredFieldSet } from './LfxApplicationDef';
import type {
  LfxApplicantInfoStatus,
  LfxApplicationCifType,
  LfxApplicationCifAction,
  LfxApplicationGeoCode,
  LfxApplicationBankingAlerts,
} from './LfxApplicationCifDef';
import {
  msisdnValidator,
  isValidRsaTaxNumber,
  isValidRsaVatNumber,
  standardTextWhiteList,
} from '../sharedUtils/LdbValidations';
// import { dateLimit } from '../sharedUtils/LdbDefinitionUtils';

//----------------------------------------------------------------------------------------------------------------------

export type LfxCompanyType = 'pty' | 'cc' | 'trust' | 'estateLate' | 'partnership' | 'club' | 'association';
export type LfxEntitySigningAuthority = 'Alone' | 'Jointly';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxApplicationCifEntityInt {
  id?: number;
  uuid?: string;
  applicationId?: number;
  applicationCifId?: number;
  accountId?: number;
  cifType?: LfxApplicationCifType;
  cifAction?: LfxApplicationCifAction;
  status?: LfxApplicantInfoStatus;

  companyType?: LfxCompanyType;
  name?: string;
  registrationNumber?: string;
  registrationDate?: Date;
  taxNumber?: string;
  vatRegistered?: boolean;
  vatNumber?: string;
  contactNumber?: string;
  contactPersonName?: string;
  emailAddress?: string;
  faxNumber?: string;
  address?: string;
  suburb?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  geoCode?: LfxApplicationGeoCode;
  businessSectorCode?: string;
  natureOfBusiness?: string;
  annualIncome?: number;
  cashPercentage?: number;
  sourceOfFundsOption?: string;
  sourceOfFunds?: string;
  customsClientNumber?: string;

  signingAuthority?: LfxEntitySigningAuthority;

  isUSATaxResident?: boolean;
  isRSATaxResident?: boolean;
  taxCountry?: string;
  taxIdentificationNumber?: string;

  clientDueDilligence?: LfxApplicationDueDilligence;
  digitalSignatureReferences?: string;

  internetBanking?: boolean;
  bankingAlerts?: LfxApplicationBankingAlerts;
  requiresFecFacility?: boolean;

  requiredFieldSet?: LfxApplicationRequiredFieldSet;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

const showFatcaCrsTaxIdentificationNumber:LdbViewFunction = (viewFunctionObject) => {
  const applicationCifIndividual = viewFunctionObject.record as LfxApplicationCifEntityInt;
  return (applicationCifIndividual.cifType === 'full' && (applicationCifIndividual.isRSATaxResident === false || applicationCifIndividual.isUSATaxResident === true))
 }

//----------------------------------------------------------------------------------------------------------------------

export const LfxApplicationCifEntityDef: LdbDefinition = {
  title: 'Entity Information',
  table: 'LfxApplicationCifEntity',
  collectionPath:
    '/account/{{accountId}}/application/{{applicationId}}/application_cif/{{applicationCifId}}/application_cif_entity',
  view: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
  indexes: ['applicationCifId'],
  pagingType: 'backEnd',
  groups: {
    system: { priority: 1, label: 'System' },
    details: { priority: 2, label: 'Company' },
    identification: { priority: 3, label: 'Identification' },
    contact: { priority: 4, label: 'Contact' },
    income: { priority: 5, label: 'Income' },
    fatca: { priority: 6, label: 'FATCA' },
    config: { priority: 7, label: 'Configuration' },
    other: { priority: 8, default: true, label: '' },
    changes: { priority: 9, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'name' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    applicationId: {
      label: 'Application',
      datatype: {
        foreignKey: {
          linkTable: 'LfxApplication',
          linkField: 'id',
          collection: 'fk_applications',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    applicationCifId: {
      label: 'Application CIF',
      datatype: {
        foreignKey: {
          collection: 'fk_application_cifs',
          linkTable: 'LfxApplicationCif',
          linkField: 'id',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    cifType: {
      label: 'CIF Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full' },
            { id: 'associated', name: 'Associated' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    cifAction: {
      label: 'CIF Action',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'create', name: 'Create' },
            { id: 'update', name: 'Update' },
          ],
        },
      },
      default: 'create',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'open', name: 'Open' },
            { id: 'locked', name: 'Locked' },
          ],
        },
      },
      default: 'open',
      mandatory: true,
      views: { create: true, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },

    companyType: {
      label: 'Company Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'pty', name: 'PTY' },
            { id: 'cc', name: 'CC' },
            { id: 'trust', name: 'Trust' },
            { id: 'estateLate', name: 'Estate Late' },
            { id: 'partnership', name: 'Partnership' },
            { id: 'club', name: 'Club' },
            { id: 'association', name: 'Association' },
          ],
        },
      },
      default: 'pty',
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    name: {
      label: 'Name',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Name should be between 2 and 60 characters', args: [2, 60] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    registrationNumber: {
      label: 'Registration Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Registration Number should be between 1 and 24 characters', args: [1, 24] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'details',
    },
    registrationDate: {
      label: 'Registration Date',
      datatype: {
        datetime: {
          type: 'date',
          format: 'human',
          input: 'calendar',
          maxDate: { type: 'day', offset: 0 },
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        // isDate:{msg:"Please Enter a Valid Date",args:true},
        // isBefore:{msg:"Registration Date must be in the past",args:undefined}
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    taxNumber: {
      label: 'RSA Tax Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      validators: {
        isNumeric: { msg: 'Tax Number should be numeric' },
        len: { msg: 'Tax Number must be 10 characters long', args: [10, 10] },
        isValidRsaTaxNumber,
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    vatRegistered: {
      label: 'Registered for VAT',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    vatNumber: {
      label: 'VAT Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { vatRegistered: { eq: true } },
      validators: {
        isNumeric: { msg: 'VAT Number should be numeric' },
        len: { msg: 'VAT Number must be 10 characters long', args: [10, 10] },
        isValidRsaVatNumber,
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'details',
    },
    contactNumber: {
      label: 'Contact Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: { msisdnValidator },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    contactPersonName: {
      label: 'Contact Person Name',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Contact Person Name should be between 2 and 100 characters', args: [2, 100] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    emailAddress: {
      label: 'Email Address',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: { isEmail: { msg: 'Email is invalid' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    faxNumber: {
      label: 'Fax Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: { isMSISDN: msisdnValidator },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    address: {
      label: 'Address',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Address should be between 5 and 40 characters', args: [5, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    suburb: {
      label: 'Suburb',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Suburb should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    city: {
      label: 'City',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'City should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    province: {
      label: 'Province',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Province should be between 2 and 40 characters', args: [2, 40] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    postalCode: {
      label: 'Postal Code',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Postal Code should be between 2 and 9 characters', args: [2, 9] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    country: {
      label: 'Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'contact',
    },
    geoCode: {
      label: 'Geo Code',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'easternCape', name: 'Eastern Cape' },
            { id: 'freeState', name: 'Free State' },
            { id: 'gauteng', name: 'Gauteng' },
            { id: 'kzn', name: 'KZN' },
            { id: 'limpopo', name: 'Limpopo' },
            { id: 'mpumalanga', name: 'Mpumalanga' },
            { id: 'northWest', name: 'North West' },
            { id: 'nothernCape', name: 'Northern Cape' },
            { id: 'westernCape', name: 'Western Cape' },
            { id: 'national', name: 'National' },
            { id: 'offShore', name: 'Off Shore' },
          ],
        },
      },
      default: 'national',
      mandatory: false,
      allowNullValues: true,
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'contact',
    },
    businessSectorCode: {
      label: 'Business Sector Code',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      button: {
        action: 'click',
        icon: 'system/search-action',
        actionName: 'actbusinessSectorCode',
        views: { create: true, edit: true, item: false, list: false, delete: false, csv: false },
      },
      validators: {
        isNumeric: { msg: 'Business Sector Code should be numeric' },
        len: { msg: 'Business Sector Code must be 4 digits long', args: [4, 4] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    natureOfBusiness: {
      label: 'Nature of Business',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Nature of Business should be between 5 and 250 characters', args: [5, 250] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    annualIncome: {
      label: 'Annual Income',
      datatype: { currency: { symbol: 'R' } },
      default: 0,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      validators: {
        isNumeric: { msg: 'Income should be numeric' },
        min: { msg: 'Income must be greater than 0', args: [0] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    cashPercentage: {
      label: 'Cash Percentage',
      datatype: 'percentage',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      validators: {
        isNumeric: { msg: 'Cash Percentage should be numeric' },
        max: { msg: 'Cash Percentage must be less than 100', args: [100] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    sourceOfFundsOption: {
      label: 'Source of Funds',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: '01', name: 'Salary' },
            { id: '02', name: 'Pension' },
            { id: '03', name: 'Commission' },
            { id: '04', name: 'Investment Income' },
            { id: '06', name: 'Business profit' },
            { id: '07', name: 'Social Grant' },
            { id: '09', name: 'Allowance/Maintenance' },
            { id: '10', name: 'Inheritance' },
            { id: '11', name: 'Prize Winnings' },
            { id: '12', name: 'Donations and Gifts' },
            { id: '13', name: 'Rental income' },
            { id: '05', name: 'Other' },
          ],
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    sourceOfFunds: {
      label: 'Source of Funds',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' } },
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Source of Funds should be between 1 and 30 characters', args: [1, 30] },
      },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'income',
    },
    customsClientNumber: {
      label: 'Customs Client Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        isNumeric: { msg: 'CCN should be numeric' },
        len: { msg: 'CCN must be longer than 4 digits', args: [4, undefined] },
      },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },

    signingAuthority: {
      label: 'Signing Authority',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Alone', name: 'Signatories can sign alone.' },
            { id: 'Jointly', name: 'Signatories must sign jointly.' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: 'create',
      mandatory: true,
      views: { create: false, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    isRSATaxResident: {
      label: 'RSA Tax Resident?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'fatca',
    },
    isUSATaxResident: {
      label: 'USA Tax Resident?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'fatca',
    },
    taxCountry: {
      label: 'Tax Country',
      datatype: {
        foreignKey: {
          collection: 'fk_countries',
          linkTable: 'LdbCountry',
          linkField: 'id',
          displayField: 'name',
          type: 'text',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      guards: { cifType: { eq: 'full' }, isUSATaxResident: { eq: false }, isRSATaxResident: { eq: false } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'fatca',
    },
    taxIdentificationNumber: {
      label: 'Tax Identification Number',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'Tax Identification Number should be between 5 and 250 characters', args: [5, 250] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: showFatcaCrsTaxIdentificationNumber, item: showFatcaCrsTaxIdentificationNumber, list: false, delete: false, csv: false },
      group: 'fatca',
    },

    clientDueDilligence: {
      label: 'Due Dilligence',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'Face-to-Face', name: 'Face To Face' },
            { id: 'Non-Face-to-Face', name: 'Non Face To Face' },
          ],
        },
      },
      default: 'Face-to-Face',
      mandatory: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    digitalSignatureReferences: {
      label: 'DocuSign Envelopes',
      datatype: 'text',
      default: null,
      mandatory: false,
      validators: {
        is: { msg: 'Please use only alphanumeric charaters', args: standardTextWhiteList },
        len: { msg: 'DocuSign Envelopes should be between 5 and 250 characters', args: [5, 250] },
      },
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    internetBanking: {
      label: 'Internet Banking?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: null,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    bankingAlerts: {
      label: 'Banking Alerts',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'E', name: 'Email' },
            { id: 'S', name: 'SMS' },
            { id: 'B', name: 'SMS and Email' },
          ],
        },
      },
      default: 'B',
      mandatory: true,
      guards: { cifType: { eq: 'full' } },
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    requiresFecFacility: {
      label: 'Client would like to use FEC Products?',
      datatype: {
        option: {
          optionType: 'boolean',
          options: [
            { id: false, name: 'No' },
            { id: true, name: 'Yes' },
          ],
        },
      },
      displayType: {
        datatype: 'option',
        display: 'radio',
      },
      default: false,
      mandatory: false,
      guards: { cifType: { eq: 'full' } },
      views: { create: true, edit: true, item: true, list: false, delete: false, csv: false },
      group: 'config',
    },
    requiredFieldSet: {
      label: 'Required Field Set',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full', tagType: 'info' },
            { id: 'minimum', name: 'Minimum', tagType: 'info' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};

//----------------------------------------------------------------------------------------------------------------------

export const requiredFields = (cifEntityApplication: LfxApplicationCifEntityInt) => {
  const requiredFields = [
    'companyType',
    'name',
    'registrationNumber',
    'registrationDate',
    'contactNumber',
    'contactPersonName',
    'emailAddress',
    'address',
    'suburb',
    'city',
    'postalCode',
    'country',
    'geoCode',
    'businessSectorCode',
  ];
  if (cifEntityApplication.cifType === 'full') {
    requiredFields.push('vatRegistered');
    requiredFields.push('annualIncome');
    requiredFields.push('cashPercentage');
    // requiredFields.push('sourceOfFunds'); // Replaced by sourceOfFundsOption
    requiredFields.push('sourceOfFundsOption');
    requiredFields.push('clientDueDilligence');
    // requiredFields.push('internetBanking'); // No longer required
    requiredFields.push('bankingAlerts');
    if (cifEntityApplication.vatRegistered) {
      requiredFields.push('vatNumber');
    }
  }
  if (cifEntityApplication.isUSATaxResident === false && cifEntityApplication.isRSATaxResident === false) {
    requiredFields.push('taxCountry');
  }
  return requiredFields;
};
