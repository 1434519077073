//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LdbSharedUtils.ts
//  Author      : Bevan Timm
//  Description : This module contains general utils for LandoByte TS systems that can be used by both backend and frontent
//  Created     : 3 September 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { LfxAccountAccountType } from '../definitions/LfxAccountDef';
import { LdbCountryInt } from '../definitions/LdbCountryDef';
import { LfxApplicationIndividualResidentialStatus } from '../definitions/LfxApplicationCifIndividualDef';
import { LfxUserInt } from '../definitions/LfxUserDef';
import { isValidIdNumber } from './south-africa-regex';

//----------------------------------------------------------------------------------------------------------------------

export const getDateOfBirthFromRsaId = (idNumber: string) => {
  if (isValidIdNumber(idNumber).error) {
    throw new Error('Invalid RSA ID Number');
  }
  var year = +idNumber.substr(0, 2);
  if (
    year >=
    +new Date()
      .getFullYear()
      .toString()
      .substr(2, 2)
  ) {
    year = 1900 + year;
  } else {
    year = 2000 + year;
  }
  return new Date(year, +idNumber.substr(2, 2) - 1, +idNumber.substr(4, 2));
};

export const getGenderFromRsaId = (idNumber: string) => {
  if (isValidIdNumber(idNumber).error) {
    throw new Error('Invalid RSA ID Number');
  }
  switch (+idNumber.substr(6, 4) < 4000) {
    case true:
      return 'F';
    case false:
      return 'M';
  }
};

export const getIsCitizenFromRsaId = (idNumber: string) => {
  return idNumber.substr(10, 1) === '0';
};

//----------------------------------------------------------------------------------------------------------------------

export const replaceTags = (
  replaceText: string,
  replaceObject: { [fieldName: string]: string | number | undefined },
  settings?: { allowNull?: boolean; allowEmpty?: boolean }
) => {
  // replaces << field|default >> or << field >> in replace text with corresponding value in replaceObject
  return replaceText.replace(/<<([^>]+)>>/g, function(_match, string) {
    
    var stringArr = string.split('|');
    var value = stringArr[0].trim();

    var objectValueIsValid: boolean;
    if (settings && settings.allowEmpty) {
      objectValueIsValid = replaceObject[value] !== undefined && replaceObject[value] !== null;
    } else {
      objectValueIsValid = !!replaceObject[value];
    }
    if (objectValueIsValid) {
      return replaceObject[value];
    } else {
      if (stringArr[1]) {
        return stringArr[1].trim();
      } else {
        if (settings && settings.allowNull) {
          return '';
        } else {
          throw new Error('Replace field ' + value + ' not found');
        }
      }
    }
  });
};

//----------------------------------------------------------------------------------------------------------------------

export const roundTo = (number: number, decimals: number) => {
  if (!decimals) {
    decimals = 0;
  }
  return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
};
//----------------------------------------------------------------------------------------------------------------------

export const isUserLocked = (user: LfxUserInt) => {
  return (
    checkUnsuccesfulCountAndTime(user.unsuccessfulLogins, user.lastUnsuccessfulLogin) ||
    checkUnsuccesfulCountAndTime(user.unsuccessfulPasswordResets, user.lastUnsuccessfulPasswordReset)
  );
};
const checkUnsuccesfulCountAndTime = (
  unsuccessfulCount: number | null | undefined,
  lastUnsuccessful: Date | null | undefined
) => {
  if (!unsuccessfulCount || unsuccessfulCount < 3) {
    return false;
  }
  if (!lastUnsuccessful) {
    return true;
  }
  var lockedTime: number; // in minutes
  switch (unsuccessfulCount) {
    case 3:
      lockedTime = 10;
      break;
    case 4:
      lockedTime = 15;
      break;
    case 5:
      lockedTime = 30;
      break;
    case 6:
      lockedTime = 60;
      break;
    default:
      lockedTime = 60;
      break;
  }
  return new Date().getTime() - lastUnsuccessful.getTime() < lockedTime * 1000 * 60;
};

//----------------------------------------------------------------------------------------------------------------------

export const residentalAccountOptions = (
  accountType: LfxAccountAccountType,
  addressCountry: LdbCountryInt | string | undefined,
  residentialStatus: LfxApplicationIndividualResidentialStatus|undefined,
  emigratingBlock: boolean | undefined
) => {
  const returnObject = { allowTrue: false, allowFalse: false };
  if (residentialStatus === 'rsaResident') {
    if (emigratingBlock) {
      returnObject.allowFalse = true
    } else {
      returnObject.allowTrue = true;
      if (!countryIsLocal(addressCountry)) {
        returnObject.allowFalse = true;
      }
    }
  } else if (!countryIsLocal(addressCountry)) {
    returnObject.allowFalse = true;
  } else if (accountType === 'entity') {
    returnObject.allowTrue = true;
  } else {
    returnObject.allowTrue = true;
    returnObject.allowFalse = true;
  }
  return returnObject;
};
const countryIsLocal = (addressCountry: LdbCountryInt | string | undefined) => {
  if (!addressCountry) {
    return true;
  }
  var addressCountryId = addressCountry;
  if (typeof addressCountry === 'object') {
    addressCountryId = addressCountry.id!;
  } else {
    addressCountryId = addressCountry;
  }
  return ['ZA', 'LS', 'SZ', 'NA'].includes(addressCountryId);
};

//----------------------------------------------------------------------------------------------------------------------

export const toTitleCase = (str: string): string => {
  return str?.replace(/([^\W_]+[^\s-]*) */g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};
export const toCamelCase = (str: string): string => { 
  return toTitleCase(str).replace(/[_ -]/g, "")
}
export const toPascalCase = (str: string): string => {
  const camelCase = toCamelCase(str);
  return camelCase[0].toLowerCase() + camelCase.substr(1)
}

//----------------------------------------------------------------------------------------------------------------------

const mapObj = { '&lt;': '<', '&gt;': `>`, '&#x27;': `'`, '&quot;': `"`, '&#x2F;': `/` };
export const removeXssCharacters = (string:string|undefined) => {
  if (typeof string === 'string') {
    return string.replace(/&lt;|&gt;|&#x27;|&#x2F;/gi, function(matched){
      //@ts-ignore
      return mapObj[matched];
    });  
  }
  return string
};
export const stripEscaped = (string:string|undefined) => {
  if (typeof string === 'string') {
    return string.replace(/\t|\r|\n/gi, function(matched){
      return "";
    });  
  }
  return string
};
export const stripLineBreaks = (string:string|undefined) => {
  if (typeof string === 'string') {
    return string.replace(/<br\/>|<br>/gi, function(matched){
      return "";
    });  

  }
  return string
}

//----------------------------------------------------------------------------------------------------------------------

export const formatCurrencyString = (currencyNumber:number|undefined,separator?:string) => {
  if (currencyNumber === undefined || currencyNumber === null) {
    return ""
  }
  if (!separator) {
    separator = ","
  }
  let isNegative  = false;
  if (currencyNumber < 0) {
    isNegative = true
    currencyNumber = currencyNumber * -1
  }
  const isZeroMinor = currencyNumber % 100 === 0;
  let val = `${Math.round(currencyNumber) / 100}`
  .split(separator)
  .join('')
  .split('')
  .reverse()
  .join('')
  .replace(/([0-9]{3})/g, '$1,')
  .split('')
  .reverse()
  .join('')
  .replace(/^,/, '')
  .split(',')
  .join(separator);

  if (isZeroMinor) {
    val = val + ".00"
  }

  if (isNegative) {
    val = "-" + val
  }
  return val;

}

//----------------------------------------------------------------------------------------------------------------------

export const splitIndividualName = (name:string) => {
  const returnObject = {fullname:"",firstNames:"",firstName:"",middleNames:"",lastName:"",commaName:""};
  let firstNamesArray: string[] = [];
  const commaSplit = name.split(",");
  if (commaSplit.length !== 1) {
    returnObject.lastName = commaSplit[0].trim();
    returnObject.firstNames = commaSplit[1].trim();
    firstNamesArray = returnObject.firstNames.split(" ");
  } else {
    const spaceSplit = name.split(" ");
    returnObject.lastName = spaceSplit[spaceSplit.length -1];
    firstNamesArray = spaceSplit.slice(0,spaceSplit.length - 1);
    returnObject.firstNames = firstNamesArray.join(" ");
  }
  // TODO - Add logic to last item or middleNames to check if is Mac, van, etc. and add it to surname
  returnObject.firstName = firstNamesArray[0] || "";
  const middleNamesArray = firstNamesArray.slice(1,firstNamesArray.length);
  returnObject.middleNames = middleNamesArray.join(" ")
  returnObject.fullname = returnObject.firstNames + " " + returnObject.lastName
  returnObject.commaName = returnObject.lastName + ", " + returnObject.firstNames
  return returnObject
 }

//----------------------------------------------------------------------------------------------------------------------

export const formatTimeForHumans = (milisecords:number,miniumUnit:'days'|'hours'|'minutes') => {
  if (milisecords === 0) {
    return 0 + " " + miniumUnit
  }
  let diffDays = Math.floor(milisecords / 86400000); // days
  let diffHrs = Math.floor((milisecords % 86400000) / 3600000); // hours
  let diffMins = Math.round(((milisecords % 86400000) % 3600000) / 60000); // minutes

  if (miniumUnit !== 'minutes') {
    diffHrs += diffMins / 60;
    diffMins = 0;
  }
  if (miniumUnit === 'days') {
    diffDays += diffHrs / 24
    diffHrs = 0;
  }
  const returnArray:string[] = [];
  if (diffDays !== 0) {
    returnArray.push(roundTo(diffDays,2) +" days")
  }
  if (diffHrs !== 0) {
    returnArray.push(roundTo(diffHrs,2) +" hours")
  }
  if (diffMins !== 0) {
    returnArray.push(roundTo(diffMins,2) +" minutes")
  }

  return returnArray.join(", ");
}

//----------------------------------------------------------------------------------------------------------------------