//----------------------------------------------------------------------------------------------------------------------
//  Copyright   : ©️ 2021 LandoByte (Pty) Ltd.
//  File        : LfxApplicationCifDef.ts
//  Author      : Bevan Timm
//  Description : This module defines a client CIF Application for the LandoFX system
//  Created     : 30 August 2021 by Bevan Timm
//----------------------------------------------------------------------------------------------------------------------

import { intermediaryFieldViewGuard } from '../sharedUtils/LdbDefinitionUtils';
import type { LdbDefinition, LdbDbObject } from './LdbInterfaces';
import type { LfxAccountAccountType } from './LfxAccountDef';
import type { LfxApplicationIntegrationStatus, LfxApplicationRequiredFieldSet } from './LfxApplicationDef';

//----------------------------------------------------------------------------------------------------------------------

export type LfxApplicationCifType = 'full' | 'associated';
export type LfxApplicationCifAction = 'create' | 'update';
export type LfxApplicationCifStatus = 'new' | 'inProgress' | 'error' | 'completed' | 'cancelled' | 'rejected' ;
export type LfxApplicationCifCraStatus = 'none' | 'requested' | 'approved' | 'declined' | 'complianceReview' | 'error'| 'eddRequested' | 'eddSubmitted'

export type LfxApplicationGeoCode =
  | 'easternCape'
  | 'freeState'
  | 'gauteng'
  | 'kzn'
  | 'limpopo'
  | 'mpumalanga'
  | 'northWest'
  | 'nothernCape'
  | 'westernCape'
  | 'national'
  | 'offShore';
export type LfxApplicantInfoStatus = 'open' | 'locked';
export type LfxApplicationBankingAlerts = 'N' | 'E' | 'S' | 'B';

//----------------------------------------------------------------------------------------------------------------------

export interface LfxApplicationCifInt {
  id?: number;
  uuid?: string;
  applicationId?: number;
  accountId?: number;
  intermediaryId?: number;
  reference?: string;
  cifNumber?: string;
  cifType?: LfxApplicationCifType;
  cifAction?: LfxApplicationCifAction;
  status?: LfxApplicationCifStatus;
  accountType?: LfxAccountAccountType;

  requiredFieldSet?: LfxApplicationRequiredFieldSet;

  craIntegrationId?: string;
  craStatus?: LfxApplicationCifCraStatus;
  craSubmittedAt?: Date;
  craSubmittedBy?:number;
  craApprovedAt?: Date;
  craApprovedBy?: number;
  craDeclinedAt?: Date;
  craDeclinedBy?: number;
  craDeclinedReason?: string;

  integrationId?: string;
  integrationStatus?: LfxApplicationIntegrationStatus;
  submissionCount?: number;
  firstSubmission?: Date;
  requestedAt?: Date;
  requestedBy?: number;
  releasedAt?: Date;
  releasedBy?: number;
  rejectedAt?: Date;
  rejectedBy?: number;
  rejectedReason?: string;

  createdAt?: Date;
  createdBy?: number;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: number;
  updatedByName?: string;
  deletedAt?: Date;
}

//----------------------------------------------------------------------------------------------------------------------

export const LfxApplicationCifDef: LdbDefinition = {
  title: 'Client CIF Application',
  table: 'LfxApplicationCif',
  collectionPath: '/account/{{accountId}}/application/{{applicationId}}/application_cif/',
  view: {
    create: true,
    edit: true,
    item: true,
    list: true,
    delete: false,
    csv: false,
    tiles: {
      default: {
        layout: [
          ['title', 'arrow'],
          ['type', 'status'],
        ],
        color: { type: 'field', field: 'consolidatedStatus', optionField: 'tagType', colorType: 'class' },
      },
    },
  },

  indexes: ['applicationId', 'integrationId'],
  pagingType: 'backEnd',
  groups: {
    outstanding: { priority: 0, label: 'Outstanding' },
    system: { priority: 1, label: 'System' },
    details: { priority: 2, label: 'Details' },
    cra: { priority: 3, label: 'Client Risk Assessment' },
    integration: { priority: 4, label: 'Integration' },
    other: { priority: 5, default: true, label: '' },
    changes: { priority: 6, label: 'Changes' },
  },
  fields: {
    id: {
      label: 'ID',
      datatype: { id: { descriptionField: 'reference' } },
      default: 'AUTOINC',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    uuid: {
      label: 'UUID',
      datatype: 'uuid',
      default: 'AUTOUUID',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    applicationId: {
      label: 'Application',
      datatype: {
        foreignKey: {
          linkTable: 'LfxApplication',
          linkField: 'id',
          collection: 'fk_applications',
          displayField: 'reference',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    accountId: {
      label: 'Account',
      datatype: {
        foreignKey: {
          collection: 'fk_accounts',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: true, delete: false, csv: false },
      group: 'system',
    },
    intermediaryId: {
      label: 'Intermediary',
      datatype: {
        foreignKey: {
          collection: 'fk_intermediaries',
          linkTable: 'LfxAccount',
          linkField: 'id',
          displayField: 'name',
        },
      },
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: false,
        list: intermediaryFieldViewGuard,
        delete: false,
        csv: false,
      },
    },
    reference: {
      label: 'Reference',
      datatype: 'text',
      default: null,
      mandatory: true,
      views: {
        create: false,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: false,
        // tiles: { default: 'title' },
      },
      group: 'system',
    },
    cifNumber: {
      label: 'CIF Number',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      validators: {
        isNumeric: { msg: 'Please use only numeric charaters' },
        len: { msg: 'CIF Number should be 8 characters', args: [8, 8] },
      },
      views: { create: true, edit: true, item: true, list: true, delete: false, csv: false },
      group: 'integration',
    },

    EntityInfo: {
      label: 'Entity Information',
      datatype: {
        json: {
          type: 'collection',
          path(record: LdbDbObject) {
            //@ts-ignore
            return `/account/${record.accountId}/application/${record.applicationId}/application_cif/${record.id}/application_cif_entity`;
          },
        },
      },
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },
    IndividualInfo: {
      label: 'Individual Information',
      datatype: {
        json: {
          type: 'collection',
          path(record: LdbDbObject) {
            //@ts-ignore
            return `/account/${record.accountId}/application/${record.applicationId}/application_cif/${record.id}/application_cif_individual`;
          },
        },
      },
      search: true,
      property: { source: 'backend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'system',
    },

    displayName: {
      label: 'Display Name',
      datatype: 'text',
      default: '',
      property: { read: true, write: false, source: 'frontend', sort: 'none' },
      mandatory: false,
      views: {
        create: false,
        edit: false,
        item: false,
        list: true,
        delete: false,
        csv: false,
        tiles: { default: 'title' },
      },
      group: 'details',
    },

    cifType: {
      label: 'CIF Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full' },
            { id: 'associated', name: 'Associated' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'details',
    },
    cifAction: {
      label: 'CIF Action',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'create', name: 'Create' },
            { id: 'update', name: 'Update' },
          ],
        },
      },
      default: 'create',
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'details',
    },
    status: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'inProgress', name: 'In Progress', tagType: 'info' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
          ],
        },
      },
      default: 'new',
      mandatory: true,
      views: {
        create: true,
        edit: false,
        item: true,
        list: false,
        delete: false,
        csv: false,
        tiles: { default: 'status' },
      },
      group: 'system',
    },

    consolidatedStatus: {
      label: 'Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'new', name: 'New', tagType: 'info' },
            { id: 'informationOutstanding', name: 'Information Outstanding', tagType: 'warning'},
            { id: 'readyToSubmit', name: 'Ready to Submit', tagType: 'success' },
            { id: 'inProgress', name: 'In Progress', tagType: 'info' },
            { id: 'sent', name: 'Sent', tagType: 'info' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'inactive', name: 'Inactive', tagType: undefined },
            { id: 'pendingReview', name: 'Pending Review', tagType: 'warning' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'completed', name: 'Completed', tagType: 'success' },
            { id: 'cancelled', name: 'Cancelled', tagType: undefined },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },

            { id: 'awaitingPortfolio', name: 'Awaiting Portfolio', tagType: 'info'},

            { id: 'craInProgress', name: 'CRA in Progress', tagType: 'info'},
            { id: 'craDeclined', name: 'CRA Declined', tagType: 'error'},
            { id: 'craError', name: 'CRA Error', tagType: 'error'},
            { id: 'craExpired', name: 'CRA Expired', tagType: 'warning'},
            { id: 'craEddRequested', name: 'EDD Requested', tagType: 'warning' },
            { id: 'craEddSubmitted', name: 'EDD Submitted', tagType: 'info' },
          ],
        },
      },
      property: { read: true, write: false, source: 'frontend', sort: 'status' },
      default: 'new',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false, tiles: { default: 'status' }, },
      group: 'system',
    },
    accountType: {
      label: 'Account Type',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'entity', name: 'Entity' },
            { id: 'individual', name: 'Individual' },
          ],
        },
      },
      default: 'entity',
      mandatory: true,
      views: {
        create: true,
        edit: false,
        item: false,
        list: false,
        delete: false,
        csv: false,
        tiles: { default: 'type' },
      },
      group: 'details',
    },

    OutstandingFields: {
      label: 'Outstanding Information',
      datatype: 'memo',
      search: false,
      property: { source: 'frontend', read: true, write: false, sort: 'none' },
      default: null,
      mandatory: false,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'outstanding',
    },

    requiredFieldSet: {
      label: 'Required Field Set',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'full', name: 'Full', tagType: 'info' },
            { id: 'minimum', name: 'Minimum', tagType: 'info' },
          ],
        },
      },
      default: 'full',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'system',
    },


    craIntegrationId: {
      label: 'CRA Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },

    craStatus: {
      label: 'CRA Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None', tagType: undefined },
            { id: 'requested', name: 'Requested', tagType: 'info' },
            { id: 'approved', name: 'Approved', tagType: 'success' },
            { id: 'declined', name: 'Declined', tagType: undefined },
            { id: 'complianceReview', name: 'Compliance Review', tagType: 'warning' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'eddRequested', name: 'EDD Requested', tagType: 'warning' },
            { id: 'eddSubmitted', name: 'EDD Submitted', tagType: 'info' },
          ],
        },
      },
      default: 'none',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    craSubmittedAt: {
      label: 'CRA Submitted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    craSubmittedBy: {
      label: 'CRA Submitted By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    craApprovedAt: {
      label: 'CRA Approved At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    craApprovedBy: {
      label: 'CRA Approved By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    craDeclinedAt: {
      label: 'CRA Declined At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    craDeclinedBy: {
      label: 'CRA Declined By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    craDeclinedReason: {
      label: 'CRA Declined Reason',
      datatype: 'memo',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'cra',
    },
    integrationId: {
      label: 'Integration ID',
      datatype: 'text',
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    integrationStatus: {
      label: 'Integration Status',
      datatype: {
        option: {
          optionType: 'text',
          options: [
            { id: 'none', name: 'None', tagType: undefined },
            { id: 'sent', name: 'Sent', tagType: 'info' },
            { id: 'active', name: 'Active', tagType: 'success' },
            { id: 'inactive', name: 'Inactive', tagType: undefined },
            { id: 'pendingReview', name: 'Pending Review', tagType: 'warning' },
            { id: 'error', name: 'Error', tagType: 'error' },
            { id: 'rejected', name: 'Rejected', tagType: 'error' },
          ],
        },
      },
      default: 'none',
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    submissionCount: {
      label: 'Submission Count',
      datatype: 'integer',
      default: 0,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: true, delete: false, csv: false },
      group: 'integration',
    },
    firstSubmission: {
      label: 'Requested At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    requestedAt: {
      label: 'Requested At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    requestedBy: {
      label: 'Requested By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    releasedAt: {
      label: 'Released At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    releasedBy: {
      label: 'Released By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedAt: {
      label: 'Rejected At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedBy: {
      label: 'Rejected By',
      datatype: {
        foreignKey: {
          collection: 'fk_users',
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'displayName',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },
    rejectedReason: {
      label: 'Rejection Reason',
      datatype: 'memo',
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: true, list: false, delete: false, csv: false },
      group: 'integration',
    },

    createdAt: {
      label: 'Created At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: true,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdBy: {
      label: 'Created By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    createdByName: {
      label: 'Created By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedAt: {
      label: 'Updated At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedBy: {
      label: 'Updated By',
      datatype: {
        foreignKey: {
          linkTable: 'LfxUser',
          linkField: 'id',
          displayField: 'username',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    updatedByName: {
      label: 'Updated By',
      datatype: 'text',
      search: true,
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
    deletedAt: {
      label: 'Deleted At',
      datatype: {
        datetime: {
          type: 'datetime',
          format: 'human',
        },
      },
      default: null,
      mandatory: false,
      allowNullValues: true,
      views: { create: false, edit: false, item: false, list: false, delete: false, csv: false },
      group: 'changes',
    },
  },
};
